import React, {useState} from 'react'
import {DateRangePicker} from 'react-date-range'
import {useIntl} from 'react-intl'
import {
  timestampCountConverter,
  timestampHoursConverter,
} from '../../../../helpers/converter/Common'
import {ModeratorTrackersEnum} from '../../../../helpers/enum/ModeratorTrackersEnum'
import {UserWrapperModel} from '../../../auth/models/AccountUserWrapperModel'
import {AccountModeratorChart} from './AccountModeratorChart'

type Props = {
  userData: UserWrapperModel
}
const UserAccount: React.FC<Props> = ({userData}) => {
  const intl = useIntl()
  const [option, setOption] = useState<String>(ModeratorTrackersEnum.SANCTIONS)
  const [showDateFilters, setShowDateFilters] = useState(false)

  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const account = userData.account
  const handleChange = (e: any) => {
    setOption(e.target.value)
  }

  function inRange(date: number, rangeDate: any) {
    let startDate = rangeDate[0].startDate.getTime()
    let endDate = rangeDate[0].endDate.getTime() + 1000 * 60 * 60 * 24
    if (date >= startDate && date <= endDate) {
      return true
    }
    return false
  }

  function countSanctionInRange(duration: number) {
    let result = 0

    if (!account.moderation?.events?.sanctions) return result

    for (let sanction of account.moderation?.events?.sanctions) {
      if (inRange(sanction.date, rangeDate) && sanction.expiration && sanction.date) {
        let durationMs = Math.ceil(sanction.expiration - sanction.date)
        if (durationMs === duration) result += 1
      } else if (
        inRange(sanction.date, rangeDate) &&
        !sanction.expiration &&
        sanction.date &&
        duration === -1
      )
        result += 1
    }
    return result
  }

  function countInvalidationInRange(type: string) {
    let result = 0
    if (!account.moderation?.events?.invalidations) return result

    for (let invalidation of account.moderation.events.invalidations) {
      if (inRange(invalidation.date, rangeDate) && invalidation.type) {
        if (type === invalidation.type) result += 1
      }
    }
    return result
  }

  function countPardonInRange(type: string) {
    let result = 0
    if (!account.moderation?.events?.pardons) return result

    for (let pardon of account.moderation.events.pardons) {
      if (inRange(pardon.date, rangeDate) && pardon.type) {
        if (type === pardon.type) result += 1
      }
    }
    return result
  }

  let screenWidth = window.screen.width
  return (
    <div className='mb-20 fw-bolder mt-15'>
      {screenWidth > 500 && (
        <>
          <div className='mt-2'>
            <button
              className='btn btn-sm btn-light'
              onClick={() => setShowDateFilters(!showDateFilters)}
            >
              {intl.formatMessage({id: 'FILTER.KEY'})}
            </button>
            {showDateFilters ? (
              <div className='table-responsive'>
                <DateRangePicker
                  ranges={rangeDate}
                  onChange={(e) => {
                    let selection = {
                      key: 'selection',
                      startDate: !e.selection.startDate ? new Date() : e.selection.startDate,
                      endDate: !e.selection.endDate ? new Date() : e.selection.endDate,
                    }
                    return setRangeDate([selection])
                  }}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  direction='horizontal'
                  preventSnapRefocus={true}
                />
              </div>
            ) : null}
            <AccountModeratorChart
              InvalidationData={
                account.moderation?.events?.invalidations
                  ? account.moderation?.events?.invalidations
                  : []
              }
              SanctionData={
                account.moderation?.events?.sanctions ? account.moderation?.events?.sanctions : []
              }
              PardonData={
                account.moderation?.events?.pardons ? account.moderation?.events?.pardons : []
              }
              startDate={rangeDate[0].startDate}
              endDate={rangeDate[0].endDate}
            />
          </div>
        </>
      )}
      <div className='d-flex my-2'>
        <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select form-select-sm w-125px'
          defaultValue='Sanctions'
          onChange={(e) => handleChange(e)}
        >
          <option value='Sanctions'>{intl.formatMessage({id: 'USER.SANCTIONS'})}</option>
          <option value='Invalidations'>{intl.formatMessage({id: 'USER.INVALIDATION'})}</option>
          <option value='Pardons'>{intl.formatMessage({id: 'USER.PARDON'})}</option>
        </select>
      </div>
      {option === ModeratorTrackersEnum.SANCTIONS ? (
        <>
          {account.moderation?.events?.sanctions?.length === 0 ? (
            <span className=''>
              <div className='fs-1 mb-20 text-center fw-bolder'>
                {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
              </div>
            </span>
          ) : (
            <>
              <div className='d-flex flex-wrap justify-content-center'>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>{countSanctionInRange(10 * 60 * 1000)}</div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    {' '}
                    <p> 10 mins</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'> {countSanctionInRange(30 * 60 * 1000)}</div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    {' '}
                    <p> 30 mins</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'> {countSanctionInRange(60 * 60 * 1000)}</div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    {' '}
                    <p> 60 mins</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>{countSanctionInRange(24 * 60 * 60 * 1000)}</div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    <p> 1 {intl.formatMessage({id: 'USER.DAYS'})}</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>
                    {countSanctionInRange(3 * 24 * 60 * 60 * 1000)}
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    <p> 3 {intl.formatMessage({id: 'USER.DAYS'})}</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>
                    {countSanctionInRange(5 * 24 * 60 * 60 * 1000)}
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    <p> 5 {intl.formatMessage({id: 'USER.DAYS'})}</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>
                    {countSanctionInRange(7 * 24 * 60 * 60 * 1000)}
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    <p> 7 {intl.formatMessage({id: 'USER.DAYS'})}</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>{countSanctionInRange(-1)}</div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    <p> Ban</p>
                  </div>
                </div>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 card-body py-5'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-50px '>{intl.formatMessage({id: 'SANCTION.TYPE'})}</th>
                        <th className='min-w-50px '>{intl.formatMessage({id: 'MENU.USERS'})}</th>
                        <th className='min-w-50px '>
                          {intl.formatMessage({id: 'SANCTION.BEGIN'})}
                        </th>
                        <th className='min-w-130px '>{intl.formatMessage({id: 'SANCTION.END'})}</th>
                        <th className='min-w-50px '>
                          {intl.formatMessage({id: 'SANCTION.DURATION'})}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.moderation?.events?.sanctions?.map(
                        (sanction, sanctionsIndex) =>
                          inRange(sanction.date, rangeDate) && (
                            <tr key={sanctionsIndex}>
                              <td>
                                <span className='text-dark fw-bolder d-block fs-6'>
                                  {sanction.type === 'SUSPENDED' ? (
                                    <span className='badge badge-warning'>
                                      {intl.formatMessage({id: 'SANCTION.SUSPENDED'})}
                                    </span>
                                  ) : (
                                    <span className='badge badge-danger'>
                                      {intl.formatMessage({id: 'SANCTION.BANNED'})}
                                    </span>
                                  )}
                                </span>
                              </td>
                              <td>
                                <a
                                  href={'/users/' + sanction.uid}
                                  className='text-dark fw-bolder d-block fs-6'
                                >
                                  {sanction.uid}
                                </a>
                              </td>
                              <td>
                                <span className='d-block fs-6'>
                                  {timestampHoursConverter(sanction.date)}
                                </span>
                              </td>
                              <td>
                                <span className='d-block fs-6'>
                                  {sanction.expiration &&
                                    timestampHoursConverter(sanction.expiration)}
                                </span>
                              </td>
                              <td>
                                <span className='d-block fs-6'>
                                  {sanction.type === 'SUSPENDED'
                                    ? sanction.expiration &&
                                      timestampCountConverter(
                                        sanction.date,
                                        sanction.expiration,
                                        intl
                                      )
                                    : 'END OF TIME'}
                                </span>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </>
      ) : option === ModeratorTrackersEnum.INVALIDATIONS ? (
        <>
          {account.moderation?.events?.invalidations?.length === 0 ? (
            <tr className=''>
              <div className='fs-1 mb-20 text-center fw-bolder'>
                {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
              </div>
            </tr>
          ) : (
            <>
              <div className='d-flex flex-wrap justify-content-center'>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>
                    {countInvalidationInRange('INVALID_NICKNAME_PROFANITY')}
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    {' '}
                    <p> {intl.formatMessage({id: 'USER.NICKNAME'})}</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>
                    {' '}
                    {countInvalidationInRange('INVALID_AVATAR_OFFENSIVE_EXPRESSION')}
                  </div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    {' '}
                    <p>Avatar</p>
                  </div>
                </div>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 card-body py-5'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-50px '>{intl.formatMessage({id: 'MENU.USERS'})}</th>
                        <th className='min-w-130px '>Date</th>
                        <th className='min-w-140px '>
                          {intl.formatMessage({id: 'INVALIDATION.TYPE'})}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.moderation?.events?.invalidations?.map(
                        (invalidation, invalidationIndex) =>
                          inRange(invalidation.date, rangeDate) && (
                            <tr key={invalidationIndex}>
                              <td>
                                <a
                                  href={'/users/' + invalidation.uid}
                                  className='text-dark fw-bolder d-block fs-6'
                                >
                                  {invalidation.uid}
                                </a>
                              </td>
                              <td>
                                <span className='text-dark fw-bolder d-block fs-6'>
                                  {timestampHoursConverter(invalidation.date)}
                                </span>
                              </td>
                              <td>
                                <span className='badge badge-warning'>{invalidation.type}</span>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {account.moderation?.events?.pardons?.length === 0 ? (
            <tr className=''>
              <div className='fs-1 mb-20 text-center fw-bolder'>
                {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
              </div>
            </tr>
          ) : (
            <>
              <div className='d-flex flex-wrap justify-content-center'>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'>{countPardonInRange('UNSUSPENDED')}</div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    {' '}
                    <p>Unsuspended</p>
                  </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3'>
                  <div className='fs-2 fw-bolder'> {countPardonInRange('UNBANNED')}</div>
                  <div className='fw-bold fs-6 text-gray-400'>
                    {' '}
                    <p>Unbanned</p>
                  </div>
                </div>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 card-body py-5'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-50px '>{intl.formatMessage({id: 'MENU.USERS'})}</th>
                        <th className='min-w-130px '>{intl.formatMessage({id: 'USER.DATE'})}</th>
                        <th className='min-w-140px '>
                          {intl.formatMessage({id: 'INVALIDATION.TYPE'})}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.moderation?.events?.pardons?.map(
                        (pardon, pardonIndex) =>
                          inRange(pardon.date, rangeDate) && (
                            <tr key={pardonIndex}>
                              <td>
                                <a
                                  href={'/users/' + pardon.uid}
                                  className='text-dark fw-bolder d-block fs-6'
                                >
                                  {pardon.uid}
                                </a>
                              </td>
                              <td>
                                <span className='text-dark fw-bolder d-block fs-6'>
                                  {timestampHoursConverter(pardon.date)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={
                                    'badge badge-' +
                                    (pardon.type === 'UNSUSPENDED' ? 'warning' : 'danger')
                                  }
                                >
                                  {pardon.type}
                                </span>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
export default UserAccount
