import {useIntl} from 'react-intl'
import React, {useState, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as users from '../../modules/users/redux/UserRedux'
import AvatarRenderer from '../../modules/profile/components/AvatarRenderer'
import ModerationDropdown from '../../../_metronic/partials/content/dropdown/ModerationDropdown'
import {RoleCheckMin} from '../../helpers/RoleRestriction'
import {Roles} from '../../modules/auth/models/RolesEnum'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/users/models/UserModel'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../modules/auth/models/AccountUserWrapperModel'
import {timestampConverter} from '../../helpers/converter/Common'
import {getFlagEmoji} from '../../helpers/converter/LocaleCodeToFlagConverter'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import BlockUI from '../../modules/profile/components/BlockUi'
import {logos} from '../../helpers/provider/ProviderDataLogo'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {ReportModel} from '../../modules/users/models/ModerationModel'
import {FiltersModel} from '../../modules/users/models/FiltersModel'
import {DiffDate} from '../../helpers/DiffDates'

interface ReportingMetaData {
  reports: ReportModel[]
  newReports: ReportModel[]
  nbSanctions: number
  lastSanctionBegin: number
  lastSanctionTs: number
  nbReportsTotal: number
  nbReportsSinceLastSanction: number
  groupedReasons: any
  newGroupedReasons: any
}

const ModerationList: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel
  let moderationUsersList = useSelector<RootState>(
    ({users}) => users.moderationUsersList,
    shallowEqual
  ) as UserWrapperModel[]
  const result = useSelector<RootState>(
    ({users}) => users.moderationResult,
    shallowEqual
  ) as UserWrapperModel[]
  const currentModerationFilters = useSelector<RootState>(
    ({users}) => users.currentModerationFilters,
    shallowEqual
  ) as FiltersModel
  const [loading, setLoading] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [noMoreResult, setNoMoreResult] = useState(false)
  const [usersListUpdated, setUsersListUpdated] = useState<UserWrapperModel[]>([])
  const [filters, setFilters] = useState({
    filterKey: 'moderation.flag',
    filterValue: 'ACTION',
    filterOperator: '==',
    filterType: 'string',
    sortingOrder: 'ASC',
  })
  const metaData: ReportingMetaData[] = []
  let uniquePlatform: string[] = []

  useEffect(() => {
    if (moderationUsersList === null || moderationUsersList.length === 0)
      dispatch(users.userActions.getModerationUsers(filters.filterValue))
    if (currentModerationFilters !== null) {
      setFilters({
        filterKey: currentModerationFilters.filterKey,
        filterValue: currentModerationFilters.filterValue,
        filterOperator: currentModerationFilters.filterOperator,
        filterType: currentModerationFilters.filterType,
        sortingOrder: currentModerationFilters.sortingOrder,
      })
    }
    setUsersListUpdated(moderationUsersList)
    for (let i = 0; i < moderationUsersList.length; i++) {
      if (moderationUsersList[i].user.moderation.flag !== currentModerationFilters.filterValue)
        moderationUsersList.splice(i, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!result) {
      setLoadMore(false)
      setNoMoreResult(true)
    }
  }, [result])

  useEffect(() => {
    dispatch(users.userActions.setModerationFilters(filters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (moderationUsersList.length > 0) {
      setLoading(false)
      setLoadMore(false)
      setNoMoreResult(false)
    }
    setUsersListUpdated(moderationUsersList)
    // initialize moderation dropdowns to their buttons
    setTimeout(function () {
      MenuComponent.reinitialization()
    }, 200)
  }, [moderationUsersList, usersListUpdated])

  function hideButton(index: number) {
    setUsersListUpdated(usersListUpdated.splice(index, 1))
  }

  function newReports(user: UserModel) {
    let data: ReportingMetaData = {
      reports: [],
      nbSanctions: 0,
      lastSanctionBegin: 0,
      lastSanctionTs: 0,
      newReports: [],
      nbReportsTotal: 0,
      nbReportsSinceLastSanction: 0,
      groupedReasons: [],
      newGroupedReasons: [],
    }
    data.reports = user.moderation.events.reports
    data.nbSanctions = user.moderation.events.sanctions.length
    data.lastSanctionTs =
      data.nbSanctions > 0
        ? Math.max.apply(
            Math,
            user.moderation.events.sanctions.map((o: any) => o.expiration || o.date)
          )
        : 0
    data.lastSanctionBegin =
      data.nbSanctions > 0
        ? user.moderation.events.sanctions[user.moderation.events.sanctions.length - 1].date
        : 0
    data.newReports = data.reports.filter((r: any) => r.date > data.lastSanctionTs + 1)
    data.nbReportsTotal = data.reports.length
    data.nbReportsSinceLastSanction = data.nbSanctions > 0 ? newReports.length : data.nbReportsTotal
    data.groupedReasons = data.reports.reduce(function (res: any, item: any) {
      ;(res[item.reason] = res[item.reason] || []).push(item)
      return res
    }, [])
    data.newGroupedReasons = data.newReports.reduce(function (res: any, item: any) {
      ;(res[item.reason] = res[item.reason] || []).push(item)
      return res
    }, [])
    return data
  }

  moderationUsersList.map(({user}, index) => metaData.push(newReports(user)))

  let allTimeStamp: any = []

  function findReportDate(reportObject: any) {
    reportObject.map((report: any) => {
      return allTimeStamp.push(report.date)
    })
    const groupedByDay: any = {}
    if (allTimeStamp)
      allTimeStamp.forEach((timestamp: any) => {
        const date = new Date(timestamp)

        const day = date.toISOString().split('T')[0]

        if (!groupedByDay[day]) {
          groupedByDay[day] = []
        }
        groupedByDay[day].push(timestamp)
      })
    return Number((reportObject.length / Object.keys(groupedByDay).length).toFixed(2))
  }
  return (
    <div className={'card mb-5 mb-xl-8'}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {intl.formatMessage({id: 'MODERATION.TITLE'})}
          </span>
        </h3>
      </div>
      <div className='d-flex'>
        <div className='d-flex my-2 ms-auto mt-10 me-10 h-40px'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-sm w-150px'
            value={filters.filterValue}
            onChange={(e) => {
              setLoading(true)
              setFilters({...filters, filterValue: e.target.value})
              dispatch(users.userActions.getModerationUsers(e.target.value))
            }}
          >
            <option value='ACTION'>{'Action'}</option>
            <option value='INVESTIGATION'>{'Investigation'}</option>
          </select>
        </div>
      </div>
      <div className='card-body py-3'>
        {!result && moderationUsersList.length === 0 ? (
          <div className=' text-center mt-10 mb-10'>
            <span> {intl.formatMessage({id: 'FILTER.NORESULT'})}</span>
          </div>
        ) : loading ? (
          <div className=' text-center mt-10 mb-10'>
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'LOADER'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 card-body py-5'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th>Avatar</th>
                  <th>{intl.formatMessage({id: 'USER.USERBOARD.USER'})}</th>
                  <th className='w-150px'>{intl.formatMessage({id: 'USER.TOTAL.REPORT'})}</th>
                  <th className='w-150px'>{intl.formatMessage({id: 'USER.AVERAGE.REPORT'})}</th>
                  <th>{intl.formatMessage({id: 'USER.SANCTIONS'})}</th>
                  {RoleCheckMin(Roles.ModeratorConfirmed, admin.account.role) && (
                    <th className='min-w-50px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {moderationUsersList.map(({user, account}, index) => {
                  uniquePlatform = []
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <AvatarRenderer
                            avatarLayers={user.profile.avatarLayers}
                            dimension={50}
                            uid={user.uid}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link to={{pathname: `/users/${user.uid}`, state: {userData: user}}}>
                              <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {user.nickname + user.nicknameId}
                              </span>
                            </Link>
                            <span className='text-muted fw-bold d-block fs-7'>{user.uid}</span>
                            <span className='fw-bold d-block fs-7'>
                              {intl.formatMessage({id: 'USER.JOINED'})}{' '}
                              {timestampConverter(user.metaData.createdTimestamp)}
                            </span>
                            <span className='fw-bold d-block fs-7'>
                              {DiffDate(new Date(Date.now()), user.metaData.createdTimestamp)}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>
                              {user.profile.age} {intl.formatMessage({id: 'USER.AGE'})} ,{' '}
                              {getFlagEmoji(user.localeCode) + '   ' + user.localeCode}
                              <span className='ms-2'>
                                {user.fcmTokens.map((fcmToken, fcmTokenIndex) => {
                                  if (!uniquePlatform.includes(fcmToken.platform)) {
                                    uniquePlatform.push(fcmToken.platform)
                                    return (
                                      <span key={fcmTokenIndex}>
                                        <img
                                          alt='platform logo'
                                          className='h-20px logo'
                                          src={toAbsoluteUrl(logos[fcmToken.platform])}
                                        />
                                      </span>
                                    )
                                  }
                                  return null
                                })}
                              </span>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-center'>{user.moderation.events.reports.length}</td>
                      <td className='text-center'>
                        {findReportDate(user.moderation.events.reports)}
                      </td>
                      <td>
                        <div className=' fw-bold d-block fs-7 min-w-150px'>
                          {intl.formatMessage({id: 'USER.SANCTIONS'})} :{' '}
                          {user.moderation.events.sanctions.length}
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-muted fw-bold d-block fs-7'>
                            {intl.formatMessage({id: 'USER.LASTSANCTIONS'})}
                          </span>
                          <span className=' fw-bold d-block fs-7'>
                            {metaData[index].lastSanctionTs
                              ? Math.round(
                                  (Date.now() - metaData[index].lastSanctionTs) / (1000 * 3600 * 24)
                                ) + intl.formatMessage({id: 'USER.DAYSAGO'})
                              : intl.formatMessage({id: 'NONE'})}
                          </span>
                          <span className='text-muted fw-bold d-block fs-7'>
                            {intl.formatMessage({id: 'SANCTION.BEGIN'})} :{' '}
                            {metaData[index].lastSanctionBegin !== 0 &&
                              timestampConverter(metaData[index].lastSanctionBegin)}
                          </span>
                          <span className='text-muted fw-bold d-block fs-7'>
                            {intl.formatMessage({id: 'SANCTION.END'})} :{' '}
                            {metaData[index].lastSanctionTs !== 0 &&
                              timestampConverter(metaData[index].lastSanctionTs)}
                          </span>
                        </div>
                      </td>

                      <td className='text-center'>
                        {RoleCheckMin(Roles.ModeratorConfirmed, account?.role) ? (
                          <img
                            alt='moderator logo'
                            className='h-35px logo'
                            src={toAbsoluteUrl('/media/logos/moderator.svg')}
                          />
                        ) : (
                          RoleCheckMin(Roles.ModeratorConfirmed, admin.account.role) &&
                          !RoleCheckMin(Roles.ModeratorConfirmed, account?.role) && (
                            <div className='d-flex my-4 justify-content-center'>
                              {user.moderation.flag === filters.filterValue ? (
                                user.isLoading ? (
                                  <BlockUI loading={user.isLoading} />
                                ) : (
                                  <>
                                    <ModerationDropdown
                                      userWrappedData={moderationUsersList[index]}
                                    />
                                    <button
                                      className='btn btn-sm btn-success ms-5'
                                      onClick={() =>
                                        dispatch(
                                          users.userActions.ignore(
                                            moderationUsersList[index],
                                            admin
                                          )
                                        )
                                      }
                                    >
                                      Ignore
                                    </button>
                                  </>
                                )
                              ) : (
                                <button
                                  className='btn btn-sm w-150px btn-light-success'
                                  onClick={() => hideButton(index)}
                                >
                                  {' '}
                                  {intl.formatMessage({id: 'USER.HIDE'})}
                                </button>
                              )}
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className='btn-custom align-items-center text-center mb-5'>
          {moderationUsersList.length === 0 ? (
            ''
          ) : (
            <div>
              {noMoreResult ? (
                <p> {intl.formatMessage({id: 'FILTER.NORESULT'})}</p>
              ) : loadMore ? (
                <button
                  type='button'
                  className='btn btn-sm btn-info btn-active-light-primary w-100px'
                >
                  <span className='spinner-border spinner-border-sm text-light'></span>
                </button>
              ) : (
                <button
                  className='btn btn-sm btn-info btn-active-light-primary w-100px'
                  onClick={() => {
                    dispatch(
                      users.userActions.getModerationUsers(
                        currentModerationFilters.filterValue,
                        moderationUsersList[moderationUsersList.length - 1].user.uid,
                        true
                      )
                    )
                    setLoadMore(true)
                  }}
                >
                  {intl.formatMessage({id: 'LOADMORE'})}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {ModerationList}
