import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import * as users from '../../users/redux/UserRedux'
import {
  ProviderBkgdAvatarColors,
  ProviderShellAvatarColors,
  ProviderOpacAvatarColors,
} from '../../../helpers/provider/ProviderColor'
import {AvatarLayerModel} from '../../users/models/ProfileModel'
import {AvatarLayerTypeEnum} from '../../auth/models/AvatarLayerTypeEnum'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {useIntl} from 'react-intl'
import cleanUpExpression from '../../../helpers/AvatarAssetHelper'
import {UserWrapperModel} from '../../auth/models/AccountUserWrapperModel'
import {
  accessoryAsset,
  eyewearAsset,
  headwearAsset,
  maskAsset,
  specialAsset,
  spotlightAsset,
  suitAsset,
} from '../../../helpers/provider/ProviderAvatarData'
import AssetProvider from './AssetProvider'
import AvatarRendererFullBody from './AvatarRendererFullBody'
import {colorTheme, themeBkgd} from '../../../helpers/provider/ProviderThemeData'
import {bundleKeys} from '../../../helpers/provider/BountiesProvider'
type Props = {
  avatarLayers: AvatarLayerModel[]
  show: any
  setShow: any
  dimension: any
}
const AvatarRendererModal: React.FC<Props> = ({avatarLayers, show, setShow, dimension}) => {
  const currentUser = useSelector<RootState>(
    ({users}) => users.currentUser,
    shallowEqual
  ) as UserWrapperModel
  let user = currentUser.user

  const [custom, setCustom] = useState<AvatarLayerModel[]>(avatarLayers)
  const [currentTheme, setCurrentTheme] = useState(user.profile.currentTheme)
  const [option, setOption] = useState('body')
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleClose = () => setShow(false)

  function handleChanges() {
    let index = findIndexByType(AvatarLayerTypeEnum.expressionTxt)
    if (index !== -1) custom[index].src = cleanUpExpression(custom[index].src)
    dispatch(users.userActions.editUser(currentUser, null, custom, currentTheme))
    setShow(false)
  }

  function getAvatarLayerSrcByType(type: string) {
    return custom[findIndexByType(type)]?.src
  }

  function getPrimaryBkgd(bkgd: string) {
    let primaryColor = Object.keys(ProviderOpacAvatarColors).find(
      (key) => ProviderOpacAvatarColors[key] === bkgd
    )
    return primaryColor ? primaryColor : ''
  }

  function getCssBorderValue(type: string, value: string) {
    return getAvatarLayerSrcByType(type) === value ? '5px #FFD008 solid' : '5px #FFFFFF solid'
  }

  function findIndexByType(type: string) {
    return custom.findIndex((object) => object.type === type)
  }

  function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find((key) => object[key] === value)
  }

  function customize(element: string, layerType: AvatarLayerTypeEnum, theme?: any) {
    if (theme) {
      if (theme.src) {
        setCurrentTheme(theme.key)
      } else {
        setCurrentTheme(theme)
      }
    }

    let tempArray = [...custom]

    const index = tempArray.findIndex((item) => item.type === layerType)

    let layer = {
      type: layerType,
      src: element,
    }
    if (element && layerType) {
      if (index < 0) tempArray.push(layer)
      else tempArray[index] = layer
    } else if (index !== -1) {
      tempArray.splice(index, 1)
    }
    setCustom(tempArray)
  }
  const bkgd = custom.find((avatar) => avatar.type === 'BKGD_COLOR')

  let userBounties: any = []

  if (user.bounties)
    Object.keys(user.bounties).map(
      (permanent) =>
        user.bounties[permanent].unlockedBy && userBounties.push(user.bounties[permanent].key)
    )
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} scrollable>
        <Modal.Header
          style={{
            backgroundColor:
              bkgd && getPrimaryBkgd(bkgd.src) ? getPrimaryBkgd(bkgd.src) : '#E0E0E0',
            padding: '0',
            height: '150px',
            position: 'relative',
          }}
        >
          <img
            alt='theme'
            src={toAbsoluteUrl(
              themeBkgd[currentTheme] && themeBkgd[currentTheme].src
                ? themeBkgd[currentTheme].src
                : '/media/themes/illu_dressing.svg'
            )}
            style={{height: '102%', width: '100%', objectFit: 'cover'}}
          />
          <div style={{position: 'absolute', left: '0', right: '0'}}>
            <AvatarRendererFullBody avatarLayers={custom} dimension={dimension} uid={user.uid} />
          </div>
          <span
            role={'button'}
            onClick={() => {
              setShow(false)
            }}
            style={{position: 'absolute', top: '5px', right: '5px', zIndex: 999}}
          >
            <img
              alt='platform logo'
              className='h-20px logo'
              src={toAbsoluteUrl('/media/icons/duotune/arrows/arr011.svg')}
            />
          </span>
          <button
            className='btn btn-sm btn-light'
            style={{position: 'absolute', zIndex: 999, right: '5px', bottom: '2px', color: 'black'}}
            onClick={() => {
              handleChanges()
            }}
          >
            {intl.formatMessage({id: 'AVATAR.SAVE'})}
          </button>
        </Modal.Header>
        <Modal.Body
          style={{height: window.innerHeight < 1000 ? window.innerHeight : window.innerHeight / 2}}
        >
          {option === 'expression' && (
            <>
              <span className='fw-bold'>{intl.formatMessage({id: 'AVATAR.EXPRESSIONS'})}</span>
              <div className='text-center mt-10'>
                <input
                  style={{fontFamily: 'WalkieTalkie'}}
                  className='text-center fs-3'
                  maxLength={3}
                  value={getAvatarLayerSrcByType(AvatarLayerTypeEnum.expressionTxt) || ''}
                  onChange={(e) => customize(e.target.value, AvatarLayerTypeEnum.expressionTxt)}
                ></input>
              </div>
            </>
          )}
          {option === 'body' && (
            <>
              <span className='fw-bold'>{intl.formatMessage({id: 'AVATAR.BODY'})}</span>
              <div className='text-center'>
                {Object.keys(ProviderShellAvatarColors).map((shellColor, shellColorIndex) =>
                  Object.values(bundleKeys).includes(ProviderShellAvatarColors[shellColor]) ? (
                    <div
                      key={shellColorIndex + '_editor'}
                      className='h-65px w-65px ms-1 me-1 mt-2'
                      style={{
                        border: getCssBorderValue(AvatarLayerTypeEnum.shellColor, shellColor),
                        display: 'inline-block',
                        padding: '3px',
                        borderRadius: '20px',
                        position: 'relative',
                      }}
                    >
                      <span
                        className='me-5'
                        style={{
                          backgroundColor: shellColor,
                          display: 'inline-block',
                          height: '100%',
                          width: '100%',
                          borderRadius: '15px',
                        }}
                        onClick={() => customize(shellColor, AvatarLayerTypeEnum.shellColor)}
                      ></span>
                      <div>
                        <i
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            backgroundColor: 'white',
                            opacity: '0.5',
                            borderBottomLeftRadius: '6px',
                            borderTopRightRadius: '15px',
                            height: '20px',
                            width: '27px',
                            lineHeight: '20px',
                          }}
                        ></i>
                        <img
                          alt='shell color'
                          src={toAbsoluteUrl(
                            userBounties.includes(ProviderShellAvatarColors[shellColor])
                              ? '/media/icons/duotune/arrows/arr085.svg'
                              : '/media/avatars/talky/icon_crown_wt_plus.svg'
                          )}
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '9px',
                            height: '13px',
                            width: '13px',
                            lineHeight: '20px',
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      key={shellColorIndex + '_editor'}
                      className='h-65px w-65px ms-1 me-1 mt-2'
                      style={{
                        border: getCssBorderValue(AvatarLayerTypeEnum.shellColor, shellColor),
                        display: 'inline-block',
                        padding: '3px',
                        borderRadius: '20px',
                        position: 'relative',
                      }}
                    >
                      <span
                        className=' me-5'
                        style={{
                          backgroundColor: shellColor,
                          display: 'inline-block',
                          height: '100%',
                          width: '100%',
                          borderRadius: '15px',
                        }}
                        onClick={() => customize(shellColor, AvatarLayerTypeEnum.shellColor)}
                      ></span>
                      {ProviderShellAvatarColors[shellColor] === 'SPECIAL' && (
                        <div>
                          <i
                            style={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              backgroundColor: 'white',
                              opacity: '0.5',
                              borderBottomLeftRadius: '6px',
                              borderTopRightRadius: '15px',
                              height: '20px',
                              width: '27px',
                              lineHeight: '20px',
                            }}
                          ></i>
                          <img
                            alt='shell color'
                            src={toAbsoluteUrl(
                              userBounties.includes(ProviderShellAvatarColors[shellColor])
                                ? '/media/icons/duotune/arrows/arr085.svg'
                                : '/media/avatars/talky/icon_crown_wt_plus.svg'
                            )}
                            style={{
                              position: 'absolute',
                              top: '5px',
                              right: '9px',
                              height: '13px',
                              width: '13px',
                              lineHeight: '20px',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </>
          )}
          {option === 'bkgd' && (
            <>
              <span className='fw-bold'>{intl.formatMessage({id: 'AVATAR.BKGD'})}</span>
              <div className='text-center'>
                <div>
                  {Object.keys(ProviderBkgdAvatarColors).map((bkgd, bkgdIndex) =>
                    ProviderBkgdAvatarColors[bkgd] === 'free' ? (
                      <div
                        key={bkgdIndex + '_editor'}
                        className='h-65px w-65px ms-1 me-1 mt-2'
                        style={{
                          border: getCssBorderValue(
                            AvatarLayerTypeEnum.bkgdColor,
                            ProviderOpacAvatarColors[bkgd]
                          ),
                          display: 'inline-block',
                          padding: '3px',
                          borderRadius: '20px',
                        }}
                      >
                        <span
                          className=' me-5'
                          style={{
                            backgroundColor: bkgd,
                            display: 'inline-block',
                            height: '100%',
                            width: '100%',
                            borderRadius: '15px',
                          }}
                          onClick={() => {
                            customize(
                              ProviderOpacAvatarColors[bkgd],
                              AvatarLayerTypeEnum.bkgdColor,
                              getKeyByValue(colorTheme, bkgd)
                            )
                          }}
                        ></span>
                      </div>
                    ) : (
                      <div
                        key={bkgdIndex + '_editor'}
                        className='h-65px w-65px ms-1 me-1 mt-2'
                        style={{
                          border: getCssBorderValue(AvatarLayerTypeEnum.bkgdColor, bkgd),
                          display: 'inline-block',
                          padding: '3px',
                          borderRadius: '20px',
                          position: 'relative',
                        }}
                      >
                        <span
                          className='me-5'
                          style={{
                            backgroundColor: bkgd,
                            display: 'inline-block',
                            height: '100%',
                            width: '100%',
                            borderRadius: '15px',
                          }}
                          onClick={() =>
                            customize(getPrimaryBkgd(bkgd), AvatarLayerTypeEnum.bkgdColor)
                          }
                        ></span>
                        <div>
                          <i
                            style={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              backgroundColor: 'white',
                              opacity: '0.5',
                              borderBottomLeftRadius: '6px',
                              borderTopRightRadius: '15px',
                              height: '20px',
                              width: '27px',
                              lineHeight: '20px',
                            }}
                          ></i>
                          <img
                            alt='crown'
                            src={toAbsoluteUrl('/media/avatars/talky/icon_crown_wt_plus.svg')}
                            style={{
                              position: 'absolute',
                              top: '5px',
                              right: '9px',
                              height: '13px',
                              width: '13px',
                              lineHeight: '20px',
                            }}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className=' d-flex flex-wrap text-center'>
                  {Object.values(themeBkgd).map((bkgdTheme, bkgdIndex) => (
                    <div
                      style={{
                        width: '40%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <div className='mt-5 fw-bold'>
                        <span>{intl.formatMessage({id: 'THEME.' + bkgdTheme.trad})}</span>
                      </div>
                      <div
                        key={bkgdIndex + '_editor'}
                        style={{
                          border: getCssBorderValue(AvatarLayerTypeEnum.bkgdColor, bkgdTheme.color),
                          display: 'inline-block',
                          padding: '3px',
                          borderRadius: '20px',
                          position: 'relative',
                        }}
                      >
                        <img
                          alt='bkdg theme'
                          src={toAbsoluteUrl(bkgdTheme.src)}
                          style={{
                            objectFit: 'cover',
                            width: '100%',
                            backgroundColor: getPrimaryBkgd(bkgdTheme.color),
                            height: '100px',
                            borderRadius: '15px',
                            position: 'relative',
                          }}
                          onClick={() =>
                            customize(bkgdTheme.color, AvatarLayerTypeEnum.bkgdColor, bkgdTheme)
                          }
                        />
                        <div>
                          <i
                            style={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              backgroundColor: 'white',
                              opacity: '0.5',
                              borderBottomLeftRadius: '6px',
                              borderTopRightRadius: '15px',
                              height: '20px',
                              width: '27px',
                              lineHeight: '20px',
                            }}
                          ></i>
                          <img
                            alt='crown'
                            src={toAbsoluteUrl(
                              userBounties.includes(bkgdTheme.lockType)
                                ? '/media/icons/duotune/arrows/arr085.svg'
                                : '/media/avatars/talky/icon_crown_wt_plus.svg'
                            )}
                            style={{
                              position: 'absolute',
                              top: '5px',
                              right: '9px',
                              height: '13px',
                              width: '13px',
                              lineHeight: '20px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {option === 'asset' && (
            <>
              <span className='fw-bold ms-5'>{intl.formatMessage({id: 'AVATAR.SPOTLIGHT'})}</span>
              <div className='mb-5'>
                <AssetProvider
                  assetType={spotlightAsset}
                  custom={custom}
                  setCustom={setCustom}
                  user={user}
                />
              </div>
              <span className='fw-bold ms-5'>{intl.formatMessage({id: 'AVATAR.EYEWEAR'})}</span>
              <div className='mb-5'>
                <AssetProvider
                  assetType={eyewearAsset}
                  custom={custom}
                  setCustom={setCustom}
                  user={user}
                />
              </div>
              <span className=' fw-bold ms-5'>{intl.formatMessage({id: 'AVATAR.HEADWEAR'})}</span>
              <div className=' mb-5'>
                <AssetProvider
                  assetType={headwearAsset}
                  custom={custom}
                  setCustom={setCustom}
                  user={user}
                />
              </div>
              <span className=' fw-bold ms-5'>{intl.formatMessage({id: 'AVATAR.MASK'})}</span>
              <div className=' mb-5'>
                <AssetProvider
                  assetType={maskAsset}
                  custom={custom}
                  setCustom={setCustom}
                  user={user}
                />
              </div>
              <span className=' fw-bold ms-5'>{intl.formatMessage({id: 'AVATAR.SUIT'})}</span>
              <div className=' mb-5'>
                <AssetProvider
                  assetType={suitAsset}
                  custom={custom}
                  setCustom={setCustom}
                  user={user}
                />
              </div>
              <span className='fw-bold ms-5'>{intl.formatMessage({id: 'AVATAR.ACCESSORY'})}</span>
              <div className=' mb-5'>
                <AssetProvider
                  assetType={accessoryAsset}
                  custom={custom}
                  setCustom={setCustom}
                  user={user}
                />
              </div>
              <span className='fw-bold ms-5'>{intl.formatMessage({id: 'AVATAR.SPECIAL'})}</span>
              <div className=' mb-5'>
                <AssetProvider
                  assetType={specialAsset}
                  custom={custom}
                  setCustom={setCustom}
                  user={user}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: 'black', justifyContent: 'center', padding: '0px'}}>
          <div className='d-flex'>
            <button
              className={'btn btn-sm ms-2 me-2 ' + (option === 'body' && 'btn-warning')}
              style={{color: 'white'}}
              onClick={() => setOption('body')}
            >
              {intl.formatMessage({id: 'AVATAR.BODY'})}
            </button>
            <button
              className={'btn btn-sm ms-2 me-2 ' + (option === 'asset' && 'btn-warning')}
              style={{color: 'white'}}
              onClick={() => setOption('asset')}
            >
              {intl.formatMessage({id: 'AVATAR.ACCESSORY'})}
            </button>
            <button
              className={'btn btn-sm ms-2 me-2 ' + (option === 'expression' && 'btn-warning')}
              style={{color: 'white'}}
              onClick={() => setOption('expression')}
            >
              {intl.formatMessage({id: 'AVATAR.EXPRESSIONS'})}
            </button>
            <button
              className={'btn btn-sm ms-2 me-2 ' + (option === 'bkgd' && 'btn-warning')}
              style={{color: 'white'}}
              onClick={() => setOption('bkgd')}
            >
              {intl.formatMessage({id: 'AVATAR.BKGD'})}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AvatarRendererModal
