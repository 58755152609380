import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as profanities from '../dashboard/redux/ProfanitiesRedux'
import {ProviderLanguageCode} from '../../helpers/provider/ProviderLanguageCode'

type Props = {
  show: any
  setShow: any
}
const AddProfanityModal: React.FC<Props> = ({show, setShow}) => {
  const [language, setLanguage] = useState('')
  const [word, setWord] = useState('')
  const dispatch = useDispatch()
  const intl = useIntl()
  const handleClose = () => setShow(false)

  function handleSave() {
    dispatch(profanities.profanitiyActions.addProfanity(language, word))
    setShow(false)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        scrollable
        style={{top: '15%'}}
      >
        <Modal.Body>
          <div className='d-flex mt-10 h-200px'>
            <div>
              <span className='text-dark fw-bolder d-block fs-6 mb-2'>
                {' '}
                {intl.formatMessage({id: 'USER.PROFANITY.TITLE'})}{' '}
              </span>
              <select
                name='bounty'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm w-200px me-10 mb-10'
                onChange={(e) => {
                  setLanguage(e.target.value)
                }}
              >
                <option value=''></option>
                {Object.keys(ProviderLanguageCode).map((languageCode, index) => (
                  <option key={index} value={languageCode}>
                    {languageCode}
                  </option>
                ))}
              </select>
              {language && (
                <div>
                  <input
                    className={'form-control form-control-solid w-200px '}
                    onChange={(e) => {
                      setWord(e.target.value)
                    }}
                  ></input>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex'>
            <button
              className='btn btn-light-danger ms-20 mt-20 me-10'
              onClick={() => setShow(false)}
            >
              {intl.formatMessage({id: 'USER.BOUNTIES.CANCEL'})}
            </button>
            <button className=' btn btn-light-success me-20 mt-20' onClick={() => handleSave()}>
              {intl.formatMessage({id: 'USER.BOUNTIES.SAVE'})}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddProfanityModal
