import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as users from '../../app/modules/users'
import * as record from '../../app/pages/dashboard'
import * as account from '../../app/pages/dashboard'
import * as device from '../../app/pages/dashboard'
import * as dashboard from '../../app/pages/dashboard'
import * as profanities from '../../app/pages/dashboard'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: users.userReducer,
  record: record.recordReducer,
  account: account.accountReducer,
  device: device.deviceReducer,
  dashboard: dashboard.dashboardReducer,
  profanities: profanities.profanityReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    users.userSaga(),
    record.recordSaga(),
    account.accountSaga(),
    device.deviceSaga(),
    dashboard.dashboardSaga(),
    profanities.profanitiesSaga(),
  ])
}
