import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import DatePicker from 'react-datepicker'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {bundleKeys} from '../../../../helpers/provider/BountiesProvider'
import {UserWrapperModel} from '../../../auth/models/AccountUserWrapperModel'
import * as users from '../../../users/redux/UserRedux'

type Props = {
  show: any
  setShow: any
}
const UserBountiesModal: React.FC<Props> = ({show, setShow}) => {
  const currentUser = useSelector<RootState>(
    ({users}) => users.currentUser,
    shallowEqual
  ) as UserWrapperModel
  let user = currentUser.user
  let expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + 1)

  const dispatch = useDispatch()
  const intl = useIntl()
  const handleClose = () => setShow(false)
  const [bounty, setBounty] = useState('')
  const [system, setSystem] = useState('')
  const [duration, setDuration] = useState(expirationDate)

  function handleSave() {
    if (system === 'SYSTEM_GIFT')
      dispatch(users.userActions.bountiesAcquire(currentUser, user.uid, bounty, system))
    else {
      dispatch(
        users.userActions.bountiesAcquire(currentUser, user.uid, bounty, system, duration.getTime())
      )
    }
    handleClose()
  }

  let permanentBounties: any = []

  if (user.bounties)
    Object.keys(user.bounties).map(
      (permanent) =>
        user.bounties[permanent].unlockedBy.SYSTEM_GIFT &&
        permanentBounties.push(user.bounties[permanent].key)
    )

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        scrollable
        size='lg'
        style={{top: '20%'}}
      >
        <Modal.Body>
          <div className='d-flex mt-10 h-200px'>
            <div>
              <span className='text-dark fw-bolder d-block fs-6 mb-2'>
                {' '}
                {intl.formatMessage({id: 'USER.BOUNTIES.TOUNLOCK'})}{' '}
              </span>
              <select
                name='bounty'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm w-200px me-10 mb-10'
                onChange={(e) => {
                  setBounty(e.target.value)
                  setSystem('')
                }}
              >
                <option value=''></option>
                {Object.keys(bundleKeys).map(
                  (bounty, index) =>
                    !permanentBounties.includes(bounty) && (
                      <option key={index} value={bounty}>
                        {bounty}
                      </option>
                    )
                )}
              </select>
              {bounty && (
                <>
                  <span className='text-dark fw-bolder d-block fs-6 mb-2'>
                    {intl.formatMessage({id: 'USER.BOUNTIES.METHOD'})}
                  </span>
                  <select
                    name='bounty'
                    data-control='select2'
                    data-hide-search='true'
                    className='form-select form-select-sm w-200px me-10'
                    value={system}
                    onChange={(e) => setSystem(e.target.value)}
                  >
                    <option value=''></option>
                    <option value='SYSTEM_GIFT'>
                      {intl.formatMessage({id: 'USER.BOUNTIES.PERMANENT'})}
                    </option>
                    <option value='SYSTEM_GIFT_TMP'>
                      {intl.formatMessage({id: 'USER.BOUNTIES.TEMPORARY'})}
                    </option>
                  </select>
                </>
              )}
            </div>
            {system === 'SYSTEM_GIFT_TMP' && (
              <div className='column'>
                <span className='text-dark fw-bolder d-block fs-6 mb-2'>
                  {intl.formatMessage({id: 'USER.BOUNTIES.UNTIL'})}
                </span>
                <div>
                  <DatePicker
                    selected={duration}
                    onChange={(date: Date) => setDuration(date)}
                    className={'form-control form-control-sm'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='d-flex'>
            <button
              className='btn btn-light-danger ms-20 mt-20 me-auto'
              onClick={() => setShow(false)}
            >
              {intl.formatMessage({id: 'USER.BOUNTIES.CANCEL'})}
            </button>
            {system && (
              <button className=' btn btn-light-success me-20 mt-20' onClick={() => handleSave()}>
                {intl.formatMessage({id: 'USER.BOUNTIES.SAVE'})}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserBountiesModal
