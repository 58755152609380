/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import React, {useState, useEffect} from 'react'
import * as device from '../dashboard/redux/DeviceRedux'
import * as account from '../dashboard/redux/AccountRedux'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {DeviceBlacklistModel} from './models/DeviceBlacklistModel'
import {timestampConverter} from '../../helpers/converter/Common'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {logos} from '../../helpers/provider/ProviderDataLogo'
import {Link} from 'react-router-dom'
import {DeviceFilters} from './components/DeviceFilters'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {FiltersModel} from '../../modules/users/models/FiltersModel'

const DeviceBlackList: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [noMoreResult, setNoMoreResult] = useState(false)

  const deviceList = useSelector<RootState>(
    ({device}) => device.devicesList,
    shallowEqual
  ) as DeviceBlacklistModel[]
  const result = useSelector<RootState>(
    ({device}) => device.result,
    shallowEqual
  ) as DeviceBlacklistModel[]
  const searchOnly = useSelector<RootState>(
    ({device}) => device.searchOnly,
    shallowEqual
  ) as FiltersModel
  const moderatorList = useSelector<RootState>(
    ({account}) => account.moderatorsList,
    shallowEqual
  ) as any

  const dispatch = useDispatch()
  const intl = useIntl()

  const [filters, setFilters] = useState({
    filterKey: '',
    filterValue: '',
    filterOperator: '==',
    filterType: '',
    sortingOrder: 'ASC',
  })

  useEffect(() => {
    if (deviceList === null || deviceList.length === 0) {
      dispatch(device.deviceActions.getDevices(''))
    }
    if (moderatorList.length === 0 || moderatorList === null)
      dispatch(account.accountActions.getModeratorsList())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (deviceList.length > 0) {
      setLoading(false)
      setLoadMore(false)
      setNoMoreResult(false)

      // initialize moderation dropdowns to their buttons
      setTimeout(function () {
        MenuComponent.reinitialization()
      }, 200)
    } else {
    }
  }, [deviceList])

  useEffect(() => {
    if (!result) {
      setLoading(false)
      setLoadMore(false)
      setNoMoreResult(true)
    }
  }, [result])

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {intl.formatMessage({id: 'MENU.DEVICEBLACKLIST'})}
          </span>
        </h3>
      </div>
      <div>
        <div className='me-10'>
          <DeviceFilters filters={filters} setFilters={setFilters} setLoading={setLoading} />
        </div>
        <div className='card-body py-3'>
          {!result && deviceList.length === 0 ? (
            <div className=' text-center mt-10 mb-10'>
              <span> {intl.formatMessage({id: 'FILTER.NORESULT'})}</span>
            </div>
          ) : loading ? (
            <div className=' text-center mt-10 mb-10'>
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'LOADER'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : (
            <div>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 card-body py-5'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th>UDID</th>
                      <th>UID</th>
                      <th>{intl.formatMessage({id: 'USER.PLATFORM'})}</th>
                      <th>Expiration</th>
                      <th>Type</th>
                      <th>{intl.formatMessage({id: 'SANCTION.MODERATOR'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deviceList.map((deviceItem, index) => (
                      <tr key={index}>
                        <th>
                          <p>{deviceItem.udid}</p>
                        </th>
                        <th>
                          <Link to={{pathname: 'users/' + deviceItem.sanction.uid}}>
                            <span className='text-dark fw-bolder text-hover-primary fs-6'>
                              {deviceItem.sanction.uid}
                            </span>
                          </Link>
                        </th>
                        <th>
                          <img
                            alt='platform logo'
                            className='h-20px logo'
                            src={toAbsoluteUrl(logos[deviceItem.platform])}
                          />
                        </th>
                        <th>
                          {deviceItem.sanction.expiration &&
                            timestampConverter(deviceItem.sanction.expiration)}
                        </th>
                        <th>
                          <span
                            className={
                              'd-block badge badge-' +
                              (deviceItem.sanction.type === 'SUSPENDED' ? 'warning' : 'danger')
                            }
                          >
                            {deviceItem.sanction.type}
                          </span>
                        </th>
                        <th>
                          {moderatorList[deviceItem.sanction.moderatorId] &&
                            moderatorList[deviceItem.sanction.moderatorId]}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className='btn-custom align-items-center text-center mb-5'>
          {deviceList.length % 10 !== 0 ? (
            ''
          ) : deviceList.length === 0 ? (
            ''
          ) : (
            <div>
              {noMoreResult ? (
                <p> {intl.formatMessage({id: 'FILTER.NORESULT'})}</p>
              ) : loadMore ? (
                <button
                  type='button'
                  className='btn btn-sm btn-info btn-active-light-primary w-100px'
                >
                  <span className='spinner-border spinner-border-sm text-light'></span>
                </button>
              ) : (
                !searchOnly && (
                  <button
                    className='btn btn-sm btn-info btn-active-light-primary w-100px'
                    onClick={() => {
                      dispatch(
                        device.deviceActions.getDevices(
                          deviceList[deviceList.length - 1].udid,
                          true,
                          filters.filterKey,
                          filters.filterValue,
                          filters.sortingOrder,
                          filters.filterType,
                          filters.filterOperator
                        )
                      )
                      setLoadMore(true)
                    }}
                  >
                    {intl.formatMessage({id: 'LOADMORE'})}
                  </button>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {DeviceBlackList}
