import React from 'react'
import {timestampConverter, timestampHoursConverter} from '../../../../helpers/converter/Common'
import {getFlagEmoji} from '../../../../helpers/converter/LocaleCodeToFlagConverter'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {logos} from '../../../../helpers/provider/ProviderDataLogo'
import {phoneToFlag} from '../../../../helpers/converter/PhoneLocalCodeToFlagConverter'
import {useIntl} from 'react-intl'
import {colorTheme, themeLogo} from '../../../../helpers/provider/ProviderThemeData'
import {getUserAgeFromBirthdate} from '../../../../helpers/converter/BirthdateToAge'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../../auth/models/AccountUserWrapperModel'
import {RoleCheckMin} from '../../../../helpers/RoleRestriction'
import {Roles} from '../../../auth/models/RolesEnum'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {ProviderAudioFilter} from '../../../../helpers/provider/ProviderAudiotFilterLogo'
type Props = {
  userData: UserWrapperModel
}
const UserOverview: React.FC<Props> = ({userData}) => {
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel
  const user = userData.user
  const intl = useIntl()
  let uniquePlatform: string[] = []

  return (
    <div className=''>
      <div className='card card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>
            {intl.formatMessage({id: 'USER.NICKNAME'})}
          </label>
          <div className='col-lg-8 fv-row'>
            <span className='fs-7'>{user.nickname + user.nicknameId}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>{intl.formatMessage({id: 'USER.USERUID'})}</label>
          <div className='col-lg-8'>
            <span className='er fs-7 text-dark'>{user.uid}</span>
          </div>
        </div>
        {RoleCheckMin(Roles.Admin, admin.account.role) && (
          <>
            <div className='row mb-7'>
              <label className='col-lg-4  text-muted'>
                {intl.formatMessage({id: 'USER.PHONE'})}
                <i
                  className='fas ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Phone number must be active'
                ></i>
              </label>
              <div className='col-lg-8 d-flex align-items-center'>
                {!user.phone?.internationalNumber ? (
                  <span className='badge badge-light'>{intl.formatMessage({id: 'NONE'})}</span>
                ) : (
                  <span className='er fs-7 me-2'>
                    {phoneToFlag[user.phone.countryCode] + user.phone.internationalNumber}
                  </span>
                )}
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4  text-muted'>Email</label>
              <div className='col-lg-8'>
                {user.email === '' ? (
                  <span className='badge badge-light'>None</span>
                ) : (
                  <span className='er fs-7 me-2'>{user.email}</span>
                )}
              </div>
            </div>
          </>
        )}
        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>
            {intl.formatMessage({id: 'USER.COUNTRY'})}
            <i
              className='fas ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>

          <div className='col-lg-8'>
            <div className=''>
              {getFlagEmoji(user.localeCode)} {user.localeCode}
            </div>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>
            {' '}
            {intl.formatMessage({id: 'USER.PROVIDER'})}
            <i
              className='fas ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>
          <div className='col-lg-8'>
            {user.providerData &&
              user.providerData.map((providerdata, providerdataIndex) => (
                <span className=' fs-7' key={providerdataIndex}>
                  {providerdata.providerId != null ? (
                    <img
                      alt='provider logo'
                      className='h-20px w-20px logo flex-shrink-0 mb-1 me-2'
                      src={toAbsoluteUrl(logos[providerdata.providerId])}
                    />
                  ) : null}
                </span>
              ))}
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>
            {' '}
            {intl.formatMessage({id: 'USER.PLATFORM'})}
            <i
              className='fas ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>

          <div className='col-lg-8'>
            {user.fcmTokens.map((fcmToken, fcmTokenIndex) => {
              if (!uniquePlatform.includes(fcmToken.platform)) {
                uniquePlatform.push(fcmToken.platform)
                return (
                  <span className=' fs-7 text-dark' key={fcmTokenIndex}>
                    <img
                      alt='platform logo'
                      className='h-20px w-20px logo flex-shrink-0 mb-1 me-2'
                      src={toAbsoluteUrl(logos[fcmToken.platform])}
                    />
                  </span>
                )
              }
              return null
            })}
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>
            {intl.formatMessage({id: 'USER.JOINED'})}
            <i
              className='fas ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>

          <div className='col-lg-8'>
            <span className='er fs-7 text-dark'>
              {timestampHoursConverter(user.metaData.createdTimestamp)}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>Age</label>
          <div className='col-lg-8'>
            <span className='er fs-7 text-dark'>
              {user.profile.birthdate
                ? timestampConverter(user.profile.birthdate) +
                  ' / ' +
                  getUserAgeFromBirthdate(user.profile.birthdate) +
                  ' ' +
                  intl.formatMessage({id: 'USER.AGE'})
                : user.profile.age
                ? user.profile.age + ' ' + intl.formatMessage({id: 'USER.AGE'})
                : ''}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>Current audiofilters</label>
          <div className='col-lg-8'>
            <span className='er fs-7 text-dark'>
              {user.profile.audioFilter in ProviderAudioFilter ? (
                <img
                  alt=''
                  className='h-20px logo flex-shrink-0 me-5 mb-1'
                  src={toAbsoluteUrl(ProviderAudioFilter[user.profile.audioFilter])}
                />
              ) : (
                <img
                  alt=''
                  className='h-20px logo flex-shrink-0 me-5 mb-1'
                  src={toAbsoluteUrl('/media/audioFilter/No_picto_filter.svg')}
                />
              )}
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4  text-muted'>Theme</label>
          <div className='col-lg-8'>
            {!user.profile.currentTheme ||
            !(user.profile.currentTheme in themeLogo || colorTheme) ? (
              <span className='badge badge-light'>None</span>
            ) : user.profile.currentTheme in themeLogo ? (
              <img
                alt=''
                className='h-20px logo flex-shrink-0 me-5 mb-1'
                src={toAbsoluteUrl(themeLogo[user.profile.currentTheme])}
              />
            ) : (
              <span
                className='rounded me-5 h-15px w-15px'
                style={{
                  backgroundColor: colorTheme[user.profile.currentTheme],
                  display: 'inline-block',
                }}
              ></span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserOverview
