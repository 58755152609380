import {Roles, RolesValue} from '../modules/auth/models/RolesEnum'

export function RoleCheckMin(min: Roles, current: Roles) {
  const currentValue = RolesValue.get(current)
  const minValue = RolesValue.get(min)

  return currentValue !== undefined && minValue !== undefined && currentValue >= minValue
}

export function RoleCheckAllowed(allowed: Roles[], current: Roles) {
  const currentValue = RolesValue.get(current)
  for (let role of allowed) {
    let roleValue = RolesValue.get(role)
    if (currentValue !== undefined && roleValue !== undefined && currentValue === roleValue)
      return true
  }
  return false
}

export function RoleCheckNotAllowed(notAllowed: Roles[], current: Roles) {
  const currentValue = RolesValue.get(current)
  for (let role of notAllowed) {
    let roleValue = RolesValue.get(role)
    if (currentValue !== undefined && roleValue !== undefined && currentValue === roleValue)
      return false
  }
  return true
}
