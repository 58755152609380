import React, {useEffect, useState} from 'react'
import {useDispatch, shallowEqual, useSelector} from 'react-redux'
import {useLocation, useParams, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as users from '../../../users/redux/UserRedux'
import * as account from '../../../../pages/dashboard/redux/AccountRedux'
import {logos} from '../../../../helpers/provider/ProviderDataLogo'
import {RootState} from '../../../../../setup'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../../auth/models/AccountUserWrapperModel'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import ModerationDropdown from '../../../../../_metronic/partials/content/dropdown/ModerationDropdown'
import UserOverview from './UserOverview'
import UserFriends from './UserFriends'
import UserModeration from './UserModeration'
import UserUdids from './UserUdids'
import {getFlagEmoji} from '../../../../helpers/converter/LocaleCodeToFlagConverter'
import UserPurchases from './UserPurchases'
import {RoleCheckMin} from '../../../../helpers/RoleRestriction'
import {Roles} from '../../../auth/models/RolesEnum'
import {useIntl} from 'react-intl'
import UserBounties from './UserBounties'
import UserAccount from './UserAccount'
import UserHive from './UserHive'
import {FrequencyModel} from '../../../users/models/FrequencyModel'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import AvatarRendererFullBody from '../AvatarRendererFullBody'
import {themeBkgd} from '../../../../helpers/provider/ProviderThemeData'
import {ProviderOpacAvatarColors} from '../../../../helpers/provider/ProviderColor'
import UserAchievements from './UserAchievements'
import UserAccountLinked from './UserAccountLinked'
import AdminDropdown from '../../../../../_metronic/partials/content/dropdown/AdminDropdown'

type Props = {
  userData: UserWrapperModel
}

type userPageParams = {
  id: string
}
const UserPage: React.FC<Props> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [userTab, setUserTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [error, setError] = useState('')
  const [kickLoading, setKickLoading] = useState(false)
  const history = useHistory()
  const location = useLocation<Props>()
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel
  const currentUser = useSelector<RootState>(
    ({users}) => users.currentUser,
    shallowEqual
  ) as UserWrapperModel
  const currentFrequency = useSelector<RootState>(
    ({users}) => users.userFrequency,
    shallowEqual
  ) as FrequencyModel
  const frequencyLoading = useSelector<RootState>(
    ({users}) => users.getFrequencyLoader,
    shallowEqual
  ) as boolean
  const moderatorList = useSelector<RootState>(
    ({account}) => account.moderatorsList,
    shallowEqual
  ) as any
  const currentUserLoading = useSelector<RootState>(
    ({users}) => users.currentUserLoading,
    shallowEqual
  ) as string | boolean
  const currentUserResult = useSelector<RootState>(
    ({users}) => users.currentUserResult,
    shallowEqual
  ) as boolean

  const errorMsg = useSelector<RootState>(({users}) => users.error, shallowEqual) as string

  const [updateUser, setUpdateUser] = useState<UserWrapperModel>(
    location.state?.userData || currentUser
  )

  const {id} = useParams<userPageParams>()
  const user = currentUser?.user
  let uniquePlatform: string[] = []
  const [currentTheme, setCurrentTheme] = useState('')

  useEffect(() => {
    if (moderatorList.length === 0 || moderatorList === null)
      dispatch(account.accountActions.getModeratorsList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(users.userActions.agoraGetParticipantFrequency(id))
    dispatch(users.userActions.get1User(id))
    if (!user) setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (user && user.uid === id) {
      setLoading(false)
    }
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 200)
    setCurrentTheme(user?.profile.currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    setError(errorMsg)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMsg])

  useEffect(() => {
    setLoading(true)
    if (user?.uid === currentUserLoading) setLoading(false)
    if (!currentUserResult && id !== currentUserLoading) setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserLoading])

  function handleUpdate() {
    setUpdate(!update)
  }
  function handleEdit() {
    const isAModerator =
      currentUser.user.bounties?.MODERATOR && admin.user.wtUserUid === currentUser.user.uid

    if (updateUser.user.nickname.length < 4) setError(intl.formatMessage({id: 'ERROR.TOSHORT'}))
    else if (updateUser.user.nickname.length > 18 && !isAModerator) setError('ERROR.TOLONG')
    else if (user?.nickname.toUpperCase() === updateUser?.user.nickname.toUpperCase())
      setError('ERROR.SAMENICKNAME')
    else if (!/^\w*$/.test(updateUser?.user.nickname) && !isAModerator)
      setError('ERROR.INVALIDCHARACTERS')
    else {
      handleUpdate()
      dispatch(users.userActions.editUser(updateUser, updateUser.user.nickname, null))
    }
  }

  function getPrimaryBkgd(bkgd: string) {
    let primaryColor = Object.keys(ProviderOpacAvatarColors).find(
      (key) => ProviderOpacAvatarColors[key] === bkgd
    )
    return primaryColor ? primaryColor : ''
  }
  let bkgd: any
  if (currentUser && currentUser.user.profile && currentUser.user.profile.avatarLayers) {
    bkgd = currentUser.user.profile.avatarLayers.find((avatar) => avatar.type === 'BKGD_COLOR')
  }

  // let allTimeStamp: any = []

  // function findReportDate(reportObject: any) {
  //   reportObject.map((report: any) => allTimeStamp.push(report.date))
  //   const groupedByDay: any = {}
  //   if (allTimeStamp)
  //     allTimeStamp.forEach((timestamp: any) => {
  //       const date = new Date(timestamp)

  //       const day = date.toISOString().split('T')[0]

  //       if (!groupedByDay[day]) {
  //         groupedByDay[day] = []
  //       }
  //       groupedByDay[day].push(timestamp)
  //     })
  //   return reportObject.length / Object.keys(groupedByDay).length
  // }

  return (
    <div className='card mb-5 mb-xl-10'>
      {user && user.isLoading && (
        <div className='d-flex flex-wrap flex-sm-nowrap position-relative'>
          <div
            className='position-absolute'
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              zIndex: 10,
              width: '100%',
              height: window.innerHeight,
            }}
          >
            <span
              className='spinner-border text-primary'
              style={{
                display: 'block',
                margin: 'auto',
                marginTop: '5%',
              }}
            ></span>
          </div>
        </div>
      )}

      <div className='card-body pt-0 pb-0 pe-0 ps-0'>
        {!loading && (
          <div
            className='d-flex position-absolute'
            style={{top: '10px', left: '10px', zIndex: 999, padding: '0px'}}
          >
            <span
              role={'button'}
              onClick={(e) => {
                e.preventDefault()
                if (history.action !== 'POP') history.goBack()
                else history.push('/users/')
              }}
            >
              <img
                alt='platform logo'
                className='h-20px logo'
                src={toAbsoluteUrl('/media/icons/duotune/arrows/arr021.svg')}
              />
            </span>
            {user && (
              <span
                className='ms-5'
                role={'button'}
                onClick={() => {
                  setLoading(true)
                  dispatch(users.userActions.get1User(id))
                  dispatch(users.userActions.agoraGetParticipantFrequency(id))
                }}
              >
                <img
                  alt='platform logo'
                  className='h-20px logo'
                  src={toAbsoluteUrl('/media/icons/duotune/arrows/arr029.svg')}
                />
              </span>
            )}
          </div>
        )}
        <div>
          {loading ? (
            <div className=' text-center mt-10 mb-10'>
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'LOADER'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : !user ? (
            <div className=' text-center mt-10 mb-10'>
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'USER.NOT.FOUND'})}
              </span>
            </div>
          ) : (
            <>
              <div
                style={{
                  backgroundColor:
                    bkgd && getPrimaryBkgd(bkgd.src) ? getPrimaryBkgd(bkgd.src) : '#E0E0E0',
                  borderRadius: '5px',
                  position: 'relative',
                }}
              >
                <div style={{position: 'absolute', right: '10px', top: '10px', zIndex: 20}}>
                  <AdminDropdown userWrappedData={currentUser} isLoading={user.isLoading} />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    left: '0',
                    right: '0',
                  }}
                >
                  <AvatarRendererFullBody
                    avatarLayers={user.profile.avatarLayers}
                    dimension={window.innerWidth < 500 ? window.innerWidth * 0.24 : 150}
                    uid={user.uid}
                    editor={true}
                    isLoading={user.isLoading}
                  />
                </div>
                <img
                  alt='theme'
                  src={toAbsoluteUrl(
                    themeBkgd[currentTheme] && themeBkgd[currentTheme].src
                      ? themeBkgd[currentTheme].src
                      : '/media/themes/illu_dressing.svg'
                  )}
                  style={{
                    maxHeight: '150px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
              </div>
              <div className='row ps-10 pe-10 pt-10'>
                <div className='col-md-3'>
                  <div className='d-flex align-items-center mb-2'>
                    {!update ? (
                      <>
                        <span
                          className='fs-2 fw-bolder me-1'
                          style={{color: errorMsg && '#f1416c'}}
                        >
                          {user.nickname + user.nicknameId}
                        </span>
                        <span className='ms-5'>
                          {!user.isLoading && (
                            <img
                              alt='edit logo'
                              className='h-20px logo'
                              src={toAbsoluteUrl('/media/icons/duotune/general/gen055.svg')}
                              onClick={() => handleUpdate()}
                            />
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <div className='d-flex'>
                          <input
                            disabled={user.isLoading}
                            placeholder={user.nickname}
                            className=' form-control form-control-sm form-control-solid'
                            onChange={(e) => {
                              setUpdateUser({
                                ...currentUser,
                                user: {...user, nickname: e.target.value},
                              })
                              setError('')
                            }}
                          ></input>
                          <div>
                            <span
                              className='btn btn-icon btn-success  ms-5'
                              onClick={() => {
                                handleEdit()
                              }}
                            >
                              <i className='fas fa-check fs-4'></i>
                            </span>
                          </div>
                          <div>
                            <span
                              className='btn btn-icon btn-danger ms-5'
                              onClick={() => {
                                handleUpdate()
                                setUpdateUser(currentUser)
                                setError('')
                              }}
                            >
                              <i className='fas fa-ban'></i>
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>{' '}
                  {error !== '' && (
                    <p className=' text-danger'>{intl.formatMessage({id: `${error}`})}</p>
                  )}
                  <div className='d-flex flex-wrap fw-bold fs-6  mb-5'>
                    <div
                      className={
                        'd-flex align-items-center mt-2 me-4 badge badge-' +
                        (user.status === 'ACTIVE'
                          ? 'success'
                          : user.status === 'SUSPENDED'
                          ? 'warning'
                          : 'danger')
                      }
                    >
                      {user.status}
                    </div>
                    <div>
                      {user.moderation.invalidationReasons?.INVALID_AVATAR_OFFENSIVE_EXPRESSION && (
                        <span className='d-block badge badge-danger me-4 mt-2'>
                          {intl.formatMessage({id: 'MODERATION.INVALID.EXPRESSION'})}
                        </span>
                      )}
                    </div>
                    <div className='mt-2'>
                      {user.moderation.invalidationReasons?.INVALID_NICKNAME_PROFANITY && (
                        <span className='d-block badge badge-danger me-4'>
                          {intl.formatMessage({id: 'MODERATION.INVALID.NICKNAME'})}
                        </span>
                      )}
                    </div>
                    <div className='d-flex align-items-center me-5 fs-2 lh-1 mt-2'>
                      {getFlagEmoji(user.localeCode)}
                    </div>
                    <div className='mt-2'>
                      {user.providerData && user.providerData.length !== 0 ? (
                        user.providerData.map((providerdata, providerdataIndex) => (
                          <span key={providerdataIndex}>
                            {providerdata.providerId != null ? (
                              <img
                                alt='provider logo'
                                className='h-20px logo flex-shrink-0 me-5 mb-1'
                                src={toAbsoluteUrl(logos[providerdata.providerId])}
                              />
                            ) : null}
                          </span>
                        ))
                      ) : (
                        <img
                          alt='provider logo'
                          className='h-20px logo flex-shrink-0 me-5 mb-1'
                          src={toAbsoluteUrl('/media/logos/anonymous.svg')}
                        />
                      )}
                    </div>
                    <div className='mt-2'>
                      {user.fcmTokens.map((fcmToken, fcmTokenIndex) => {
                        if (!uniquePlatform.includes(fcmToken.platform)) {
                          uniquePlatform.push(fcmToken.platform)
                          return (
                            <span key={fcmTokenIndex}>
                              <img
                                alt='platform logo'
                                className='h-20px logo flex-shrink-0 me-5 mb-1'
                                src={toAbsoluteUrl(logos[fcmToken.platform])}
                              />
                            </span>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='d-flex'>
                    <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-3 mb-3 col-md-4'>
                      <div className='d-flex'>
                        <div className='fs-2 fw-bolder'>
                          {
                            user.moderation.events.reports.length
                            // > 0
                            //   ? findReportDate(user.moderation.events.reports)
                            //   : 0
                          }
                        </div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>
                        {' '}
                        {intl.formatMessage({id: 'USER.TOTAL.REPORT'})}
                      </div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded  py-3 px-4 me-3 mb-3 col-md-3'>
                      <div className='d-flex'>
                        <div className='fs-2 fw-bolder'>
                          {user.moderation.events.sanctions.length}
                        </div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Sanctions</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded  py-3 px-4 me-3 mb-3 col-md-3'>
                      <div className='d-flex'>
                        <div className='fs-2 fw-bolder'>{user.friends.friendsApproved.length}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>
                        {intl.formatMessage({id: 'USER.USERPAGE.FRIENDS'})}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3 d-flex flex-column'>
                  <div className=' mb-2'>
                    {frequencyLoading || kickLoading ? (
                      <span
                        className=' badge badge-light'
                        style={{fontFamily: 'WalkieTalkie', fontSize: '130%'}}
                      >
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : currentFrequency &&
                      (currentFrequency.confAlias
                        ? currentFrequency.confAlias.length <= 12
                        : currentFrequency.frequencyAlias.length <= 12) ? (
                      <div style={{marginLeft: 'auto'}}>
                        <span
                          className=' badge badge-light-success me-5'
                          style={{fontFamily: 'WalkieTalkie', fontSize: '130%', width: '50%'}}
                        >
                          {currentFrequency.confAlias
                            ? currentFrequency.confAlias
                            : currentFrequency.frequencyAlias}
                        </span>
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id='tooltip-kick'>{intl.formatMessage({id: 'KICK'})}</Tooltip>
                          }
                        >
                          <span
                            className='btn btn-sm btn-icon btn-danger ms-1 mb-1'
                            onClick={() => {
                              dispatch(
                                users.userActions.kick(
                                  currentUser,
                                  currentFrequency.confId,
                                  currentFrequency?.frequencyAlias,
                                  () => {
                                    setKickLoading(true)
                                    setTimeout(() => {
                                      dispatch(users.userActions.agoraGetParticipantFrequency(id))
                                      setKickLoading(false)
                                    }, 3000)
                                  }
                                )
                              )
                            }}
                          >
                            <i className='fas fa-skull-crossbones fs-7'></i>
                          </span>
                        </OverlayTrigger>
                      </div>
                    ) : currentFrequency ? (
                      <span
                        className=' badge badge-light-primary'
                        style={{fontFamily: 'WalkieTalkie', fontSize: '130%'}}
                      >
                        {intl.formatMessage({id: 'PRIVATE'})}
                      </span>
                    ) : (
                      <span
                        className='badge badge-light-danger'
                        style={{fontFamily: 'WalkieTalkie', fontSize: '130%'}}
                      >
                        {intl.formatMessage({id: 'OFFLINE'})}
                      </span>
                    )}
                  </div>
                  <div className='d-flex flex-row'>
                    {RoleCheckMin(Roles.ModeratorConfirmed, currentUser.account?.role) ? (
                      <div className='col-md-12'>
                        <img
                          alt='moderator logo'
                          className='h-60px logo '
                          style={{
                            display: 'block',
                            marginLeft: 'auto',
                          }}
                          src={toAbsoluteUrl('/media/logos/moderator.svg')}
                        />
                      </div>
                    ) : (
                      RoleCheckMin(Roles.ModeratorNew, admin.account.role) &&
                      !RoleCheckMin(Roles.ModeratorNew, currentUser.account?.role) && (
                        <>
                          <div className='col-md-9'>
                            <ModerationDropdown
                              userWrappedData={currentUser}
                              isLoading={user.isLoading}
                            />
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className='ps-10 pe-10'>
                <div className='d-flex overflow-auto h-55px '>
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                      <a
                        href='#overview'
                        className={
                          `nav-link text-active-primary me-6 ` + (userTab === 0 && 'active')
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setUserTab(0)
                        }}
                      >
                        {intl.formatMessage({id: 'USER.USERPAGE.OVERVIEW'})}
                      </a>
                    </li>

                    <li className='nav-item'>
                      <a
                        href='#moderation'
                        className={
                          `nav-link text-active-primary me-6 ` + (userTab === 1 && 'active')
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setUserTab(1)
                        }}
                      >
                        {intl.formatMessage({id: 'USER.USERPAGE.MODERATION'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        href='#friends'
                        className={
                          `nav-link text-active-primary me-6 ` + (userTab === 2 && 'active')
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setUserTab(2)
                        }}
                      >
                        {intl.formatMessage({id: 'USER.USERPAGE.FRIENDS'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        href='#udids'
                        className={
                          `nav-link text-active-primary me-6 ` + (userTab === 3 && 'active')
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setUserTab(3)
                        }}
                      >
                        {intl.formatMessage({id: 'USER.USERPAGE.DEVICEID'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        href='#purchases'
                        className={
                          `nav-link text-active-primary me-6 ` + (userTab === 4 && 'active')
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setUserTab(4)
                        }}
                      >
                        {intl.formatMessage({id: 'USER.USERPAGE.PURCHASES'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        href='#bounties'
                        className={
                          `nav-link text-active-primary me-6 ` + (userTab === 5 && 'active')
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setUserTab(5)
                        }}
                      >
                        {intl.formatMessage({id: 'USER.USERPAGE.BOUNTIES'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        href='#achievements'
                        className={
                          `nav-link text-active-primary me-6 ` + (userTab === 6 && 'active')
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setUserTab(6)
                        }}
                      >
                        {intl.formatMessage({id: 'USER.USERPAGE.ACHIEVEMENTS'})}
                      </a>
                    </li>

                    <li className='nav-item'>
                      <a
                        href='#accountlinked'
                        className={
                          `nav-link text-active-primary me-6 ` + (userTab === 7 && 'active')
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setUserTab(7)
                        }}
                      >
                        {intl.formatMessage({id: 'USER.USERPAGE.ACCOUNTLINKED'})}
                      </a>
                    </li>
                    {RoleCheckMin(Roles.Admin, admin.account.role) && currentUser.account && (
                      <>
                        <li className='nav-item'>
                          <a
                            href='#links'
                            className={
                              `nav-link text-active-primary me-6 ` + (userTab === 8 && 'active')
                            }
                            onClick={(e) => {
                              e.preventDefault()
                              setUserTab(8)
                            }}
                          >
                            {intl.formatMessage({id: 'USER.USERPAGE.ACCOUNT'})}
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            href='#links'
                            className={
                              `nav-link text-active-primary me-6 ` + (userTab === 9 && 'active')
                            }
                            onClick={(e) => {
                              e.preventDefault()
                              setUserTab(9)
                            }}
                          >
                            Hive
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                {userTab === 0 && <UserOverview userData={currentUser} />}
                {userTab === 1 && <UserModeration userData={currentUser} />}
                {userTab === 2 && <UserFriends userData={currentUser} />}
                {userTab === 3 && <UserUdids userData={currentUser} />}
                {userTab === 4 && <UserPurchases userData={currentUser} />}
                {userTab === 5 && <UserBounties userData={currentUser} />}
                {userTab === 6 && <UserAchievements userData={currentUser} />}
                {userTab === 7 && <UserAccountLinked userData={currentUser} />}
                {userTab === 8 && <UserAccount userData={currentUser} />}
                {userTab === 9 && <UserHive userData={currentUser} />}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserPage
