export enum Roles {
  Viewer = 'VIEWER',
  ModeratorNew = 'MODERATOR_NEW',
  ModeratorConfirmed = 'MODERATOR_CONFIRMED',
  ModeratorVeteran = 'MODERATOR_VETERAN',
  Admin = 'ADMIN',
}

export const RolesValue = new Map<string, number>([
  [Roles.Viewer, 0],
  [Roles.ModeratorNew, 1],
  [Roles.ModeratorConfirmed, 2],
  [Roles.ModeratorVeteran, 3],
  [Roles.Admin, 4],
])
