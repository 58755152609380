/* eslint-disable jsx-a11y/anchor-is-valid */
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Typeahead} from 'react-bootstrap-typeahead'
import {specificFilterCase, unlockedByBountiesFilterCase} from '../../../helpers/FilterCase'
import * as users from '../../users/redux/UserRedux'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import 'react-datepicker/dist/react-datepicker.css'
import {RootState} from '../../../../setup'
import {BountiesFiltersModel} from '../../users/models/FiltersModel'

export const BountiesFilterBar = (props: any) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const currentBountiesFilters = useSelector<RootState>(
    ({users}) => users.bountiesFilters,
    shallowEqual
  ) as BountiesFiltersModel
  const {filters, setLoading, bountiesFilters, setBountiesFilters, parentsRef, bountieNameRef} =
    props

  const advancedFilters = false
  useEffect(() => {
    if (currentBountiesFilters !== null || undefined)
      setBountiesFilters({
        filterLabel: currentBountiesFilters.filterLabel,
        name: currentBountiesFilters.name,
        unlockedBy: currentBountiesFilters.unlockedBy,
        keyType: currentBountiesFilters.keyType,
        value: currentBountiesFilters.value,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let finalFilters = filters.filterKey + '.' + bountiesFilters.name + '.' + bountiesFilters.keyType

  bountiesFilters.unlockedBy
    ? (finalFilters =
        filters.filterKey +
        '.' +
        bountiesFilters.name +
        '.unlockedBy.' +
        bountiesFilters.unlockedBy +
        '.' +
        bountiesFilters.keyType)
    : (bountiesFilters.value = bountiesFilters.name)

  function handleClear() {
    setBountiesFilters({...bountiesFilters, unlockedBy: '', keyType: '', value: ''})
  }
  return (
    <div className='d-flex flex-wrap'>
      <div className='d-flex align-items-center justify-content-start mt-3'>
        <span className='ms-6'>{intl.formatMessage({id: 'FILTER.NAME'})}</span>
        <Typeahead
          id='filterName'
          ref={bountieNameRef}
          defaultInputValue={currentBountiesFilters.name}
          onChange={(e) => {
            setBountiesFilters({
              ...bountiesFilters,
              name: e[0],
              value: e[0],
              filterLabel: filters.filterKey,
              unlockedBy: '',
            })
          }}
          options={specificFilterCase[filters.filterKey]}
          inputProps={{
            className: 'ms-6 mw-100px form-control form-control-sm form-control-solid',
          }}
        />
      </div>
      {bountiesFilters.name && unlockedByBountiesFilterCase[bountiesFilters.name] && (
        <div className='d-flex align-items-center justify-content-start mt-3'>
          <span className='ms-6'>{intl.formatMessage({id: 'FILTER.UNLOCKEDBY'})}</span>
          <Typeahead
            id=''
            ref={parentsRef}
            defaultInputValue={bountiesFilters.unlockedBy}
            onChange={(e) => {
              handleClear()
              setBountiesFilters({
                ...bountiesFilters,
                unlockedBy: e[0],
                keyType: 'key',
                value: e[0],
              })
            }}
            options={unlockedByBountiesFilterCase[bountiesFilters.name]}
            inputProps={{
              className: 'ms-6 mw-100px form-control form-control-sm form-control-solid',
            }}
          />
        </div>
      )}
      {advancedFilters && bountiesFilters.unlockedBy && (
        <div className='d-flex align-items-center justify-content-start mt-3'>
          <span className='ms-6'>{intl.formatMessage({id: 'FILTER.KEYTYPE'})}</span>
          <Typeahead
            id=''
            ref={parentsRef}
            defaultInputValue={'key'}
            onChange={(e) => {
              setBountiesFilters({
                ...bountiesFilters,
                unlockedBy: e[0],
                value: bountiesFilters.unlockedBy,
              })
            }}
            options={['key', 'type']}
            inputProps={{
              className: 'ms-6 mw-100px form-control form-control-sm form-control-solid',
            }}
          />
        </div>
      )}
      {advancedFilters && bountiesFilters.keyType && (
        <div className='d-flex align-items-center justify-content-start mt-3'>
          <span className='ms-6'>{intl.formatMessage({id: 'FILTER.VALUE'})}</span>
          <Typeahead
            id=''
            ref={parentsRef}
            defaultInputValue={currentBountiesFilters.value}
            onChange={(e) => setBountiesFilters({...bountiesFilters, value: e[0]})}
            options={unlockedByBountiesFilterCase[bountiesFilters.name]}
            inputProps={{
              className: 'ms-6 mw-100px form-control form-control-sm form-control-solid',
            }}
          />
        </div>
      )}
      {(bountiesFilters.name || bountiesFilters.value) && (
        <div className='d-flex align-items-center mt-3'>
          <button
            className={'btn btn-sm btn-light-primary ms-8'}
            onClick={() => {
              dispatch(
                users.userActions.getUsers(
                  null,
                  finalFilters,
                  bountiesFilters.value,
                  filters.sortingOrder,
                  filters.filterType,
                  filters.filterOperator
                )
              )
              dispatch(users.userActions.setFilters(filters))
              dispatch(users.userActions.setBountiesFilters(bountiesFilters))
              setLoading(true)
            }}
          >
            {intl.formatMessage({id: 'FILTER.SEARCH'})}
          </button>
        </div>
      )}
    </div>
  )
}
