import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {UserWrapperModel} from '../../../auth/models/AccountUserWrapperModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as users from '../../../users/redux/UserRedux'
import {RootState} from '../../../../../setup'
import AvatarRenderer from '../AvatarRenderer'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {logos} from '../../../../helpers/provider/ProviderDataLogo'
import {Link} from 'react-router-dom'

type Props = {
  userData: UserWrapperModel
}
const UserAccountLinked: React.FC<Props> = ({userData}) => {
  const user = userData.user
  const devicesUserList = useSelector<RootState>(
    ({users}) => users.devicesUserList,
    shallowEqual
  ) as any[]
  const targetUidDevicesUserList = useSelector<RootState>(
    ({users}) => users.targetUidDevicesUserList,
    shallowEqual
  ) as string
  const devicesPendingResult = useSelector<RootState>(
    ({users}) => users.devicesPendingResult,
    shallowEqual
  ) as string

  const intl = useIntl()
  const dispatch = useDispatch()

  const shownLimit = 300

  useEffect(() => {
    if (user.devices)
      if (!devicesUserList.length || targetUidDevicesUserList !== user.uid) {
        Object.keys(user.devices).forEach((deviceId, index) => {
          dispatch(
            users.userActions.getUserDevicesList(
              'devices.' + deviceId + '.udid',
              deviceId,
              user.uid
            )
          )
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <div className='table-responsive'>
        <span className='fw-bolder'>
          {devicesUserList.length} results
          <p
            className={
              ' ms-3 badge badge-' +
              (devicesPendingResult[0] === devicesPendingResult[1] ? 'success' : 'danger')
            }
          >
            {devicesPendingResult[0]} / {devicesPendingResult[1]}
          </p>
          {devicesUserList.length > shownLimit && (
            <p>{intl.formatMessage({id: 'DEVICES.LIMIT'})}</p>
          )}
        </span>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-70px'>N°</th>
              <th className='min-w-100px'>Avatar</th>
              <th className='min-w-100px'> {intl.formatMessage({id: 'USER.NICKNAME'})}</th>
              <th className='min-w-100px'>Uid</th>
              <th className='min-w-100px'>Udid</th>
              <th className='min-w-100px'>Status</th>
              <th className='min-w-100px'>Platform</th>
            </tr>
          </thead>
          <tbody>
            {targetUidDevicesUserList === user.uid &&
              devicesUserList.slice(0, shownLimit).map((device, deviceIndex) => (
                <tr key={deviceIndex}>
                  <td className='fw-bolder'>{deviceIndex + 1}</td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <AvatarRenderer
                        avatarLayers={device.avatarLayers}
                        dimension={60}
                        uid={device.uid}
                      />
                    </div>
                  </td>
                  <td className='fw-bolder'>
                    <Link to={{pathname: device.uid, state: {userData: user}}}>
                      <span className='text-dark fw-bolder text-hover-primary fs-6'>
                        {device.nickname}
                      </span>
                    </Link>
                  </td>
                  <td className='fw-bolder'>
                    <Link to={{pathname: device.uid, state: {userData: user}}}>
                      <span className='text-dark fw-bolder text-hover-primary fs-6'>
                        {device.uid}
                      </span>
                    </Link>
                  </td>
                  <td className='fw-bolder'>{device.udid}</td>
                  <td>
                    <span
                      className={
                        'd-block badge badge-' +
                        (device.status === 'ACTIVE'
                          ? 'success'
                          : device.status === 'SUSPENDED'
                          ? 'warning'
                          : 'danger')
                      }
                    >
                      {device.status}
                    </span>
                  </td>
                  <td>
                    <img
                      alt='platform logo'
                      className='h-30px logo flex-shrink-0 me-5 mb-1'
                      src={toAbsoluteUrl(logos[device.platform])}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <>
        <div className='row g-6 mb-6 g-xl-9 mb-xl-9 '></div>
      </>
    </div>
  )
}

export default UserAccountLinked
