import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {call, put, takeLatest, select} from 'redux-saga/effects'
import axios from 'axios'
import {ApiRequestManager} from '../../../logic/ApiRequestManager'
import {AccountUserWrapperModel} from '../../../modules/auth/models/AccountUserWrapperModel'
import {FiltersModel} from '../../../modules/users/models/FiltersModel'
export interface AccountActionWithPayload<T> extends Action {
  payload?: T
}

export interface accountListGetParams {
  cursorLastDocumentKey?: string
  limit: number
  sortingKey?: string
  sortingValue?: string
  filterKey?: string
  filterValue?: string
  type?: string
  operator?: string
}

export const accountActionTypes = {
  GetAccounts: '[GetAccount] Action',
  CheckEmailAccount: '[Get1Account] Action',
  EmailIsUsed: '[EmailIsValid] Action',
  SetAccounts: '[SetAccount] Action',
  DeactivateAccounts: '[DeactivateAccount] Action',
  ActivateAccounts: '[ActivateAccount] Action',
  LoadAccount: '[LoadAccount] Action',
  UpdateAccount: '[UpdateAccount] Action',
  InviteAccount: '[InviteAccount] Action',
  NewAccount: '[NewAccount] Action',
  SetAccountFilters: '[SetAccountFilters] Action',
  GetModeratorsList: '[GetModeratorList] Action',
  SetModeratorList: '[SetModeratorList] Action',
  ChangeAccountRole: '[ChangeAccountRole] Action',
}

const initialAccountState: IAccountState = {
  accountsList: [],
  moderatorsList: [],
  result: false,
  emailIsUsed: {
    status: true,
    email: '',
  },
  emailVerification: false,
  tmpCredentials: '',
  accountFilters: null,
}

export interface IAccountState {
  accountsList: AccountUserWrapperModel[]
  moderatorsList: AccountUserWrapperModel[]
  result: boolean
  emailIsUsed: any
  emailVerification: boolean
  tmpCredentials: string
  accountFilters: FiltersModel | null
}

export const accountReducer = persistReducer(
  {storage, key: 'v100-intra-account-list', whitelist: ['account']},
  (state: IAccountState = initialAccountState, action: AccountActionWithPayload<any>) => {
    switch (action.type) {
      case accountActionTypes.SetAccounts: {
        const {accounts} = action.payload
        const {concat} = action.payload
        let finalAccountArray: AccountUserWrapperModel[] = concat
          ? state.accountsList.concat(accounts)
          : accounts
        return {...state, accountsList: finalAccountArray}
      }
      case accountActionTypes.LoadAccount: {
        let accountInfo = action.payload.account
        let finalAccountArray = [...state.accountsList]
        finalAccountArray.forEach((account, i) => {
          if (account.account.uid === accountInfo.account.uid)
            finalAccountArray[i] = {...account, isLoading: true}
        })
        return {...state, accountsList: finalAccountArray}
      }
      case accountActionTypes.UpdateAccount: {
        let accountInfo = action.payload.account
        let finalAccountArray = [...state.accountsList]
        finalAccountArray.forEach((account, i) => {
          if (account.account.uid === accountInfo.uid) {
            finalAccountArray[i] = {...account, isLoading: false}
            finalAccountArray[i].account = accountInfo
          }
        })
        return {...state, accountsList: finalAccountArray}
      }
      case accountActionTypes.NewAccount: {
        let result = action.payload.resultLength
        let tmpCredentials = action.payload.account.credentials
        return {
          ...state,
          result: result,
          emailIsUsed: {status: true, email: ''},
          tmpCredentials: tmpCredentials,
        }
      }
      case accountActionTypes.EmailIsUsed: {
        let result = action.payload.resultLength
        let email = action.payload.email
        let newEmail = !state.emailVerification
        return {
          ...state,
          result: false,
          emailIsUsed: {status: result, email: email},
          emailVerification: newEmail,
        }
      }
      case accountActionTypes.SetAccountFilters: {
        const {filters} = action.payload
        return {...state, accountFilters: filters}
      }
      case accountActionTypes.SetModeratorList: {
        const moderatorData = action.payload.moderators
        let moderatorObject = moderatorData.reduce(
          (obj: any, item: any) =>
            Object.assign(
              obj,
              item.user && {[item.user.uid]: item.user.nickname},
              item.user && item.account.slackId && {[item.account.slackId]: item.user.nickname}
            ),
          {}
        )
        return {...state, moderatorsList: moderatorObject}
      }
      default:
        return state
    }
  }
)

export const accountActions = {
  inviteAccount: (
    uid: string,
    email: string,
    role: string,
    slackId?: string,
    privateFrequency?: string
  ) => ({
    type: accountActionTypes.InviteAccount,
    payload: {uid, email, role, slackId, privateFrequency},
  }),
  newAccount: (account: AccountUserWrapperModel, resultLength: boolean) => ({
    type: accountActionTypes.NewAccount,
    payload: {account, resultLength},
  }),
  getAccounts: (
    lastUid?: string | null,
    concat?: boolean,
    filterKey?: string,
    filterValue?: string,
    sortingValue?: string,
    type?: string,
    operator?: string,
    sortingKey?: string
  ) => ({
    type: accountActionTypes.GetAccounts,
    payload: {
      lastUid,
      concat,
      filterKey,
      filterValue,
      sortingValue,
      type,
      operator,
      sortingKey,
    },
  }),
  checkEmailAccount: (email: string) => ({
    type: accountActionTypes.CheckEmailAccount,
    payload: {email},
  }),
  emailIsUsed: (resultLength: boolean, email: string) => ({
    type: accountActionTypes.EmailIsUsed,
    payload: {resultLength, email},
  }),
  setAccounts: (
    accounts: AccountUserWrapperModel[],
    concat?: boolean,
    sortingKey?: string,
    sortingValue?: string
  ) => ({
    type: accountActionTypes.SetAccounts,
    payload: {accounts, concat, sortingKey, sortingValue},
  }),
  deactivateAccounts: (targetUid: string, account: AccountUserWrapperModel) => ({
    type: accountActionTypes.DeactivateAccounts,
    payload: {targetUid, account},
  }),
  activateAccounts: (targetUid: string, account: AccountUserWrapperModel) => ({
    type: accountActionTypes.ActivateAccounts,
    payload: {targetUid, account},
  }),
  loadAccounts: (account: AccountUserWrapperModel) => ({
    type: accountActionTypes.LoadAccount,
    payload: {account},
  }),
  updateAccounts: (account: AccountUserWrapperModel) => ({
    type: accountActionTypes.UpdateAccount,
    payload: {account},
  }),
  setAccountFilters: (filters: FiltersModel) => ({
    type: accountActionTypes.SetAccountFilters,
    payload: {filters},
  }),
  getModeratorsList: () => ({
    type: accountActionTypes.GetModeratorsList,
  }),
  setModeratorsList: (moderators: any) => ({
    type: accountActionTypes.SetModeratorList,
    payload: {moderators},
  }),
  changeAccountRole: (role: string, account: AccountUserWrapperModel) => ({
    type: accountActionTypes.ChangeAccountRole,
    payload: {role, account},
  }),
}

export function* accountSaga() {
  yield takeLatest(accountActionTypes.GetAccounts, function* getAccountsSaga(action: any): any {
    const getAccountsList = (state: any) => state.account.accountsList
    const accountsList = yield select(getAccountsList)
    let param: accountListGetParams = {
      cursorLastDocumentKey:
        accountsList != null && accountsList.length > 0 && action.payload.lastUid
          ? accountsList[accountsList.length - 1].account.uid
          : '',
      limit: 10,
    }
    try {
      param = {
        ...param,
        sortingKey: action.payload.sortingKey,
        sortingValue: action.payload.sortingValue,
        filterKey: action.payload.filterKey,
        filterValue: action.payload.filterValue,
        type: action.payload.type,
        operator: action.payload.operator,
      }
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAccountsList,
        {
          params: param,
        }
      )
      let accountsArray: AccountUserWrapperModel[] = response.data.results
      yield put(accountActions.setAccounts(accountsArray, action.payload.concat))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(
    accountActionTypes.CheckEmailAccount,
    function* checkEmailAccountSaga(action: any): any {
      try {
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAccountsList,
          {
            params: {
              filterKey: 'email',
              filterValue: action.payload.email,
            },
          }
        )
        let resultLength = true
        if (!response.data.results.length) resultLength = false

        yield put(accountActions.emailIsUsed(resultLength, action.payload.email))
      } catch (err) {
        console.error(err)
      }
    }
  )

  yield takeLatest(accountActionTypes.InviteAccount, function* inviteAccountSaga(action: any): any {
    try {
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraInviteAccount,
        {
          uid: action.payload.uid,
          email: action.payload.email,
          role: action.payload.role,
          slackId: action.payload.slackId,
          privateFrequency: action.payload.privateFrequency,
        }
      )
      let resultLength = true
      if (!response.data) resultLength = false

      let accountInfo = response.data
      yield put(accountActions.newAccount(accountInfo, resultLength))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(
    accountActionTypes.DeactivateAccounts,
    function* deactivateAccountSaga(action: any): any {
      try {
        yield put(accountActions.loadAccounts(action.payload.account))
        const response = yield call(
          axios.post,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraDeactivateAdminAccount,
          {
            targetUid: action.payload.targetUid,
          }
        )
        let accountInfo = response.data
        yield put(accountActions.updateAccounts(accountInfo))
      } catch (err) {
        console.error(err)
      }
    }
  )

  yield takeLatest(
    accountActionTypes.ActivateAccounts,
    function* activateAccountSaga(action: any): any {
      try {
        yield put(accountActions.loadAccounts(action.payload.account))
        const response = yield call(
          axios.post,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraActivateAdminAccount,
          {
            targetUid: action.payload.targetUid,
          }
        )
        let accountInfo = response.data
        yield put(accountActions.updateAccounts(accountInfo))
      } catch (err) {
        console.error(err)
      }
    }
  )

  yield takeLatest(
    accountActionTypes.GetModeratorsList,
    function* getModeratorsSaga(action: any): any {
      let param: accountListGetParams = {
        limit: 100,
      }
      try {
        param = {
          ...param,
        }
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAccountsList,
          {
            params: param,
          }
        )
        let moderatorsArray: AccountUserWrapperModel[] = response.data.results
        yield put(accountActions.setModeratorsList(moderatorsArray))
      } catch (err) {
        console.error(err)
      }
    }
  )
  yield takeLatest(
    accountActionTypes.ChangeAccountRole,
    function* changeAccountRoleSaga(action: any): any {
      try {
        yield put(accountActions.loadAccounts(action.payload.account))
        const response = yield call(
          axios.post,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraChangeAccountRole,
          {
            targetUid: action.payload.account.account.uid,
            role: action.payload.role,
          }
        )
        let accountInfo = response.data
        yield put(accountActions.updateAccounts(accountInfo))
      } catch (err) {
        console.error(err)
      }
    }
  )
}
