/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as users from '../../../../app/modules/users/redux/UserRedux'
import {KTSVG} from '../../../helpers'
import swal from 'sweetalert'
import {useIntl} from 'react-intl'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../../../app/modules/auth/models/AccountUserWrapperModel'
import {RootState} from '../../../../setup'
import {RoleCheckMin} from '../../../../app/helpers/RoleRestriction'
import {Roles} from '../../../../app/modules/auth/models/RolesEnum'
import {DiffTimestamp} from '../../../../app/helpers/DiffDates'
import {moderationValues} from '../../../../app/helpers/provider/SanctionLevelProvider'
type Props = {
  userWrappedData: UserWrapperModel
  isLoading?: boolean
}

const ModerationDropdown: React.FC<Props> = ({userWrappedData, isLoading}) => {
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel

  const dispatch = useDispatch()
  const [countByValueBody, setCountByValueBody] = useState('')
  const intl = useIntl()
  const userData = userWrappedData.user
  let uidArray: string[] = []
  function checkSanctionBeforeSuspending(
    SanctionDuration: any,
    hasDevices: boolean,
    sanction: string
  ) {
    if (hasDevices)
      dispatch(
        users.userActions.getBlacklistedDevices(countByValueBody, userWrappedData, (data: any) => {
          let tmpDate = 0
          let recentSanction: any = null
          data.forEach((item: any) => {
            if (tmpDate < item.sanction.date) {
              tmpDate = item.sanction.date
              recentSanction = item.sanction
            }
          })

          if (recentSanction && recentSanction.expiration > Date.now() + SanctionDuration) {
            swal({
              title: intl.formatMessage({id: 'ALERT.VERIFICATION'}),
              text: `${intl.formatMessage({id: 'ALERT.SUSPEND'})}\n ${DiffTimestamp(
                Date.now(),
                recentSanction?.expiration,
                intl
              )}`,
              buttons: ['Cancel', 'Suspend'],
              icon: 'warning',
            }).then((willBan) => {
              if (willBan) {
                swal(intl.formatMessage({id: 'ALERT.SUSPENDING'}), {
                  icon: 'success',
                })
                dispatch(
                  users.userActions.suspend(
                    userWrappedData,
                    SanctionDuration,
                    admin,
                    kickAfterSuspending()
                  )
                )
              } else {
                swal({
                  icon: 'error',
                })
              }
            })
          } else {
            if (moderationValues[SanctionDuration].reason) {
              let swalField = document.createElement('textarea')
              swalField.setAttribute('placeholder', 'Reason for suspend')
              swalField.classList.add('form-control', 'form-control-solid')
              swal({
                title: '(OPTIONAL) Please write a reason for the suspend',
                content: {
                  element: swalField,
                },
                buttons: {
                  cancel: {
                    text: 'Cancel',
                    visible: true,
                    value: 'cancel',
                  },

                  confirm: {
                    text: 'Confirm',
                    visible: true,
                    value: 'confirm',
                  },
                },
                icon: 'warning',
              }).then((value) => {
                let reason = swalField.value
                switch (value) {
                  case 'cancel':
                    swal('Canceled: user will not be suspended', {
                      icon: 'error',
                    })
                    break
                  case 'confirm':
                    swal('Confirmed: suspending user', {
                      icon: 'success',
                    })
                    dispatch(
                      users.userActions.suspend(
                        userWrappedData,
                        SanctionDuration,
                        admin,
                        kickAfterSuspending(),
                        reason
                      )
                    )
                    break
                }
              })
            }
          }
        })
      )
    else {
      if (
        moderationValues.hasOwnProperty(sanction) &&
        moderationValues[sanction].hasOwnProperty('reason')
      ) {
        let swalField = document.createElement('textarea')
        swalField.setAttribute('placeholder', 'Reason for suspend')
        swalField.classList.add('form-control', 'form-control-solid')
        swal({
          title: '(OPTIONAL) Please write a reason for the suspend',
          content: {
            element: swalField,
          },
          buttons: {
            cancel: {
              text: 'Cancel',
              visible: true,
              value: 'cancel',
            },

            confirm: {
              text: 'Confirm',
              visible: true,
              value: 'confirm',
            },
          },
          icon: 'warning',
        }).then((value) => {
          let reason = swalField.value
          switch (value) {
            case 'cancel':
              swal('Canceled: user will not be suspended', {
                icon: 'error',
              })
              break
            case 'confirm':
              swal('Confirmed: suspending user', {
                icon: 'success',
              })
              dispatch(
                users.userActions.suspend(
                  userWrappedData,
                  SanctionDuration,
                  admin,
                  kickAfterSuspending(),
                  reason
                )
              )
              break
          }
        })
      } else {
        dispatch(
          users.userActions.suspend(userWrappedData, SanctionDuration, admin, kickAfterSuspending())
        )
      }
    }
  }

  function kickAfterSuspending() {
    dispatch(
      users.userActions.agoraGetParticipantFrequency(userWrappedData.user.uid, (data: any) => {
        dispatch(users.userActions.kick(userWrappedData, data.confId, 'AGORA'))
      })
    )
  }

  return userData.status !== 'BANNED' ? (
    <div className='w-60 w-md-60'>
      <button
        id={'moderation-button-' + userData.uid}
        type='button'
        className={
          'btn btn-sm w-200px btn-light' + (userData.status === 'ACTIVE' ? '' : '-warning')
        }
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-start'
        disabled={isLoading}
        onClick={() => {
          if (userData.devices) {
            uidArray = []
            Object.keys(userData.devices).map((data) => uidArray.push(data))
            setCountByValueBody(uidArray.join())
          }
        }}
      >
        {intl.formatMessage({id: 'DROPDOWN.ACTIONS'})}
        <span className='svg-icon svg-icon-5 rotate-180 ms-3 me-0'>
          <KTSVG
            path='/media/icons/duotune/arrows/arr072.svg'
            className='svg-icon-2x svg-icon-danger'
          />
        </span>
      </button>
      <div
        id={'moderation-dropdown-' + userData.uid}
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
        data-kt-menu='true'
      >
        {userData.status === 'SUSPENDED' && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3 text-success'
              onClick={() => dispatch(users.userActions.unsuspend(userWrappedData, admin))}
            >
              {intl.formatMessage({id: 'DROPDOWN.UNSUSPEND'})}
            </a>
          </div>
        )}
        {RoleCheckMin(Roles.ModeratorVeteran, admin.account.role) && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3 text-danger'
              onClick={() => {
                if (userData.devices && Object.keys(userData.devices).length !== 0) {
                  userWrappedData.isLoading = true
                  dispatch(
                    users.userActions.countBy(
                      userData.uid,
                      'udids',
                      countByValueBody,
                      'string',
                      (totalUnique: number) => {
                        let swalField = document.createElement('textarea')
                        swalField.setAttribute('placeholder', 'Reason for ban')
                        swalField.classList.add('form-control', 'form-control-solid')
                        swal({
                          title: 'Are you sure?',
                          text:
                            'The user will be permanently banned' +
                            (totalUnique > 0 && ' ( + ' + totalUnique + ' others )'),
                          content: {
                            element: swalField,
                          },
                          buttons: {
                            cancel: {
                              text: 'Cancel',
                              visible: true,
                              value: 'cancel',
                            },
                            confirm: {
                              text: 'Confirm',
                              visible: true,
                              value: 'confirm',
                            },
                          },
                          icon: 'warning',
                        }).then((value) => {
                          let reason = swalField.value
                          switch (value) {
                            case 'cancel':
                              swal('Canceled: user will not be banned', {
                                icon: 'error',
                              })
                              break
                            case 'confirm':
                              if (reason) {
                                swal('Confirmed: banning user', {
                                  icon: 'success',
                                })
                                dispatch(users.userActions.ban(userWrappedData, admin, reason))
                              } else {
                                swal('Please provide a reason before banning.', {
                                  icon: 'error',
                                })
                              }
                              break
                          }
                        })
                      },
                      userWrappedData,
                      false
                    )
                  )
                } else {
                  let swalField = document.createElement('textarea')
                  swalField.setAttribute('placeholder', 'Reason for ban')
                  swalField.classList.add('form-control', 'form-control-solid')
                  swal({
                    title: 'Are you sure?',
                    text: 'The user will be permanently banned',
                    content: {
                      element: swalField,
                    },
                    buttons: {
                      cancel: {
                        text: 'Cancel',
                        visible: true,
                        value: 'cancel',
                      },
                      confirm: {
                        text: 'Confirm',
                        visible: true,
                        value: 'confirm',
                      },
                    },
                    icon: 'warning',
                  }).then((value) => {
                    let reason = swalField.value
                    switch (value) {
                      case 'cancel':
                        swal('Canceled: user will not be banned', {
                          icon: 'error',
                        })
                        break
                      case 'confirm':
                        if (reason) {
                          swal('Confirmed: banning user', {
                            icon: 'success',
                          })
                          dispatch(users.userActions.ban(userWrappedData, admin, reason))
                        } else {
                          swal('Please provide a reason before banning.', {
                            icon: 'error',
                          })
                        }
                        break
                    }
                  })
                }
              }}
            >
              {intl.formatMessage({id: 'DROPDOWN.BAN'})}
            </a>
          </div>
        )}
        {Object.keys(moderationValues).map(
          (sanction, index) =>
            RoleCheckMin(moderationValues[sanction].levelRequired, admin.account.role) && (
              <div key={index} className='menu-item px-3'>
                <a
                  className='menu-link px-3 '
                  onClick={() =>
                    typeof moderationValues[sanction].sanctionValue === 'number'
                      ? checkSanctionBeforeSuspending(
                          moderationValues[sanction].sanctionValue,
                          userData.devices && userData.devices.length && true,
                          sanction
                        )
                      : dispatch(
                          users.userActions.invalidateUserAttribute(
                            userWrappedData,
                            moderationValues[sanction].sanctionValue
                          )
                        )
                  }
                >
                  {intl.formatMessage({id: moderationValues[sanction].trad})}
                </a>
              </div>
            )
        )}
      </div>
    </div>
  ) : (
    <button
      id={'moderation-button-' + userData.uid}
      type='button'
      className='btn btn-light-success btn-sm w-150px'
      onClick={() => {
        swal({
          title: 'Are you sure?',
          text: 'The user will be unbanned',
          buttons: ['Cancel', 'Unban'],
          icon: 'warning',
        }).then((willUnban) => {
          if (willUnban) {
            swal('Confirmed: unbanning user', {
              icon: 'success',
            })
            dispatch(users.userActions.unban(userWrappedData, admin))
          } else {
            swal('Canceled: user still banned', {
              icon: 'error',
            })
          }
        })
      }}
    >
      UnBan
    </button>
  )
}

export default ModerationDropdown
