import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {call, put, takeLatest, select} from 'redux-saga/effects'
import axios from 'axios'
import {ApiRequestManager} from '../../../logic/ApiRequestManager'
import {DeviceBlacklistModel} from '../models/DeviceBlacklistModel'
import {FiltersModel} from '../../../modules/users/models/FiltersModel'
export interface DeviceActionWithPayload<T> extends Action {
  payload?: T
}

export interface deviceListGetParams {
  cursorLastDocumentKey: string
  limit: number
  filterKey?: string
  filterValue?: string
  sortingValue?: string
  type?: string
  operator?: string
}

export const deviceActionTypes = {
  GetDevices: '[GetDevices] Action',
  SetDevices: '[SetDevices] Action',
  SetCurrentFilters: '[SetFilters] Action',
}

const initialDeviceState: IDeviceState = {
  devicesList: [],
  currentFilters: null,
  result: true,
  searchOnly: false,
}

export interface IDeviceState {
  devicesList: DeviceBlacklistModel[]
  currentFilters: FiltersModel | null
  result: boolean
  searchOnly: boolean
}

export const deviceReducer = persistReducer(
  {storage, key: 'v100-intra-device', whitelist: ['device']},
  (state: IDeviceState = initialDeviceState, action: DeviceActionWithPayload<any>) => {
    switch (action.type) {
      case deviceActionTypes.SetDevices: {
        const {devices} = action.payload
        const {concat} = action.payload
        const {resultLength} = action.payload

        let finalDeviceArray: DeviceBlacklistModel[] = concat
          ? state.devicesList.concat(devices)
          : devices
        return {...state, devicesList: finalDeviceArray, result: resultLength}
      }
      case deviceActionTypes.SetCurrentFilters: {
        const {filters} = action.payload
        return {...state, currentFilters: filters}
      }
      default:
        return state
    }
  }
)

export const deviceActions = {
  getDevices: (
    lastDeviceId?: string | null,
    concat?: boolean,
    filterKey?: string,
    filterValue?: string,
    sortingValue?: string,
    type?: string,
    operator?: string
  ) => ({
    type: deviceActionTypes.GetDevices,
    payload: {lastDeviceId, concat, filterKey, filterValue, sortingValue, type, operator},
  }),
  setDevices: (devices: DeviceBlacklistModel[], concat?: boolean, resultLength?: boolean) => ({
    type: deviceActionTypes.SetDevices,
    payload: {devices, concat, resultLength},
  }),
  setFilters: (filters: FiltersModel) => ({
    type: deviceActionTypes.SetCurrentFilters,
    payload: {filters},
  }),
}

export function* deviceSaga() {
  yield takeLatest(deviceActionTypes.GetDevices, function* getDevicesSaga(action: any): any {
    const getDevicesList = (state: any) => state.device.devicesList
    const devicesList = yield select(getDevicesList)
    let param: deviceListGetParams = {
      cursorLastDocumentKey:
        devicesList != null && devicesList.length > 0 && action.payload.lastDeviceId
          ? devicesList[devicesList.length - 1].udid
          : '',
      limit: 10,
    }
    try {
      if (action.payload.filterKey !== '' && action.payload.filterValue !== '') {
        param = {
          ...param,
          filterKey: action.payload.filterKey,
          filterValue: action.payload.filterValue,
          sortingValue: action.payload.sortingValue,
          type: action.payload.type,
          operator: action.payload.operator,
        }
      }
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraDeviceList,
        {
          params: param,
        }
      )
      let resultLength = true
      if (!response.data.results.length) resultLength = false

      let devicesArray: DeviceBlacklistModel[] = response.data.results
      yield put(deviceActions.setDevices(devicesArray, action.payload.concat, resultLength))
    } catch (err) {
      console.error(err)
    }
  })
}
