export interface LanguageCodeInterface {
  [key: string]: string
}

export let ProviderLanguageCode: LanguageCodeInterface = {
  en: 'en',
  es: 'es',
  zh: 'zh',
  fr: 'fr',
  de: 'de',
  ja: 'ja',
  ru: 'ru',
  pt: 'pt',
  ar: 'ar',
  hi: 'hi',
  ko: 'ko',
  it: 'it',
  nl: 'nl',
  tr: 'tr',
  pl: 'pl',
  sv: 'sv',
  cs: 'cs',
  da: 'da',
  el: 'el',
  fi: 'fi',
}
