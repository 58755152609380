import {Line} from 'react-chartjs-2'
import React from 'react'
import {timestampConverter} from '../../../../helpers/converter/Common'
import moment from 'moment'
import {ReportModel, SanctionModel} from '../../../users/models/ModerationModel'
import {useIntl} from 'react-intl'
import {arrayReduce, dataAssign} from '../../../../helpers/ChartHelper'

type Props = {
  ModerationReportData: ReportModel[]
  ModerationSanctionData: SanctionModel[]
  startDate: Date
  endDate: Date
}

const ModerationChart: React.FC<Props> = ({
  ModerationReportData,
  ModerationSanctionData,
  startDate,
  endDate,
}) => {
  const intl = useIntl()
  const resultDates: string[] = []
  const reportList: string[] = []
  const sanctionList: string[] = []
  const start: Date = new Date(startDate)
  const end: Date = new Date(endDate)
  const diffInDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  const current = moment(start)
  const labelArray = [
    intl.formatMessage({id: 'CHART.DAILY'}),
    intl.formatMessage({id: 'CHART.TOTAL'}),
    intl.formatMessage({id: 'CHART.SUSPEND'}),
  ]
  ModerationReportData.map((report: ReportModel) =>
    reportList.push(timestampConverter(report.date))
  )

  ModerationSanctionData.map((sanction: SanctionModel) => {
    let sanctionEnd = end.getTime()
    if (sanction.expiration) sanctionEnd = sanction.expiration
    for (let i = sanction.date; i < sanctionEnd; i = i + 1000 * 60 * 60 * 24) {
      sanctionList.push(timestampConverter(i))
    }
    return sanctionList
  })

  let n = 0
  while (n < diffInDays + 1) {
    resultDates.push(current.format('YYYY-MM-DD'))
    current.add(1, 'day')
    n++
  }

  const reportDate = arrayReduce(reportList, (v: number) => (v || 0) + 1)
  const finalReportData = arrayReduce(resultDates, (v: number) => v || 0)
  dataAssign(reportDate, finalReportData)

  const sanctionDate = arrayReduce(sanctionList, (v: number) => v || 1)
  const finalSanctionData = arrayReduce(resultDates, (v: number) => v || 0)
  dataAssign(sanctionDate, finalSanctionData)

  let sum = 0
  const reportTotal = Object.values(finalReportData).map((v: any) => (sum += v))
  const previousReport = ModerationReportData.length - reportTotal[reportTotal.length - 1]
  const reportFinal = reportTotal.map(function (value) {
    return value + previousReport
  })

  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        beginAtZero: true,
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        beginAtZero: true,
      },
    },
  }

  const data = {
    labels: resultDates,
    datasets: [
      {
        label: labelArray[0],
        data: finalReportData,
        fill: false,
        borderColor: '#009EF7',
        yAxisID: 'y',
      },
      {
        label: labelArray[1],
        data: reportFinal,
        fill: false,
        borderColor: '#50CD89',
        yAxisID: 'y',
      },
      {
        label: labelArray[2],
        data: finalSanctionData,
        fill: true,
        borderColor: '#F1416C ',
        backgroundColor: '#F1416C ',
        yAxisID: 'y1',
        stepped: true,
      },
    ],
  }

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  )
}

export {ModerationChart}
