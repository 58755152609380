import React, {useState} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {useIntl} from 'react-intl'
import {timestampConverter} from '../../../../helpers/converter/Common'
import UserBountiesModal from './UserBountiesModal'
import * as users from '../../../users/redux/UserRedux'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {RoleCheckMin} from '../../../../helpers/RoleRestriction'
import {Roles} from '../../../auth/models/RolesEnum'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../../auth/models/AccountUserWrapperModel'

type Props = {
  userData: UserWrapperModel
}
const UserBounties: React.FC<Props> = ({userData}) => {
  const intl = useIntl()
  const currentUser = useSelector<RootState>(
    ({users}) => users.currentUser,
    shallowEqual
  ) as UserWrapperModel
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel
  const user = currentUser.user
  const dispatch = useDispatch()
  const [modalShow, setModalShow] = useState(false)
  let bountyData = bountiesObjectsToArray()

  function bountiesObjectsToArray() {
    let bountyArray = []

    for (const bountyKey in user.bounties) {
      const bounty = user.bounties[bountyKey]
      bountyArray.push(bounty)
    }
    return bountyArray
  }

  function handleRemove(key: string, unlockedByKey: string, unlockByType: string) {
    dispatch(
      users.userActions.bountiesRemove(currentUser, user.uid, key, unlockedByKey, unlockByType)
    )
  }

  return (
    <>
      {user.providerData && !user.providerData.length ? (
        <div className='fs-1 mb-20 text-center fw-bolder'>
          {intl.formatMessage({id: 'USER.BOUNTIES.ANONYMOUS'})}
        </div>
      ) : (
        <>
          {RoleCheckMin(Roles.Admin, admin.account.role) && (
            <div>
              <button className='btn btn-sm btn-light-success' onClick={() => setModalShow(true)}>
                {intl.formatMessage({id: 'USER.BOUNTIES.ACTIVATION'})}
              </button>
              {modalShow && <UserBountiesModal show={modalShow} setShow={setModalShow} />}
            </div>
          )}
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th>{intl.formatMessage({id: 'USER.BOUNTIES.BOUNTY'})}</th>
                  <th>{intl.formatMessage({id: 'USER.BOUNTIES.TYPE'})}</th>
                  <th>{intl.formatMessage({id: 'USER.BOUNTIES.UNLOCKEDBY'})}</th>
                  <th>{intl.formatMessage({id: 'USER.BOUNTIES.UNLOCKEDBY.TYPE'})}</th>
                  <th>{intl.formatMessage({id: 'USER.BOUNTIES.DURATION'})}</th>
                  {RoleCheckMin(Roles.Admin, admin.account.role) && (
                    <th>{intl.formatMessage({id: 'USER.BOUNTIES.ACTION'})}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {bountyData.map((bounty, bountyIndex) => (
                  <tr key={bountyIndex}>
                    <td>
                      <span className='text-dark fw-bolder d-block fs-6'>{bounty.key}</span>
                    </td>
                    <td>
                      <span className='fw-bolder d-block fs-6 badge badge-success'>
                        {bounty.type}
                      </span>
                    </td>
                    <td>
                      {Object.entries(bounty.unlockedBy).map(([key, value]) => (
                        <div
                          key={key}
                          style={{minHeight: '35px'}}
                          className='mt-1 mb-1 d-flex align-items-center'
                        >
                          <span
                            className='text-dark fw-bolder fs-6'
                            style={{overflowX: 'scroll', whiteSpace: 'nowrap', maxWidth: '200px'}}
                          >
                            {key}
                          </span>
                        </div>
                      ))}
                    </td>
                    <td>
                      {Object.entries(bounty.unlockedBy).map(([key, value]) => (
                        <div key={value.type} className='mt-1 mb-1 d-flex align-items-center'>
                          <span className='badge badge-info fw-bolder' style={{width: '100%'}}>
                            {value.type}
                          </span>
                        </div>
                      ))}
                    </td>

                    <td>
                      {Object.entries(bounty.unlockedBy).map(([key, value]) => (
                        <div
                          key={key}
                          style={{minHeight: '35px'}}
                          className='mt-1 mb-1 d-flex align-items-center'
                        >
                          <span
                            className='text-dark fw-bolder fs-6'
                            style={{overflowX: 'scroll', whiteSpace: 'nowrap', maxWidth: '200px'}}
                          >
                            {timestampConverter(value.ts)}{' '}
                            {value.expiration && ' / ' + timestampConverter(value.expiration)}
                          </span>
                        </div>
                      ))}
                    </td>
                    {RoleCheckMin(Roles.Admin, admin.account.role) && (
                      <td>
                        <div className='d-block '>
                          {Object.entries(bounty.unlockedBy).map(([key, value]) => (
                            <div key={key}>
                              <button
                                className=' btn btn-sm btn-light-danger mt-1 mb-1'
                                onClick={() => handleRemove(bounty.key, value.key, value.type)}
                              >
                                {intl.formatMessage({id: 'USER.BOUNTIES.REMOVE'})}
                              </button>
                            </div>
                          ))}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}
export default UserBounties
