/* eslint-disable jsx-a11y/anchor-is-valid */

import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import * as profanities from '../dashboard/redux/ProfanitiesRedux'
import {useEffect, useState} from 'react'
import TableRow from './TableRow'
import AddProfanityModal from './AddProfanityModal'

const Profanities: React.FC = () => {
  const languagesList = useSelector<RootState>(
    ({profanities}) => profanities.languagesList,
    shallowEqual
  ) as any
  const reduxLoading = useSelector<RootState>(
    ({profanities}) => profanities.loading,
    shallowEqual
  ) as any

  const intl = useIntl()
  const dispatch = useDispatch()
  const [option, setOption] = useState<any>('en')
  const [loading, setLoading] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const profanitiesList = useSelector<RootState>(
    ({profanities}) => profanities.profanitiesList,
    shallowEqual
  ) as any

  useEffect(() => {
    dispatch(profanities.profanitiyActions.getLanguages())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!profanitiesList[option]) dispatch(profanities.profanitiyActions.getProfanities(option))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option])

  useEffect(() => {
    if (!profanitiesList[option]) dispatch(profanities.profanitiyActions.getProfanities(option))
    if (profanitiesList[option] && profanitiesList[option].length) setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profanitiesList])

  const handleChange = (e: any) => {
    setOption(e.target.value)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {intl.formatMessage({id: 'MENU.PROFANITIES'})}
          </span>
        </h3>
      </div>

      {loading || reduxLoading ? (
        <div className=' text-center mt-10 mb-10'>
          <span className='indicator-progress' style={{display: 'block'}}>
            {intl.formatMessage({id: 'LOADER'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </div>
      ) : (
        languagesList.length && (
          <div className='card mb-5 mb-xl-8'>
            <div className='d-flex my-2 ms-5 mt-5 me-5' style={{justifyContent: 'space-between'}}>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm w-125px'
                defaultValue='en'
                onChange={(e) => handleChange(e)}
              >
                {languagesList.map((language: string, languageIndex: number) => (
                  <option key={languageIndex} value={language}>
                    {language}
                  </option>
                ))}
              </select>
              <button className='btn btn-success' onClick={() => setModalShow(true)}>
                Add profanity
              </button>
              {modalShow && <AddProfanityModal show={modalShow} setShow={setModalShow} />}
            </div>
            <div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <div className='d-flex text-center'>
                    {profanitiesList[option] && (
                      <TableRow dataList={profanitiesList[option]} languageCode={option} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  )
}

export {Profanities}
