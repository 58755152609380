/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as users from '../../../../app/modules/users/redux/UserRedux'
import {KTSVG} from '../../../helpers'
import swal from 'sweetalert'
import {useIntl} from 'react-intl'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../../../app/modules/auth/models/AccountUserWrapperModel'
import {RootState} from '../../../../setup'
import {RoleCheckMin} from '../../../../app/helpers/RoleRestriction'
import {Roles} from '../../../../app/modules/auth/models/RolesEnum'
import {useHistory} from 'react-router-dom'
type Props = {
  userWrappedData: UserWrapperModel
  isLoading?: boolean
}

const AdminDropdown: React.FC<Props> = ({userWrappedData, isLoading}) => {
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel

  const dispatch = useDispatch()
  const [countByValueBody, setCountByValueBody] = useState('')
  const intl = useIntl()
  const userData = userWrappedData.user
  let uidArray: string[] = []

  const user = userWrappedData?.user
  const history = useHistory()

  function handleDelete() {
    if (user && user.uid) {
      dispatch(users.userActions.deleteAccount(userWrappedData, user.uid))
      history.push('/users/')
    }
  }

  return (
    <div>
      <button
        id={'admin-button-' + userData.uid}
        type='button'
        className={'btn btn-sm btn-light-info'}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='left-start'
        disabled={isLoading}
        onClick={() => {
          if (userData.devices) {
            uidArray = []
            Object.keys(userData.devices).map((data) => uidArray.push(data))
            setCountByValueBody(uidArray.join())
          }
        }}
      >
        <span className='svg-icon svg-icon-5 rotate-180 ms-3 me-0'>
          <KTSVG path='/media/icons/duotune/general/gen052.svg' />
        </span>
      </button>
      <div
        id={'admin-dropdown-' + userData.uid}
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
        data-kt-menu='true'
      >
        {RoleCheckMin(Roles.Admin, admin.account.role) &&
          !RoleCheckMin(Roles.ModeratorConfirmed, userWrappedData.account?.role) && (
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3 text-danger'
                onClick={() => {
                  swal({
                    title:
                      `${user.nickname} ` +
                      `${user.nicknameId} ` +
                      intl.formatMessage({id: 'ALERT.WILLDELETE'}),
                    text: intl.formatMessage({id: 'ALERT.SECURITY'}),
                    buttons: [
                      intl.formatMessage({id: 'ALERT.CANCEL'}),
                      intl.formatMessage({id: 'ALERT.DELETE'}),
                    ],
                    icon: 'warning',
                    content: {
                      element: 'input',
                      attributes: {
                        placeholder: `${user.nicknameId}`,
                        type: 'text',
                      },
                    },
                  }).then((nicknameValidation) => {
                    if (nicknameValidation) {
                      if (nicknameValidation === user.nicknameId) {
                        swal(intl.formatMessage({id: 'ALERT.DELETING'}), {
                          icon: 'success',
                        })
                        handleDelete()
                      } else {
                        swal(intl.formatMessage({id: 'ALERT.WRONGNICKNAME'}), {
                          icon: 'error',
                        })
                      }
                    } else {
                      swal(intl.formatMessage({id: 'ALERT.STILLEXISTING'}), {
                        icon: 'error',
                      })
                    }
                  })
                }}
              >
                Delete
              </a>
            </div>
          )}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3 text-info'
            onClick={() =>
              dispatch(users.userActions.testFcmToken(user.uid, user.fcmTokens, userWrappedData))
            }
          >
            Test FcmToken
          </a>
        </div>
      </div>
    </div>
  )
}

export default AdminDropdown
