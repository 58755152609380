/* eslint-disable jsx-a11y/anchor-is-valid */

import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify'
import swal from 'sweetalert'
import {RootState} from '../../../setup'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {AccountUserWrapperModel} from '../../modules/auth/models/AccountUserWrapperModel'
import AvatarRenderer from '../../modules/profile/components/AvatarRenderer'
import {FiltersModel} from '../../modules/users/models/FiltersModel'
import AddModeratorModal from './AddModeratorModal'
import {AccountFilters} from './components/AccountFilters'
import * as accounts from './redux/AccountRedux'
import ChangeRoleModal from './ChangeRoleModal'

const Team: React.FC = () => {
  const intl = useIntl()
  const accountsList = useSelector<RootState>(
    ({account}) => account.accountsList,
    shallowEqual
  ) as AccountUserWrapperModel[]
  const currentFilters = useSelector<RootState>(
    ({account}) => account.accountFilters,
    shallowEqual
  ) as FiltersModel

  const [modalShow, setModalShow] = useState(false)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [accountToChange, setAccountToChange] = useState<AccountUserWrapperModel>()

  const [filters, setFilters] = useState({
    filterKey: '',
    filterValue: '',
    filterOperator: '==',
    filterType: '',
    sortingOrder: 'ASC',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (accountsList === null || accountsList.length === 0)
      dispatch(accounts.accountActions.getAccounts())

    if (currentFilters !== null)
      setFilters({
        filterKey: currentFilters.filterKey,
        filterValue: currentFilters.filterValue,
        filterOperator: currentFilters.filterOperator,
        filterType: currentFilters.filterType,
        sortingOrder: currentFilters.sortingOrder,
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (accountsList.length > 0) {
      setLoading(false)
      setLoadMore(false)
    }
    // initialize moderation dropdowns to their buttons
    setTimeout(function () {
      MenuComponent.reinitialization()
    }, 200)
  }, [accountsList])

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {intl.formatMessage({id: 'MENU.TEAM'})}
            </span>
          </h3>
        </div>
        <div className='d-flex ms-auto me-5'>
          <button className='btn btn-info' onClick={() => setShow(true)}>
            {intl.formatMessage({id: 'MODAL.ADDMODERATOR'})}
          </button>
          <AddModeratorModal show={show} setShow={setShow} />
          <AccountFilters filters={filters} setFilters={setFilters} setLoading={setLoading} />
          {modalShow && accountToChange && (
            <ChangeRoleModal show={modalShow} setShow={setModalShow} account={accountToChange} />
          )}
        </div>
        {loading ? (
          <div className=' text-center mt-10 mb-10'>
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'LOADER'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 card-body py-5'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th>{intl.formatMessage({id: 'TEAM.AVATAR'})}</th>
                    <th>{intl.formatMessage({id: 'TEAM.MODERATOR'})}</th>
                    <th>{intl.formatMessage({id: 'TEAM.ACCOUNTUID'})}</th>
                    <th>TW Uid</th>
                    <th>Email</th>
                    <th>{intl.formatMessage({id: 'TEAM.STATUS'})}</th>
                    <th>{intl.formatMessage({id: 'TEAM.ACTION'})}</th>
                  </tr>
                </thead>
                <tbody>
                  {accountsList.map((accountItem, index) => (
                    <tr key={index}>
                      <th>
                        {accountItem.user?.avatarLayers ? (
                          <AvatarRenderer
                            avatarLayers={accountItem.user.avatarLayers}
                            dimension={50}
                            uid={accountItem.user.uid}
                          />
                        ) : (
                          <AvatarRenderer avatarLayers={[]} dimension={50} />
                        )}
                      </th>
                      <th className='text-dark fw-bolder'>
                        {accountItem.user?.uid && (
                          <Link
                            to={{
                              pathname: `/users/${accountItem.user?.uid}`,
                              state: {userData: accountItem.user},
                            }}
                          >
                            <span className='text-dark fw-bolder text-hover-primary fs-6'>
                              {accountItem.user?.nickname + accountItem.user?.nicknameId}
                            </span>
                          </Link>
                        )}
                      </th>
                      <th>
                        <div className='d-flex'>
                          <span
                            className='text-dark fw-bolder w-100px overflow-hidden'
                            style={{
                              textOverflow: 'ellipsis',
                              lineHeight: '40px',
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            {accountItem.account.uid}
                          </span>
                          <span
                            className='fa fa-copy btn btn-icon'
                            style={{verticalAlign: 'top'}}
                            onClick={() =>
                              navigator.clipboard.writeText(accountItem.account.uid).then(() =>
                                toast.info(intl.formatMessage({id: 'TEXTCOPIED'}), {
                                  position: 'bottom-right',
                                  autoClose: 2000,
                                  hideProgressBar: true,
                                })
                              )
                            }
                          >
                            <ToastContainer />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className='d-flex'>
                          <span
                            className='text-dark fw-bolder w-100px overflow-hidden'
                            style={{
                              textOverflow: 'ellipsis',
                              lineHeight: '40px',
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            {accountItem.user?.uid}
                          </span>
                          {accountItem.user?.uid && (
                            <span
                              className='fa fa-copy btn btn-icon'
                              style={{verticalAlign: 'top'}}
                              onClick={() =>
                                navigator.clipboard.writeText(accountItem.user.uid).then(() =>
                                  toast.info(intl.formatMessage({id: 'TEXTCOPIED'}), {
                                    position: 'bottom-right',
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                  })
                                )
                              }
                            >
                              <ToastContainer />
                            </span>
                          )}
                        </div>
                      </th>
                      <th>{accountItem.account.email}</th>
                      <th>
                        <span
                          className={
                            'badge w-100 badge-' +
                            (accountItem.account.role === 'ADMIN'
                              ? 'info'
                              : accountItem.account.role === 'MODERATOR_CONFIRMED'
                              ? 'success'
                              : 'warning')
                          }
                        >
                          {accountItem.account.role}
                        </span>
                        <span
                          className={
                            'badge mt-2 w-100 badge-light-' +
                            (accountItem.account.status === 'INVITED'
                              ? 'warning'
                              : accountItem.account.status === 'ACTIVE'
                              ? 'success'
                              : 'danger')
                          }
                        >
                          {accountItem.account.status}
                        </span>
                      </th>
                      <th className='d-flex'>
                        <div className='d-flex flex-column'>
                          {(accountItem.account.status === 'ACTIVE' ||
                            accountItem.account.status === 'INVITED') &&
                            !accountItem.isLoading && (
                              <button
                                className='btn btn-sm btn-icon btn-danger'
                                onClick={() => {
                                  swal({
                                    title:
                                      `${accountItem.account.email} ` +
                                      intl.formatMessage({id: 'ALERT.WILLDEACTIVATE'}),
                                    text: intl.formatMessage({
                                      id: 'ALERT.SECURITY.DEACTIVATE.ACCOUNT',
                                    }),
                                    buttons: [
                                      intl.formatMessage({id: 'ALERT.CANCEL'}),
                                      intl.formatMessage({id: 'ALERT.DEACTIVATING'}),
                                    ],
                                    icon: 'warning',
                                    content: {
                                      element: 'input',
                                      attributes: {
                                        placeholder: `${accountItem.account.email}`,
                                        type: 'text',
                                      },
                                    },
                                  }).then((emailValidation) => {
                                    if (emailValidation) {
                                      if (emailValidation === accountItem.account.email) {
                                        swal(
                                          intl.formatMessage({id: 'ALERT.DEACTIVATING.ACCOUNT'}),
                                          {
                                            icon: 'success',
                                          }
                                        )
                                        dispatch(
                                          accounts.accountActions.deactivateAccounts(
                                            accountItem.account.uid,
                                            accountItem
                                          )
                                        )
                                      } else {
                                        swal(intl.formatMessage({id: 'ALERT.WRONGEMAIL'}), {
                                          icon: 'error',
                                        })
                                      }
                                    } else {
                                      swal(
                                        intl.formatMessage({id: 'ALERT.STILLACTIVATE.ACCOUNT'}),
                                        {
                                          icon: 'error',
                                        }
                                      )
                                    }
                                  })
                                }}
                              >
                                <i className='fas fa-ban'></i>
                              </button>
                            )}
                          {(accountItem.account.status === 'INACTIVE' ||
                            accountItem.account.status === 'INVITED') &&
                            !accountItem.isLoading && (
                              <button
                                className={
                                  'btn btn-sm btn-icon btn-success ' +
                                  (accountItem.account.status === 'INVITED' ? ' mt-2' : ' ')
                                }
                                onClick={() => {
                                  swal({
                                    title:
                                      `${accountItem.account.email} ` +
                                      intl.formatMessage({id: 'ALERT.WILLACTIVATE'}),
                                    text: intl.formatMessage({
                                      id: 'ALERT.SECURITY.ACTIVATE.ACCOUNT',
                                    }),
                                    buttons: [
                                      intl.formatMessage({id: 'ALERT.CANCEL'}),
                                      intl.formatMessage({id: 'ALERT.ACTIVATING'}),
                                    ],
                                    icon: 'warning',
                                    content: {
                                      element: 'input',
                                      attributes: {
                                        placeholder: `${accountItem.account.email}`,
                                        type: 'text',
                                      },
                                    },
                                  }).then((emailValidation) => {
                                    if (emailValidation) {
                                      if (emailValidation === accountItem.account.email) {
                                        swal(intl.formatMessage({id: 'ALERT.ACTIVATING.ACCOUNT'}), {
                                          icon: 'success',
                                        })
                                        dispatch(
                                          accounts.accountActions.activateAccounts(
                                            accountItem.account.uid,
                                            accountItem
                                          )
                                        )
                                      } else {
                                        swal(intl.formatMessage({id: 'ALERT.WRONGEMAIL'}), {
                                          icon: 'error',
                                        })
                                      }
                                    } else {
                                      swal(
                                        intl.formatMessage({id: 'ALERT.STILLDEACTIVATE.ACCOUNT'}),
                                        {
                                          icon: 'error',
                                        }
                                      )
                                    }
                                  })
                                }}
                              >
                                <i className='fas fa-check'></i>
                              </button>
                            )}
                          {accountItem.isLoading && (
                            <button className='btn btn-icon'>
                              <span className='spinner-border spinner-border-sm'></span>
                            </button>
                          )}
                        </div>
                        <div style={{margin: 'auto', marginLeft: '5%'}}>
                          <button
                            className='btn btn-sm btn-info'
                            onClick={() => {
                              setModalShow(true)
                              setAccountToChange(accountItem)
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className='btn-custom align-items-center text-center mb-5'>
          {accountsList.length % 10 !== 0 ? (
            ''
          ) : accountsList.length === 0 ? (
            ''
          ) : (
            <div>
              {loadMore ? (
                <button
                  type='button'
                  className='btn btn-sm btn-info btn-active-light-primary w-100px'
                >
                  <span className='spinner-border spinner-border-sm text-light'></span>
                </button>
              ) : (
                <button
                  className='btn btn-sm btn-info btn-active-light-primary w-100px'
                  onClick={() => {
                    dispatch(
                      accounts.accountActions.getAccounts(
                        accountsList[accountsList.length - 1].account.uid,
                        true,
                        filters.filterKey,
                        filters.filterValue,
                        filters.sortingOrder,
                        filters.filterType,
                        filters.filterOperator
                      )
                    )
                    setLoadMore(true)
                  }}
                >
                  {intl.formatMessage({id: 'LOADMORE'})}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {Team}
