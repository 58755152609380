import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {AvatarLayerTypeEnum} from '../../auth/models/AvatarLayerTypeEnum'
import {bundleKeys} from '../../../helpers/provider/BountiesProvider'
type Props = {
  assetType: any
  custom: any
  setCustom: any
  user: any
}

const AssetProvider: React.FC<Props> = ({assetType, custom, setCustom, user}) => {
  function getAvatarLayerSrcByType(type: string) {
    return custom[findIndexByType(type)]?.src
  }

  function getCssBorderValue(type: string, value: string) {
    return getAvatarLayerSrcByType(type) === value ? '5px #FFD008 solid' : '5px #FFFFFF solid'
  }

  function findIndexByType(type: string) {
    return custom.findIndex((object: any) => object.type === type)
  }

  function customize(element: string, layerType: AvatarLayerTypeEnum) {
    let tempArray = [...custom]
    const index = tempArray.findIndex((item) => item.type === layerType)

    let layer = {
      type: layerType,
      src: element,
    }

    if (element && layerType) {
      if (index < 0) tempArray.push(layer)
      else if (element === tempArray[index].src) {
        tempArray.splice(index)
      } else tempArray[index] = layer
    } else if (index !== -1) {
      tempArray.splice(index, 1)
    }

    setCustom(tempArray)
  }

  let userBounties: any = []

  if (user.bounties)
    Object.keys(user.bounties).map(
      (permanent) =>
        user.bounties[permanent].unlockedBy && userBounties.push(user.bounties[permanent].key)
    )

  return (
    <div>
      {Object.keys(assetType).map((assetKey, assetIndex) => {
        let assetPath = assetType[assetKey]
        return (
          !assetType[assetKey].hidden &&
          (Object.values(bundleKeys).includes(assetType[assetKey].lockType) ? (
            <div
              className='mt-5 ms-1'
              key={assetIndex + ' asset'}
              style={{
                display: 'inline-block',
              }}
            >
              <div
                className=' position-relative'
                style={{
                  border: getCssBorderValue(AvatarLayerTypeEnum.img, assetKey),
                  padding: '3px',
                  borderRadius: '20px',
                }}
              >
                <img
                  alt='avatar img'
                  src={toAbsoluteUrl(assetPath.preview)}
                  style={{
                    display: 'inline-block',
                    borderRadius: '15px',
                    backgroundColor: 'rgba(151, 135, 146, 0.1)',
                  }}
                  className='h-65px w-65px'
                  onClick={() => customize(assetKey, AvatarLayerTypeEnum.img)}
                />
                <div>
                  <i
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      backgroundColor: 'white',
                      opacity: '0.5',
                      borderBottomLeftRadius: '6px',
                      borderTopRightRadius: '15px',
                      height: '20px',
                      width: '27px',
                      lineHeight: '20px',
                    }}
                  ></i>
                  <img
                    alt='avatar'
                    src={toAbsoluteUrl(
                      userBounties.includes(assetType[assetKey].lockType)
                        ? '/media/icons/duotune/arrows/arr085.svg'
                        : '/media/avatars/talky/icon_crown_wt_plus.svg'
                    )}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '9px',
                      height: '13px',
                      width: '13px',
                      lineHeight: '20px',
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              className='mt-5 ms-1'
              key={assetIndex + ' asset'}
              style={{
                display: 'inline-block',
              }}
            >
              <div
                className=' position-relative'
                style={{
                  border: getCssBorderValue(AvatarLayerTypeEnum.img, assetKey),
                  padding: '3px',
                  borderRadius: '20px',
                }}
              >
                <img
                  alt='avatar img'
                  src={toAbsoluteUrl(assetPath.preview)}
                  style={{
                    display: 'inline-block',
                    borderRadius: '15px',
                    backgroundColor: 'rgba(151, 135, 146, 0.1)',
                  }}
                  className='h-65px w-65px'
                  onClick={() => customize(assetKey, AvatarLayerTypeEnum.img)}
                />
                {assetType[assetKey].lockType === 'SPECIAL' && (
                  <div>
                    <i
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        backgroundColor: 'white',
                        opacity: '0.5',
                        borderBottomLeftRadius: '6px',
                        borderTopRightRadius: '15px',
                        height: '20px',
                        width: '27px',
                        lineHeight: '20px',
                      }}
                    ></i>
                    <img
                      alt='crown img'
                      src={toAbsoluteUrl('/media/avatars/talky/icon_crown_wt_plus.svg')}
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '9px',
                        height: '13px',
                        width: '13px',
                        lineHeight: '20px',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))
        )
      })}
    </div>
  )
}

export default AssetProvider
