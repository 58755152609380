export const portionTime = (durationInSeconds: any) => {
  let hours = durationInSeconds / 3600
  let mins = (durationInSeconds % 3600) / 60
  let secs = (mins * 60) % 60

  hours = Math.trunc(hours)
  mins = Math.trunc(mins)

  if (!hours && !mins && !secs) {
    return 'None'
  }

  if (hours) {
    if (mins) {
      return secs ? `${hours} hr ${mins} min ${Math.round(secs)} sec` : `${hours} hr ${mins} min`
    } else {
      return secs ? `${hours} hr ${Math.round(secs)} sec` : `${hours} hr`
    }
  } else {
    if (mins) {
      return secs ? `${mins} min ${Math.round(secs)} sec` : `${mins} min`
    } else {
      return secs ? `${Math.round(secs)} sec` : `None`
    }
  }
}
