import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {AvatarLayerModel} from '../../users/models/ProfileModel'
import {hex} from '../../../helpers/converter/HexColorConverter'
import {AvatarLayerTypeEnum} from '../../auth/models/AvatarLayerTypeEnum'
import {seedToInt} from '../../../helpers/CustomRng'
import {ProviderShellAvatarColors} from '../../../helpers/provider/ProviderColor'
import AvatarRendererModal from '../components/AvatarRendererModal'
import cleanUpExpression from '../../../helpers/AvatarAssetHelper'
import {avatarAsset} from '../../../helpers/provider/ProviderAvatarData'

type Props = {
  avatarLayers: AvatarLayerModel[]
  dimension: number
  uid?: string
  editor?: boolean
  isLoading?: boolean
}
function autoSize(dimension: number) {
  return (14 / 100) * dimension
}

function getColorFromUid(uid?: string) {
  let colorIndex: number = 0
  let freeColor = []
  for (let i = 0; i < Object.keys(ProviderShellAvatarColors).length; i++) {
    if (Object.values(ProviderShellAvatarColors)[i] === 'free')
      freeColor.push(Object.keys(ProviderShellAvatarColors)[i])
  }

  try {
    if (uid) {
      colorIndex = seedToInt(uid, freeColor.length)
    }
  } catch (error) {
    console.error(error)
  }
  return freeColor[Math.abs(colorIndex) % freeColor.length]
}
const AvatarRendererFullBody: React.FC<Props> = ({
  avatarLayers,
  dimension,
  uid,
  editor,
  isLoading,
}) => {
  const [show, setShow] = useState(false)
  const [modalShow, setModalShow] = useState(false)

  if (!avatarLayers) avatarLayers = []

  let randomColor = getColorFromUid(uid)

  const defaultAvatar: AvatarLayerModel[] = [
    {
      type: AvatarLayerTypeEnum.bkgdColor,
      src: '#E0E0E0',
    },
    {
      type: AvatarLayerTypeEnum.shellColor,
      src: randomColor,
    },
    {
      type: AvatarLayerTypeEnum.expressionTxt,
      src: '^.^',
    },
  ]
  let result = null

  defaultAvatar.forEach((defaultAvatarRender) => {
    result = avatarLayers.filter((obj) => {
      return obj.type === defaultAvatarRender.type
    })
    if (!result || result.length === 0) avatarLayers.push(defaultAvatarRender)
  })

  return (
    <div style={{width: dimension + 'px', height: dimension + 'px'}}>
      {editor && (
        <img
          alt='edit logo'
          onClick={() => setModalShow(true)}
          onMouseOver={() => setShow(true)}
          onMouseOut={() => setShow(false)}
          className={'logo position-absolute opacity-' + (show ? '100' : '0')}
          src={toAbsoluteUrl('/media/icons/duotune/general/gen055.svg')}
          style={{left: '49%', top: '60%', zIndex: 5}}
        ></img>
      )}
      {editor && modalShow && !isLoading && (
        <AvatarRendererModal
          show={modalShow}
          setShow={setModalShow}
          avatarLayers={avatarLayers}
          dimension={dimension}
        />
      )}
      <div className={'bg-color-warning opacity-' + (show && editor ? '50' : '100')}>
        <div
          className='position-absolute z-index-3 w-100 text-center overflow-visible'
          style={{
            fontFamily: 'WalkieTalkie',
            top: '36%',
            fontSize: autoSize(dimension) + 'px',
          }}
        >
          <span className='' style={{color: '#002133', opacity: '0.1'}}>
            888
          </span>
        </div>
        {avatarLayers.map((avatarLayer, avatarLayerIndex) => (
          <div
            key={avatarLayer.type + '_' + avatarLayerIndex}
            className='position-absolute w-100 h-100'
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
            onClick={() => setModalShow(true)}
          >
            {avatarLayer.type === 'SHELL_COLOR' ? (
              <div className='position-absolute z-index-2 w-100 h-100'>
                <img
                  alt='avatar color'
                  className='logo z-index-1 position-absolute w-100 h-100'
                  style={{filter: hex[avatarLayer.src]}}
                  src={toAbsoluteUrl('/media/avatars/talky/fullbody/Avatar_body_color.svg')}
                />
                <img
                  alt='avatar color'
                  className='logo z-index-2 position-absolute w-100 h-100'
                  src={toAbsoluteUrl('/media/avatars/talky/fullbody/Avatar_shell_full_Body.svg')}
                />
              </div>
            ) : null}

            {avatarLayer.type === 'EXPRESSION_TXT' ? (
              <div
                className='position-absolute z-index-3 w-100 text-center overflow-visible'
                style={{
                  fontFamily: 'WalkieTalkie',
                  top: '36%',
                  fontSize: autoSize(dimension) + 'px',
                }}
              >
                {cleanUpExpression(avatarLayer.src)}
              </div>
            ) : null}

            {avatarLayer.type === 'IMG' &&
            avatarAsset[avatarLayer.src] &&
            avatarAsset[avatarLayer.src].fullBody ? (
              <img
                alt='avatar img'
                className='position-absolute w-100 h-100'
                style={{zIndex: 4}}
                src={toAbsoluteUrl(avatarAsset[avatarLayer.src].fullBody)}
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AvatarRendererFullBody
