export interface ProviderRecordHosts {
  [key: string]: string
}

export let hostsConverter: ProviderRecordHosts = {
  '78606c9c-773b-34bb-902e-60d0d1586d79': 'Baptiste',
  'fe168a27-644d-35df-b9cd-6ac55cf05b68': 'Valentin',
  '78552354-bee8-3ec8-a123-23d4331c7349': 'Corentin',
  '2517c680-0754-39b7-9e22-2432a241908e': 'Stephane',
  'a35ba04e-6676-3710-849a-225b8233fc3a': 'Maxime',
  '28339f61-a6a6-3e37-82d6-5a46d3c2e396': 'Rodrigo',
  '2e3368b1-2706-3f62-b30e-0c3051a027ee': 'Emmanuelle',
  'b1110050-15fa-3400-b78b-d59938a2cadb': 'Benjamin',
  '9ae7fdba-742f-35ff-93a1-94fa9ba0d6c9': 'Vincent',
  'f0e741ea-6287-3d01-929e-5c2635c147dd': 'Maeva',
  '93249015-5ade-32f4-832c-5aa8e1325a71': 'Jamie',
}
