export const achievementsKeys = {
  ONESHOT_WTPLUS_ACQUIRED_HALLOWEEN_2022: 'ONESHOT_WTPLUS_ACQUIRED_HALLOWEEN_2022',
  ONESHOT_WTPLUS_ACQUIRED_CHRISTMAS_2022: 'ONESHOT_WTPLUS_ACQUIRED_CHRISTMAS_2022',
  ONESHOT_WTPLUS_ACQUIRED_CHINESE_NEW_YEAR_2023: 'ONESHOT_WTPLUS_ACQUIRED_CHINESE_NEW_YEAR_2023',
  ONESHOT_WTPLUS_ACQUIRED_VALENTINES_2023: 'ONESHOT_WTPLUS_ACQUIRED_VALENTINES_2023',
  ONESHOT_WTPLUS_ACQUIRED_EASTER_2023: 'ONESHOT_WTPLUS_ACQUIRED_EASTER_2023',
  ONESHOT_WTPLUS_ACQUIRED_GREAT_TALKY_BROS: 'ONESHOT_WTPLUS_ACQUIRED_GREAT_TALKY_BROS',
  ONESHOT_WTPLUS_ACQUIRED_MAY_THE_4TH: 'ONESHOT_WTPLUS_ACQUIRED_MAY_THE_4TH',
  ONESHOT_WTPLUS_ACQUIRED_TEARS_OF_THE_WALKIE: 'ONESHOT_WTPLUS_ACQUIRED_TEARS_OF_THE_WALKIE',
  ONESHOT_WTPLUS_ACQUIRED_SPIDER_TALKIE: 'ONESHOT_WTPLUS_ACQUIRED_SPIDER_TALKIE',
  ONESHOT_WTPLUS_ACQUIRED_PRIDE_MONTH_2023: 'ONESHOT_WTPLUS_ACQUIRED_PRIDE_MONTH_2023',
  ONESHOT_WTPLUS_ACQUIRED_AQUATIC_SUMMER_2023: 'ONESHOT_WTPLUS_ACQUIRED_AQUATIC_SUMMER_2023',
  ONESHOT_WTPLUS_ACQUIRED_BACK_TO_SCHOOL_2023: 'ONESHOT_WTPLUS_ACQUIRED_BACK_TO_SCHOOL_2023',
  ONESHOT_WTPLUS_ACQUIRED_HALLOWEEN_2023: 'ONESHOT_WTPLUS_ACQUIRED_HALLOWEEN_2023',
  ONESHOT_WTPLUS_ACQUIRED_CHRISTMAS_2023: 'ONESHOT_WTPLUS_ACQUIRED_CHRISTMAS_2023',
  ONESHOT_WTPLUS_ACQUIRED_NEW_YEAR_2024: 'ONESHOT_WTPLUS_ACQUIRED_NEW_YEAR_2024',
  ONESHOT_WTPLUS_ACQUIRED_SKI_CLUB_2024: 'ONESHOT_WTPLUS_ACQUIRED_SKI_CLUB_2024',
  ONESHOT_WTPLUS_ACQUIRED_CHINESE_NEW_YEAR_2024: 'ONESHOT_WTPLUS_ACQUIRED_CHINESE_NEW_YEAR_2024',
  ONESHOT_WTPLUS_ACQUIRED_VALENTINES_2024: 'ONESHOT_WTPLUS_ACQUIRED_VALENTINES_2024',
  ONESHOT_WTPLUS_ACQUIRED_SUPERBOWL_2024: 'ONESHOT_WTPLUS_ACQUIRED_SUPERBOWL_2024',
}
