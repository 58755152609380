import {bundleKeys} from './BountiesProvider'

export interface ProviderAvatarLogoType {
  [key: string]: {
    faceOnly: string
    fullBody: string
    lockType: string | null
    preview: string
    hidden: boolean
  }
}

export const spotlightAsset: ProviderAvatarLogoType = {
  HAT_SUPERBOWL_2024_DRINKING_CAP_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_superbowl_2024_drinking_cap_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_superbowl_2024_drinking_cap_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_superbowl_2024_drinking_cap_1_preview.svg',
    hidden: false,
  },
  BODY_SUPERBOWL_2024_KC_JERSEY_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_superbowl_2024_kc_jersey_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_superbowl_2024_kc_jersey_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_kc_jersey_1_preview.svg',
    hidden: false,
  },
  BODY_SUPERBOWL_2024_KC_GLOVE_SUIT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_superbowl_2024_kc_glove_suit_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_superbowl_2024_kc_glove_suit_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_kc_glove_suit_1_preview.svg',
    hidden: false,
  },
  BODY_SUPERBOWL_2024_SF_JERSEY_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_superbowl_2024_sf_jersey_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_superbowl_2024_sf_jersey_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_sf_jersey_1_preview.svg',
    hidden: false,
  },
  BODY_SUPERBOWL_2024_SF_GLOVE_SUIT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_superbowl_2024_sf_glove_suit_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_superbowl_2024_sf_glove_suit_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_sf_glove_suit_1_preview.svg',
    hidden: false,
  },
  BODY_VALENTINES_2024_SUSPENDER_BELT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_valentines_2024_suspender_belt_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_valentines_2024_suspender_belt_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_valentines_2024_suspender_belt_1_preview.svg',
    hidden: false,
  },
  BODY_VALENTINES_2024_LOVE_STRUCK_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_body_valentines_2024_love_struck_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_body_valentines_2024_love_struck_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2024,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_body_valentines_2024_love_struck_1_preview.svg',
    hidden: false,
  },
  BODY_VALENTINES_2024_LOLLIPOP_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_body_valentines_2024_lollipop_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_body_valentines_2024_lollipop_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2024,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_body_valentines_2024_lollipop_1_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_CHINESE_NEW_YEAR_DRAGON_2: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_chinese_new_year_dragon_2.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_chinese_new_year_dragon_2_fullbody.svg',
    lockType: bundleKeys.CHINESE_NEW_YEAR_2024,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_chinese_new_year_dragon_2_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_CHINESE_NEW_YEAR_DRAGON_3: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_chinese_new_year_dragon_3.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_chinese_new_year_dragon_3_fullbody.svg',
    lockType: bundleKeys.CHINESE_NEW_YEAR_2024,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_chinese_new_year_dragon_3_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_SUPERBOWL_2024_SF_HELMET_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_superbowl_2024_sf_helmet_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_superbowl_2024_sf_helmet_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_superbowl_2024_sf_helmet_1_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_SUPERBOWL_2024_KC_HELMET_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_superbowl_2024_kc_helmet_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_superbowl_2024_kc_helmet_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2022,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_superbowl_2024_kc_helmet_1_preview.svg',
    hidden: false,
  },
}
export const eyewearAsset: ProviderAvatarLogoType = {
  EYEWEAR_GLASSES_NEW_YEAR_2024_PARTY_1: {
    faceOnly:
      '/media/avatars/talky/assets/eyewear/faceonly/avatar_layer_eyewear_glasses_new_year_2024_party_1.svg',
    fullBody:
      '/media/avatars/talky/assets/eyewear/fullbody/avatar_layer_eyewear_glasses_new_year_2024_party_1_fullbody.svg',
    lockType: bundleKeys.NEW_YEAR_2024,
    preview:
      '/media/avatars/talky/assets/eyewear/preview/avatar_layer_eyewear_glasses_new_year_2024_party_1_preview.svg',
    hidden: false,
  },
  EYEWEAR_GLASSES_HEARTS_1: {
    faceOnly:
      '/media/avatars/talky/assets/eyewear/faceonly/avatar_layer_eyewear_glasses_hearts_1.svg',
    fullBody:
      '/media/avatars/talky/assets/eyewear/fullbody/avatar_layer_eyewear_glasses_hearts_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/eyewear/preview/avatar_layer_eyewear_glasses_hearts_1_preview.svg',
    hidden: false,
  },
  EYEWEAR_GLASSES_HIPPIE_1: {
    faceOnly:
      '/media/avatars/talky/assets/eyewear/faceonly/avatar_layer_eyewear_glasses_hippie_1.svg',
    fullBody:
      '/media/avatars/talky/assets/eyewear/fullbody/avatar_layer_eyewear_glasses_hippie_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/eyewear/preview/avatar_layer_eyewear_glasses_hippie_1_preview.svg',
    hidden: false,
  },
  EYEWEAR_GLASSES_PARTY_1: {
    faceOnly:
      '/media/avatars/talky/assets/eyewear/faceonly/avatar_layer_eyewear_glasses_party_1.svg',
    fullBody:
      '/media/avatars/talky/assets/eyewear/fullbody/avatar_layer_eyewear_glasses_party_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/eyewear/preview/avatar_layer_eyewear_glasses_party_1_preview.svg',
    hidden: false,
  },
  EYEWEAR_GLASSES_WAYFARER_1: {
    faceOnly:
      '/media/avatars/talky/assets/eyewear/faceonly/avatar_layer_eyewear_glasses_wayfarer_1.svg',
    fullBody:
      '/media/avatars/talky/assets/eyewear/fullbody/avatar_layer_eyewear_glasses_wayfarer_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/eyewear/preview/avatar_layer_eyewear_glasses_wayfarer_1_preview.svg',
    hidden: false,
  },
  EYEWEAR_GLASSES_CATEYE_1: {
    faceOnly:
      '/media/avatars/talky/assets/eyewear/faceonly/avatar_layer_eyewear_glasses_cateye_1.svg',
    fullBody:
      '/media/avatars/talky/assets/eyewear/fullbody/avatar_layer_eyewear_glasses_cateye_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/eyewear/preview/avatar_layer_eyewear_glasses_cateye_1_preview.svg',
    hidden: false,
  },
  EYEWEAR_GLASSES_CATEYE_2: {
    faceOnly:
      '/media/avatars/talky/assets/eyewear/faceonly/avatar_layer_eyewear_glasses_cateye_2.svg',
    fullBody:
      '/media/avatars/talky/assets/eyewear/fullbody/avatar_layer_eyewear_glasses_cateye_2_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/eyewear/preview/avatar_layer_eyewear_glasses_cateye_2_preview.svg',
    hidden: false,
  },
}

export const headwearAsset: ProviderAvatarLogoType = {
  HAT_SUPERBOWL_2024_DRINKING_CAP_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_superbowl_2024_drinking_cap_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_superbowl_2024_drinking_cap_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_superbowl_2024_drinking_cap_1_preview.svg',
    hidden: false,
  },
  HAT_NEW_YEAR_2024_PARTY_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_new_year_2024_party_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_new_year_2024_party_1_fullbody.svg',
    lockType: bundleKeys.NEW_YEAR_2024,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_new_year_2024_party_1_preview.svg',
    hidden: false,
  },
  HAIR_LONG_NEW_YEAR_2024_GREEN_WIG_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hair_long_new_year_2024_green_wig_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hair_long_new_year_2024_green_wig_1_fullbody.svg',
    lockType: bundleKeys.NEW_YEAR_2024,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hair_long_new_year_2024_green_wig_1_preview.svg',
    hidden: false,
  },
  HAT_HALLOWEEN_2023_WITCH_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_halloween_2023_witch_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_halloween_2023_witch_1_fullbody.svg',
    lockType: bundleKeys.HALLOWEEN_2023,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_halloween_2023_witch_1_preview.svg',
    hidden: false,
  },
  HAT_BACK_TO_SCHOOL_2023_GRADUATION_CAP_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_back_to_school_2023_graduation_cap_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_back_to_school_2023_graduation_cap_1_fullbody.svg',
    lockType: bundleKeys.BACK_TO_SCHOOL_2023,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_back_to_school_2023_graduation_cap_1_preview.svg',
    hidden: false,
  },
  HAIR_SHORT_TEARS_OF_THE_WALKIE_ZELDA_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hair_short_tears_of_the_walkie_zelda_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hair_short_tears_of_the_walkie_zelda_1_fullbody_1.svg',
    lockType: bundleKeys.TEARS_OF_THE_WALKIE,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hair_short_tears_of_the_walkie_zelda_1_preview.svg',
    hidden: false,
  },
  HAIR_SHORT_MAY_THE_4TH_LEIA_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hair_short_may_the_4th_leia_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hair_short_may_the_4th_leia_1_fullbody.svg',
    lockType: bundleKeys.MAY_THE_4TH,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hair_short_may_the_4th_leia_1_preview_1.svg',
    hidden: false,
  },
  HAIR_SHORT_GREAT_TALKY_BROS_BOWSER_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hair_short_great_talky_sibs_bowser_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hair_short_great_talky_sibs_bowser_1_fullbody.svg',
    lockType: bundleKeys.GREAT_TALKY_BROS,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hair_short_great_talky_sibs_bowser_1_preview.svg',
    hidden: false,
  },
  HAIR_LONG_GREAT_TALKY_BROS_PEACH_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hair_long_great_talky_sibs_peach_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hair_long_great_talky_sibs_peach_1_fullbody.svg',
    lockType: bundleKeys.GREAT_TALKY_BROS,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hair_long_great_talky_sibs_peach_1_preview.svg',
    hidden: false,
  },
  HAT_EASTER_2023_EGG_BASKET_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_easter_2023_egg_basket_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_easter_2023_egg_basket_1_fullbody.svg',
    lockType: bundleKeys.EASTER_2023,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_easter_2023_egg_basket_1_preview.svg',
    hidden: false,
  },
  HAT_EASTER_2023_CHICKEN_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_easter_2023_chicken.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_easter_2023_chicken_fullbody.svg',
    lockType: bundleKeys.EASTER_2023,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_easter_2023_chicken_preview.svg',
    hidden: false,
  },
  HAT_EASTER_2023_EGGSHELL_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_easter_2023_eggshell.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_easter_2023_eggshell_fullbody.svg',
    lockType: bundleKeys.EASTER_2023,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_easter_2023_eggshell_preview.svg',
    hidden: false,
  },
  HAT_HELMET_FIREFIGHTER_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_helmet_firefighter_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_helmet_firefighter_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_helmet_firefighter_1_preview.svg',
    hidden: false,
  },
  HAT_UNICORN_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_unicorn_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_unicorn_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_unicorn_1_preview.svg',
    hidden: false,
  },
  HAT_CROWN_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_crown_1.svg',
    fullBody: '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_crown_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_crown_1_preview.svg',
    hidden: false,
  },
  HAT_CROWN_2: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_crown_2.svg',
    fullBody: '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_crown_2_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_crown_2_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_STRANGER_TALKIES_DUSTIN: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_facial_outer_stranger_talkies_dustin.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_facial_outer_stranger_talkies_dustin_fullbody.svg',
    lockType: bundleKeys.STRANGER_TALKIES_2022,
    preview:
      '/media/avatars/talky/assets/headwear/preview/facial_outer_weirder_stuff_dustin_1_preview.svg',
    hidden: false,
  },
  HAT_STRANGER_TALKIES_EGGO: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_stranger_talkies_eggo.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_stranger_talkies_eggo_fullbody.svg',
    lockType: bundleKeys.STRANGER_TALKIES_2022,
    preview: '/media/avatars/talky/assets/headwear/preview/hat_weirder_stuff_eggo_preview.svg',
    hidden: false,
  },
  HAT_BEANIE_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_beanie_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_beanie_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_beanie_1_preview.svg',
    hidden: false,
  },
  HAT_BEANIE_WOOL_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_beanie_wool_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_beanie_wool_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_beanie_wool_1_preview.svg',
    hidden: false,
  },
  HAT_BEANIE_CHECKERED_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_beanie_checkered_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_beanie_checkered_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_beanie_checkered_1_preview.svg',
    hidden: false,
  },
  HAT_BANDANA_PIRATE_PARROT_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_bandana_pirate_parrot_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_bandana_pirate_parrot_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_bandana_pirate_parrot_1_preview.svg',
    hidden: false,
  },
  HAT_BEANIE_EARFLAP_KITTY_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_beanie_earflap_kitty_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_beanie_earflap_kitty_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_beanie_earflap_kitty_1_preview.svg',
    hidden: false,
  },
  HAT_BEANIE_EARFLAP_FROG_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_beanie_earflap_frog_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_beanie_earflap_frog_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_frog_1_preview.svg',
    hidden: false,
  },
  HAT_FLOWER_CROWN_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_flower_crown_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_flower_crown_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_flower_crown_1_preview.svg',
    hidden: false,
  },
  HAT_CAP_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_cap_1.svg',
    fullBody: '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_cap_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_cap_1_preview.svg',
    hidden: false,
  },
  HAT_CAP_BACKWARDS_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_cap_backwards_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_cap_backwards_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_cap_backwards_1_preview.svg',
    hidden: false,
  },
  HAT_FISHER_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_fisher_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_fisher_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_fisher_1_preview.svg',
    hidden: false,
  },
  HAT_FISHER_2: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_fisher_2.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_fisher_2_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_fisher_2_preview.svg',
    hidden: false,
  },
  HAT_LADY_SUMMER_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_lady_summer_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_lady_summer_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_lady_summer_1_preview.svg',
    hidden: false,
  },
  HAT_CUBAN_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_cuban_1.svg',
    fullBody: '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_cuban_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_cuban_1_preview.svg',
    hidden: false,
  },
  HAT_CONICAL_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_conical_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_conical_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_conical_1_preview.svg',
    hidden: false,
  },
  HAT_TURBAN_1: {
    faceOnly: '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_turban_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_turban_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_turban_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_HELMET_ASTRONAUT_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_facial_outer_helmet_astronaut_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_facial_outer_helmet_astronaut_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_facial_outer_helmet_astronaut_1_preview.svg',
    hidden: false,
  },
}

export const suitAsset: ProviderAvatarLogoType = {
  BODY_SUPERBOWL_2024_KC_JERSEY_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_superbowl_2024_kc_jersey_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_superbowl_2024_kc_jersey_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_kc_jersey_1_preview.svg',
    hidden: false,
  },
  BODY_SUPERBOWL_2024_KC_GLOVE_SUIT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_superbowl_2024_kc_glove_suit_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_superbowl_2024_kc_glove_suit_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_kc_glove_suit_1_preview.svg',
    hidden: false,
  },
  BODY_SUPERBOWL_2024_SF_JERSEY_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_superbowl_2024_sf_jersey_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_superbowl_2024_sf_jersey_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_sf_jersey_1_preview.svg',
    hidden: false,
  },
  BODY_SUPERBOWL_2024_SF_GLOVE_SUIT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_superbowl_2024_sf_glove_suit_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_superbowl_2024_sf_glove_suit_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_sf_glove_suit_1_preview.svg',
    hidden: false,
  },
  BODY_VALENTINES_2024_SUSPENDER_BELT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_valentines_2024_suspender_belt_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_valentines_2024_suspender_belt_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_valentines_2024_suspender_belt_1_preview.svg',
    hidden: false,
  },
  BODY_SKI_CLUB_2024_DOWN_JACKET_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_ski_club_2024_down_jacket_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_ski_club_2024_down_jacket_1_fullbody.svg',
    lockType: bundleKeys.SKI_CLUB_2024,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_ski_club_2024_down_jacket_1_preview.svg',
    hidden: false,
  },
  BODY_CHRISTMAS_2023_HOLLY_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_christmas_2023_holly_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_christmas_2023_holly_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_christmas_2023_holly_1_preview.svg',
    hidden: false,
  },
  BODY_CHRISTMAS_2023_CANDYPANTY_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_christmas_2023_candypanty_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_christmas_2023_candypanty_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_christmas_2023_candypanty_1_preview.svg',
    hidden: false,
  },
  BODY_CHRISTMAS_2023_ICECUBE_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_christmas_2023_icecube_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_christmas_2023_icecube_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_christmas_2023_icecube_1_preview.svg',
    hidden: false,
  },
  BODY_CHRISTMAS_2023_JACQUARD_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_christmas_2023_jacquard_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_christmas_2023_jacquard_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_christmas_2023_jacquard_1_preview.svg',
    hidden: false,
  },
  BODY_HALLOWEEN_2023_DRACULA_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_halloween_2023_dracula_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_halloween_2023_dracula_1_fullbody.svg',
    lockType: bundleKeys.HALLOWEEN_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_halloween_2023_dracula_1_preview.svg',
    hidden: false,
  },
  BODY_HALLOWEEN_2023_SKELLY_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_halloween_2023_skelly_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_halloween_2023_skelly_1_fullbody.svg',
    lockType: bundleKeys.HALLOWEEN_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_halloween_2023_skelly_1_preview.svg',
    hidden: false,
  },
  BODY_HALLOWEEN_2023_MUMMY_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_halloween_2023_mummy_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_halloween_2023_mummy_1_fullbody.svg',
    lockType: bundleKeys.HALLOWEEN_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_halloween_2023_mummy_1_preview.svg',
    hidden: false,
  },
  BODY_BACK_TO_SCHOOL_2023_CHEERLEADER_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_back_to_school_2023_cheerleader_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_back_to_school_2023_cheerleader_1_fullbody.svg',
    lockType: bundleKeys.BACK_TO_SCHOOL_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_back_to_school_2023_cheerleader_1_preview.svg',
    hidden: false,
  },
  BODY_BACK_TO_SCHOOL_2023_PENCIL_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_back_to_school_2023_pencil_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_back_to_school_2023_pencil_1_fullbody.svg',
    lockType: bundleKeys.BACK_TO_SCHOOL_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_back_to_school_2023_pencil_1_preview.svg',
    hidden: false,
  },
  BODY_AQUATIC_SUMMER_2023_SURFER_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_aquatic_summer_2023_surfer_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_aquatic_summer_2023_surfer_1_fullbody.svg',
    lockType: bundleKeys.AQUATIC_SUMMER_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_aquatic_summer_2023_surfer_1_preview.svg',
    hidden: false,
  },
  BODY_AQUATIC_SUMMER_2023_TOURIST_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_aquatic_summer_2023_tourist_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_aquatic_summer_2023_tourist_1_fullbody.svg',
    lockType: bundleKeys.AQUATIC_SUMMER_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_aquatic_summer_2023_tourist_1_preview.svg',
    hidden: false,
  },
  BODY_PRIDE_MONTH_2023_SKIRT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_pride_month_2023_skirt_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_pride_month_2023_skirt_1_fullbody.svg',
    lockType: bundleKeys.PRIDE_MONTH_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_pride_month_2023_skirt_1_preview.svg',
    hidden: false,
  },
  BODY_PRIDE_MONTH_2023_UNDERWEAR_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_pride_month_2023_underwear_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_pride_month_2023_underwear_1_fullbody.svg',
    lockType: bundleKeys.PRIDE_MONTH_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_pride_month_2023_underwear_1_preview.svg',
    hidden: false,
  },
  BODY_PRIDE_MONTH_2023_WINGS_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_pride_month_2023_wings_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_pride_month_2023_wings_1_fullbody.svg',
    lockType: bundleKeys.PRIDE_MONTH_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_pride_month_2023_wings_1_preview.svg',
    hidden: false,
  },
  BODY_SPIDER_TALKIE_SPOT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_spider_talkie_spot_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_spider_talkie_spot_1_fullbody.svg',
    lockType: bundleKeys.SPIDER_TALKIE,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_spider_talkie_spot_1_preview.svg',
    hidden: false,
  },
  BODY_SPIDER_TALKIE_PUNK_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_spider_talkie_punk_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_spider_talkie_punk_1_fullbody.svg',
    lockType: bundleKeys.SPIDER_TALKIE,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_spider_talkie_punk_1_preview.svg',
    hidden: false,
  },
  BODY_SPIDER_TALKIE_GWEN_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_spider_talkie_gwen_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_spider_talkie_gwen_1_fullbody.svg',
    lockType: bundleKeys.SPIDER_TALKIE,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_spider_talkie_gwen_1_preview.svg',
    hidden: false,
  },
  BODY_SPIDER_TALKIE_MILES_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_spider_talkie_miles_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_spider_talkie_miles_1_fullbody.svg',
    lockType: bundleKeys.SPIDER_TALKIE,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_spider_talkie_miles_1_preview.svg',
    hidden: false,
  },

  BODY_TEARS_OF_THE_WALKIE_LINK_HERO_TUNIC_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_tears_of_the_walkie_link_hero_tunic_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_tears_of_the_walkie_link_hero_tunic_1_fullbody_1.svg',
    lockType: bundleKeys.TEARS_OF_THE_WALKIE,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_tears_of_the_walkie_link_hero_tunic_1_preview.svg',
    hidden: false,
  },
  BODY_TEARS_OF_THE_WALKIE_GANON_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_tears_of_the_walkie_ganon_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_tears_of_the_walkie_ganon_1_fullbody.svg',
    lockType: bundleKeys.TEARS_OF_THE_WALKIE,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_tears_of_the_walkie_ganon_1_preview.svg',
    hidden: false,
  },

  BODY_MAY_THE_4TH_LUKE_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_avatar_layer_body_may_the_4th_luke_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_avatar_layer_body_may_the_4th_luke_1_fullbody.svg',
    lockType: bundleKeys.MAY_THE_4TH,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_avatar_layer_body_may_the_4th_luke_1_preview.svg',
    hidden: false,
  },
  BODY_MAY_THE_4TH_YODA_1: {
    faceOnly: '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_may_the_4th_yoda_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_may_the_4th_yoda_1_fullbody.svg',
    lockType: bundleKeys.MAY_THE_4TH,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_may_the_4th_yoda_1_preview.svg',
    hidden: false,
  },
  BODY_CAVEMAN_1: {
    faceOnly: '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_caveman_1.svg',
    fullBody: '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_caveman_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview: '/media/avatars/talky/assets/suit/preview/avatar_layer_body_caveman_1_preview.svg',
    hidden: false,
  },
  BODY_VALENTINES_2023_CUPID_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_valentines_2023_cupid_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_valentines_2023_cupid_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_valentines_2023_cupid_1_preview.svg',
    hidden: false,
  },
  BODY_CHINESE_NEW_YEAR_TANG_SUIT_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_chinese_new_year_tang_suit_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_chinese_new_year_tang_suit_1_fullbody.svg',
    lockType: bundleKeys.CHINESE_NEW_YEAR_2023,
    preview: '/media/avatars/talky/assets/suit/preview/chineseNewYear1Preview.svg',
    hidden: false,
  },
  BODY_CHRISTMAS_LIGHTS_1: {
    faceOnly: '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_christmas_lights_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_christmas_lights_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2022,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_christmas_lights_1_preview.svg',
    hidden: false,
  },
  BODY_HALLOWEEN_2022_GHOST_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_halloween_2022_ghost_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_halloween_2022_ghost_1_fullbody.svg',
    lockType: bundleKeys.HALLOWEEN_2022,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_halloween_2022_ghost_1_preview.svg',
    hidden: false,
  },
  BODY_HALLOWEEN_2022_FRANKENSTEIN_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_halloween_2022_frankenstein_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_halloween_2022_frankenstein_1_fullbody.svg',
    lockType: bundleKeys.HALLOWEEN_2022,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_halloween_2022_frankenstein_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_STRANGER_TALKIES_AHOY: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_facial_outer_stranger_talkies_ahoy.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_facial_outer_stranger_talkies_ahoy.svg',
    lockType: bundleKeys.STRANGER_TALKIES_2022,
    preview: '/media/avatars/talky/assets/suit/preview/facial_outer_weirder_stuff_ahoy_preview.svg',
    hidden: false,
  },
}

export const maskAsset: ProviderAvatarLogoType = {
  FACIAL_MASK_CHINESE_NEW_YEAR_DRAGON_2: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_chinese_new_year_dragon_2.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_chinese_new_year_dragon_2_fullbody.svg',
    lockType: bundleKeys.CHINESE_NEW_YEAR_2024,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_chinese_new_year_dragon_2_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_CHINESE_NEW_YEAR_DRAGON_3: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_chinese_new_year_dragon_3.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_chinese_new_year_dragon_3_fullbody.svg',
    lockType: bundleKeys.CHINESE_NEW_YEAR_2024,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_chinese_new_year_dragon_3_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_SUPERBOWL_2024_SF_HELMET_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_superbowl_2024_sf_helmet_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_superbowl_2024_sf_helmet_1_fullbody.svg',
    lockType: bundleKeys.SUPERBOWL_2024,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_superbowl_2024_sf_helmet_1_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_SUPERBOWL_2024_KC_HELMET_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_superbowl_2024_kc_helmet_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_superbowl_2024_kc_helmet_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2022,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_superbowl_2024_kc_helmet_1_preview.svg',
    hidden: false,
  },
  FACIAL_FULL_CHRISTMAS_SNOWGLOBE_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_facial_full_christmas_snowglobe_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_facial_full_christmas_snowglobe_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2022,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_facial_full_christmas_snowglobe_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_AQUATIC_SUMMER_2023_OCTOPUS_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_aquatic_summer_2023_octopus_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_body_aquatic_summer_2023_octopus_1_fullbody.svg',
    lockType: bundleKeys.AQUATIC_SUMMER_2023,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_aquatic_summer_2023_octopus_1_preview.svg',
    hidden: false,
  },
  HAT_HELMET_MAY_THE_4TH_MANDO_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_helmet_may_the_4th_mando_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_helmet_may_the_4th_mando_1_fullbody.svg',
    lockType: bundleKeys.MAY_THE_4TH,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_helmet_may_the_4th_mando_1_preview_1.svg',
    hidden: false,
  },
  FACIAL_OUTER_TEARS_OF_THE_WALKIE_BOKOBLIN_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_tears_of_the_walkie_bokoblin_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_tears_of_the_walkie_bokoblin_1_fullbody.svg',
    lockType: bundleKeys.TEARS_OF_THE_WALKIE,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_tears_of_the_walkie_bokoblin_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_GREAT_TALKY_BROS_PIRANHA_PLANT_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_great_talky_sibs_piranha_plant_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_great_talky_sibs_piranha_plant_1_fullbody.svg',
    lockType: bundleKeys.GREAT_TALKY_BROS,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_great_talky_sibs_piranha_plant_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_GREAT_TALKY_BROS_MARIO_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_great_talky_sibs_mario_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_great_talky_sibs_mario_1_fullbody.svg',
    lockType: bundleKeys.GREAT_TALKY_BROS,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_great_talky_sibs_mario_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_FLOWER_1: {
    faceOnly: '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_flower_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_flower_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_flower_1_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_CHINESE_NEW_YEAR_DRAGON_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_chinese_new_year_dragon_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_chinese_new_year_dragon_1_fullbody.svg',
    lockType: bundleKeys.CHINESE_NEW_YEAR_2023,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_chinese_new_year_dragon_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_CHRISTMAS_TREE_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_christmas_tree_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_christmas_tree_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2022,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_christmas_tree_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_CHRISTMAS_TURKEY_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_christmas_turkey_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_christmas_turkey_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2022,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_christmas_turkey_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_HALLOWEEN_2022_PUMPKIN_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_halloween_2022_pumpkin_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_halloween_2022_pumpkin_1_fullbody.svg',
    lockType: bundleKeys.HALLOWEEN_2022,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_halloween_2022_pumpkin_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_STRANGER_TALKIES_GORGY: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_stranger_talkies_gorgy.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_stranger_talkies_gorgy_fullbody.svg',
    lockType: bundleKeys.STRANGER_TALKIES_2022,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_stranger_talkies_gorgy_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_TALKINATOR_1: {
    faceOnly:
      '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_outer_talkinator_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_outer_talkinator_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_robot_1_preview.svg',
    hidden: false,
  },

  FACIAL_MASK_SCUBA_1: {
    faceOnly: '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_scuba_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_scuba_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_scuba_1_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_CARNIVAL_1: {
    faceOnly: '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_carnival_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_carnival_1_fullbody.svg',
    lockType: bundleKeys.WT_PLUS,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_carnival_1_preview.svg',
    hidden: false,
  },
}

export const accessoryAsset: ProviderAvatarLogoType = {
  BODY_VALENTINES_2024_LOVE_STRUCK_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_body_valentines_2024_love_struck_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_body_valentines_2024_love_struck_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2024,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_body_valentines_2024_love_struck_1_preview.svg',
    hidden: false,
  },
  BODY_VALENTINES_2024_LOLLIPOP_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_body_valentines_2024_lollipop_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_body_valentines_2024_lollipop_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2024,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_body_valentines_2024_lollipop_1_preview.svg',
    hidden: false,
  },
  FACIAL_OUTER_SKI_CLUB_2024_EARMUFFS_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_facial_outer_ski_club_2024_earmuffs_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_facial_outer_ski_club_2024_earmuffs_1_fullbody.svg',
    lockType: bundleKeys.SKI_CLUB_2024,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_facial_outer_ski_club_2024_earmuffs_1_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_SKI_CLUB_2024_SKIER_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_facial_mask_ski_club_2024_skier_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_facial_mask_ski_club_2024_skier_1_fullbody.svg',
    lockType: bundleKeys.SKI_CLUB_2024,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_facial_mask_ski_club_2024_skier_1_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_SKI_CLUB_2024_SNOWBOARDER_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_facial_mask_ski_club_2024_snowboarder_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_facial_mask_ski_club_2024_snowboarder_1_fullbody.svg',
    lockType: bundleKeys.SKI_CLUB_2024,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_facial_mask_ski_club_2024_snowboarder_1_preview.svg',
    hidden: false,
  },
  ACCESSORY_BACK_TO_SCHOOL_2023_BAG_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_accessory_back_to_school_2023_bag_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_accessory_back_to_school_2023_bag_1_fullbody.svg',
    lockType: bundleKeys.BACK_TO_SCHOOL_2023,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_accessory_back_to_school_2023_bag_1_preview.svg',
    hidden: false,
  },
  BODY_AQUATIC_SUMMER_2023_FLAMINGO_BUOY_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_body_aquatic_summer_2023_flamingo_buoy_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_body_aquatic_summer_2023_flamingo_buoy_1_fullbody.svg',
    lockType: bundleKeys.EASTER_2023,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_body_aquatic_summer_2023_flamingo_buoy_1_preview.svg',
    hidden: false,
  },
  BODY_PRIDE_MONTH_2023_FLAG_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_pride_month_2023_flag_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_pride_month_2023_flag_1_fullbody.svg',
    lockType: bundleKeys.PRIDE_MONTH_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_pride_month_2023_flag_1_preview.svg',
    hidden: false,
  },

  NECK_EASTER_2023_BLUE_BOW_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_neck_easter_2023_blue_bow_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_neck_easter_2023_blue_bow_1_fullbody.svg',
    lockType: bundleKeys.EASTER_2023,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_neck_easter_2023_blue_bow_1_preview.svg',
    hidden: false,
  },
  HAT_BOW_1: {
    faceOnly: '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_hat_bow_1.svg',
    fullBody: '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_hat_bow_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/accessory/preview/avatar_layer_hat_bow_1_preview.svg',
    hidden: false,
  },
  NECK_BOWTIE_1: {
    faceOnly: '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_neck_bowtie_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_neck_bowtie_1_fullbody.svg',
    lockType: null,
    preview: '/media/avatars/talky/assets/accessory/preview/avatar_layer_neck_bowtie_1_preview.svg',
    hidden: false,
  },
  HAT_BAND_VALENTINES_2023_HEARTS_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_band_valentines_2023_hearts_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_band_valentines_2023_hearts_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2023,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_band_valentines_2023_hearts_1_preview.svg',
    hidden: false,
  },
  BODY_VALENTINES_2023_HEART_BALOON_1: {
    faceOnly:
      '/media/avatars/talky/assets/suit/faceonly/avatar_layer_body_valentines_2023_heart_baloon_1.svg',
    fullBody:
      '/media/avatars/talky/assets/suit/fullbody/avatar_layer_body_valentines_2023_heart_baloon_1_fullbody.svg',
    lockType: bundleKeys.VALENTINES_2023,
    preview:
      '/media/avatars/talky/assets/suit/preview/avatar_layer_body_valentines_2023_heart_baloon_1_preview.svg',
    hidden: false,
  },
  HAT_BAND_CHRISTMAS_REINDEER_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_band_christmas_reindeer_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_band_christmas_reindeer_1_fullbody.svg',
    lockType: bundleKeys.CHRISTMAS_2022,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_band_christmas_reindeer_1_preview.svg',
    hidden: false,
  },
  HAT_BAND_EARS_BUNNY_1: {
    faceOnly:
      '/media/avatars/talky/assets/headwear/faceonly/avatar_layer_hat_band_ears_bunny_1.svg',
    fullBody:
      '/media/avatars/talky/assets/headwear/fullbody/avatar_layer_hat_band_ears_bunny_1_fullbody.svg',
    lockType: bundleKeys.EASTER_2022,
    preview:
      '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_band_ears_bunny_1_preview.svg',
    hidden: false,
  },
  FACIAL_MASK_SURGICAL_1: {
    faceOnly: '/media/avatars/talky/assets/mask/faceonly/avatar_layer_facial_mask_surgical_1.svg',
    fullBody:
      '/media/avatars/talky/assets/mask/fullbody/avatar_layer_facial_mask_surgical_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_surgical_1_preview.svg',
    hidden: false,
  },
  FACIAL_HAIR_MUSTACHE_1: {
    faceOnly:
      '/media/avatars/talky/assets/accessory/faceonly/avatar_layer_facial_hair_mustache_1.svg',
    fullBody:
      '/media/avatars/talky/assets/accessory/fullbody/avatar_layer_facial_hair_mustache_1_fullbody.svg',
    lockType: null,
    preview:
      '/media/avatars/talky/assets/accessory/preview/avatar_layer_facial_hair_mustache_1_preview.svg',
    hidden: false,
  },
}

export const specialAsset: ProviderAvatarLogoType = {
  HAT_CAP_STAFF_1: {
    faceOnly: '/media/avatars/talky/assets/special/faceonly/avatar_layer_hat_cap_staff_1.svg',
    fullBody:
      '/media/avatars/talky/assets/special/fullbody/avatar_layer_hat_cap_staff_1_fullbody.svg',
    lockType: 'SPECIAL',
    preview: '/media/avatars/talky/assets/special/preview/avatar_layer_hat_cap_staff_1_preview.svg',
    hidden: false,
  },
  HAT_PARTY_1: {
    faceOnly: '/media/avatars/talky/assets/special/faceonly/avatar_layer_hat_party_1.svg',
    fullBody: '/media/avatars/talky/assets/special/fullbody/avatar_layer_hat_party_1_fullbody.svg',
    lockType: 'SPECIAL',
    preview: '/media/avatars/talky/assets/special/preview/avatar_layer_hat_party_1_preview.svg',
    hidden: false,
  },
}

export const avatarAsset: ProviderAvatarLogoType = {
  ...spotlightAsset,
  ...eyewearAsset,
  ...headwearAsset,
  ...suitAsset,
  ...maskAsset,
  ...accessoryAsset,
  ...specialAsset,
}
