export function seedToInt(seed: string, max: number) {
  // Base64 encode the UUID string
  const encodedHash = _base64ToArrayBuffer(seed)

  // Get the sum of all the bits in the encoded hash
  let total = 0

  for (let i = 0; i < encodedHash.length; i++) {
    total += encodedHash[i]
  }
  // Modulo the result to get it clamped to the maximum wanted value
  return Math.abs(total) % max
}
function _base64ToArrayBuffer(base64: string) {
  let binary_string: string = btoa(base64)
  let len: number = binary_string.length
  let bytes: Uint8Array = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  let result: Uint8Array = new Uint8Array(bytes.buffer)

  return result
}
