import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {call, put, takeLatest, select} from 'redux-saga/effects'
import axios from 'axios'
import {ApiRequestManager} from '../../../logic/ApiRequestManager'
import {RecordsModel} from '../models/RecordModel'
import {FiltersModel} from '../../../modules/users/models/FiltersModel'
export interface RecordActionWithPayload<T> extends Action {
  payload?: T
}

export interface recordListGetParams {
  cursorLastDocumentKey: string
  limit: number
  filterKey?: string
  filterValue?: string
  sortingKey?: string
  sortingValue?: string
  type?: string
  operator?: string
}

export const recordActionTypes = {
  GetRecords: '[GetRecords] Action',
  SetRecords: '[SetRecords] Action',
  LoadRecords: '[LoadRecords] Action',
  GetRecordingMeta: '[GetRecordingMeta] Action',
  UpdateRecord: '[UpdateRecord] Action',
  DeleteRecord: '[DeleteRecord] Action',
  SetRecordFilters: '[SetRecordFilters] Action',
}

const initialRecordState: IRecordState = {
  recordsList: [],
  recordFilters: null,
  result: true,
}

export interface IRecordState {
  recordsList: RecordsModel[]
  recordFilters: FiltersModel | null
  result: boolean
}

export const recordReducer = persistReducer(
  {storage, key: 'v100-intra-record', whitelist: ['record']},
  (state: IRecordState = initialRecordState, action: RecordActionWithPayload<any>) => {
    switch (action.type) {
      case recordActionTypes.SetRecords: {
        const {records} = action.payload
        const {concat} = action.payload
        const {resultLength} = action.payload
        let finalRecordArray: RecordsModel[] = concat ? state.recordsList.concat(records) : records
        return {...state, recordsList: finalRecordArray, result: resultLength}
      }
      case recordActionTypes.UpdateRecord: {
        let record = action.payload.record
        const finalRecordArray = [...state.recordsList]
        finalRecordArray.forEach((recordTmp, i) => {
          if (recordTmp.conference?.confId === record.conference.confId) {
            finalRecordArray[i] = {
              ...recordTmp,
              url: record.url,
              isLoading: false,
              records: record.records,
            }
          }
        })
        return {...state, recordsList: finalRecordArray}
      }
      case recordActionTypes.LoadRecords: {
        let record = action.payload.record
        const finalRecordArray = [...state.recordsList]
        finalRecordArray.forEach((recordTmp, i) => {
          if (recordTmp.conference?.confId === record.conference.confId) {
            finalRecordArray[i] = {...record, isLoading: true}
          }
        })
        return {...state, recordsList: finalRecordArray}
      }
      case recordActionTypes.SetRecordFilters: {
        const {filters} = action.payload
        return {...state, recordFilters: filters}
      }

      default:
        return state
    }
  }
)

export const recordActions = {
  getRecords: (
    lastConfId?: string | null,
    concat?: boolean,
    filterKey?: string,
    filterValue?: string,
    sortingValue?: string,
    type?: string,
    operator?: string,
    sortingKey?: string
  ) => ({
    type: recordActionTypes.GetRecords,
    payload: {lastConfId, concat, filterKey, filterValue, sortingValue, type, operator, sortingKey},
  }),
  loadRecords: (record: RecordsModel) => ({type: recordActionTypes.LoadRecords, payload: {record}}),
  setRecords: (records: RecordsModel[], concat?: boolean, resultLength?: boolean) => ({
    type: recordActionTypes.SetRecords,
    payload: {records, concat, resultLength},
  }),
  getRecordingMeta: (confId: string, callBack: any, record: RecordsModel) => ({
    type: recordActionTypes.GetRecordingMeta,
    payload: {confId, callBack, record},
  }),
  updateRecords: (record: RecordsModel) => ({
    type: recordActionTypes.UpdateRecord,
    payload: {record},
  }),
  deleteRecords: (confId: string) => ({type: recordActionTypes.DeleteRecord, payload: {confId}}),
  setRecordFilters: (filters: FiltersModel) => ({
    type: recordActionTypes.SetRecordFilters,
    payload: {filters},
  }),
}

export function* recordSaga() {
  yield takeLatest(recordActionTypes.GetRecords, function* getRecordsSaga(action: any): any {
    const getRecordsList = (state: any) => state.record.recordsList
    const recordsList = yield select(getRecordsList)
    let param: recordListGetParams = {
      cursorLastDocumentKey:
        recordsList != null && recordsList.length > 0 && action.payload.lastConfId
          ? recordsList[recordsList.length - 1].conference.confId
          : '',
      limit: 10,
    }
    try {
      if (action.payload.filterKey !== '' && action.payload.filterValue !== '') {
        if (action.payload.filterKey === 'duration') action.payload.filterValue *= 60000
        param = {
          ...param,
          filterKey: action.payload.filterKey,
          filterValue: action.payload.filterValue,
          sortingKey: action.payload.sortingKey,
          sortingValue: action.payload.sortingValue,
          type: action.payload.type,
          operator: action.payload.operator,
        }
      }
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraGetRecords,
        {
          params: param,
        }
      )
      let resultLength = true
      if (!response.data.results.length) resultLength = false
      let recordsArray: RecordsModel[] = response.data.results
      yield put(recordActions.setRecords(recordsArray, action.payload.concat, resultLength))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(
    recordActionTypes.GetRecordingMeta,
    function* getRecordMetaSaga(action: any): any {
      try {
        yield put(recordActions.loadRecords(action.payload.record))
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraGetRecordingMeta,
          {
            params: {
              confId: action.payload.confId,
            },
          }
        )
        let updatedRecord: RecordsModel = response.data
        yield put(recordActions.updateRecords(updatedRecord))
        if (action.payload.callBack) action.payload.callBack(updatedRecord.url)
      } catch (err) {
        console.error(err)
      }
    }
  )

  yield takeLatest(recordActionTypes.DeleteRecord, function* deleteRecordSaga(action: any): any {
    try {
      yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraDeleteRecord,
        {
          confId: action.payload.confId,
        }
      )
      yield put(recordActions.getRecords())
    } catch (err) {
      console.error(err)
    }
  })
}
