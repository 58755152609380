/* eslint-disable react/jsx-no-target-blank */
// import React from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import {RoleCheckMin} from '../../../../app/helpers/RoleRestriction'
import {AccountUserWrapperModel} from '../../../../app/modules/auth/models/AccountUserWrapperModel'
import {Roles} from '../../../../app/modules/auth/models/RolesEnum'
import {RootState} from '../../../../setup'
// import { KTSVG } from '../../../helpers'
// import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Firestore</span>
        </div>
      </div>
      <AsideMenuItem
        to='/users/'
        title={intl.formatMessage({id: 'MENU.USERS'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/communication/com005.svg'
      />
      <AsideMenuItem
        to='/moderation'
        title={intl.formatMessage({id: 'MENU.MODERATION'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen004.svg'
      />
      {RoleCheckMin(Roles.Admin, admin.account.role) && (
        <AsideMenuItem
          to='/deviceblacklist'
          title={intl.formatMessage({id: 'MENU.DEVICEBLACKLIST'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen050.svg'
        />
      )}
      <AsideMenuItem
        to='/frequencies'
        title={intl.formatMessage({id: 'MENU.FREQUENCIES'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/electronics/elc007.svg'
      />
      {RoleCheckMin(Roles.Admin, admin.account.role) && (
        <AsideMenuItem
          to='/records'
          title={intl.formatMessage({id: 'MENU.RECORDS'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/social/soc007.svg'
        />
      )}
      {RoleCheckMin(Roles.Admin, admin.account.role) && (
        <AsideMenuItem
          to='/team'
          title={intl.formatMessage({id: 'MENU.TEAM'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/coding/cod002.svg'
        />
      )}
      {RoleCheckMin(Roles.Admin, admin.account.role) && (
        <AsideMenuItem
          to='/profanities'
          title={intl.formatMessage({id: 'MENU.PROFANITIES'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen034.svg'
        />
      )}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
