/* eslint-disable jsx-a11y/anchor-is-valid */

import {useIntl} from 'react-intl'

const Frequencies: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='align-items-center'>
        <p className='fw-bolder fs-2 text-dark m-0 pb-10 text-center '>
          {intl.formatMessage({id: 'COMINGSOON'})}
        </p>
      </div>
    </>
  )
}

export {Frequencies}
