import React from 'react'
import {useIntl} from 'react-intl'
type Props = {
  loading: Boolean
  div?: boolean
}

const BlockUI: React.FC<Props> = ({loading, div}) => {
  const intl = useIntl()

  if (!loading && !div) {
    return null
  } else if (loading && div) {
    return (
      <div className=' text-center mt-10 mb-10'>
        <span className='indicator-progress' style={{display: 'block'}}>
          {intl.formatMessage({id: 'LOADER'})}{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      </div>
    )
  } else {
    return (
      <div className='blockui w-60 w-md-60 '>
        <button type='button' className='btn btn-light btn-sm w-200px'>
          <span className='spinner-border text-primary'></span>
        </button>
      </div>
    )
  }
}

export default BlockUI
