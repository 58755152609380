import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {call, put, takeEvery} from 'redux-saga/effects'
import axios from 'axios'
import {ApiRequestManager} from '../../../logic/ApiRequestManager'
import {UserWrapperModel} from '../../../modules/auth/models/AccountUserWrapperModel'
export interface DashboardActionWithPayload<T> extends Action {
  payload?: T
}

export interface dashboardListGetParams {
  limit: number
  filterKey?: string
  filterValue?: string
  sortingKey?: string
  sortingValue?: string
  type?: string
  operator?: string
}

export const dashboardActionTypes = {
  DashboardCountBy: '[DashboardCountBy] Action',
  SetData: '[SetData] Action',
  TopUsers: '[TopUsers] Action',
  SetTopUsers: '[SetTopUsers] Action',
}

const initialDashboardState: IDashboardState = {
  userSuspended: null,
  userBanned: null,
  lastModifiedUsers: null,
  bounties: null,
  topUsersFriendsList: null,
  topUsersAchievementsList: null,
}

export interface IDashboardState {
  userSuspended: number | null
  userBanned: number | null
  lastModifiedUsers: number | null
  bounties: Object | null
  topUsersFriendsList: UserWrapperModel[] | null
  topUsersAchievementsList: UserWrapperModel[] | null
}

export const dashboardReducer = persistReducer(
  {storage, key: 'v100-intra-dashboard', whitelist: ['dashboard']},
  (state: IDashboardState = initialDashboardState, action: DashboardActionWithPayload<any>) => {
    switch (action.type) {
      case dashboardActionTypes.SetData: {
        const data = action.payload.data
        const dataLabel = action.payload.dataLabel
        const ts = action.payload.ts
        if (dataLabel === 'user') {
          return {
            ...state,
            userSuspended: data?.SUSPENDED.total,
            userBanned: data?.BANNED.total,
          }
        }
        if (dataLabel === 'lastModifiedUsers') {
          return {
            ...state,
            lastModifiedUsers: data[ts].total,
          }
        }
        if (dataLabel === 'bounties') {
          if (!state.bounties)
            return {
              ...state,
              bounties: {[Object.keys(data).toString()]: data[Object.keys(data).toString()].total},
            }
          else {
            return {
              ...state,
              bounties: {
                ...state.bounties,
                [Object.keys(data).toString()]: data[Object.keys(data).toString()].total,
              },
            }
          }
        } else {
          return {...state}
        }
      }
      case dashboardActionTypes.SetTopUsers: {
        const {usersList} = action.payload
        const {resultLength} = action.payload
        const {dataLabel} = action.payload
        if (dataLabel === 'friends')
          return {...state, topUsersFriendsList: usersList, result: resultLength}
        if (dataLabel === 'achievements')
          return {...state, topUsersAchievementsList: usersList, result: resultLength}
        else {
          return {...state}
        }
      }

      default:
        return state
    }
  }
)

export const dashboardActions = {
  dashboardCountBy: (
    countByKey: any,
    countByValues: any,
    type: string,
    operator: string,
    dataLabel?: string,
    sortingKey?: string,
    sortingValue?: string
  ) => ({
    type: dashboardActionTypes.DashboardCountBy,
    payload: {countByKey, countByValues, type, operator, dataLabel, sortingKey, sortingValue},
  }),
  setData: (data: any, dataLabel: string, ts?: number) => ({
    type: dashboardActionTypes.SetData,
    payload: {data, dataLabel, ts},
  }),
  topUsers: (
    filterKey?: string,
    filterValue?: string,
    sortingKey?: string,
    sortingValue?: string,
    type?: string,
    operator?: string,
    dataLabel?: string
  ) => ({
    type: dashboardActionTypes.TopUsers,
    payload: {filterKey, filterValue, sortingKey, sortingValue, type, operator, dataLabel},
  }),
  setTopUsers: (usersList: UserWrapperModel[], resultLength?: boolean, dataLabel?: string) => ({
    type: dashboardActionTypes.SetTopUsers,
    payload: {usersList, resultLength, dataLabel},
  }),
}

export function* dashboardSaga() {
  yield takeEvery(dashboardActionTypes.DashboardCountBy, function* countBy(action: any): any {
    try {
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraCountBy,
        {
          params: {
            countByKey: action.payload.countByKey,
            countByValues: action.payload.countByValues,
            type: action.payload.type,
            operator: action.payload.operator,
            dataLabel: action.payload.dataLabel,
            sortingKey: action.payload.sortingKey,
            sortingValue: action.payload.sortingValue,
            ignoreDetailedIndexes: true,
          },
        }
      )
      if (response.data)
        yield put(
          dashboardActions.setData(
            response.data.detail,
            action.payload.dataLabel,
            action.payload.countByValues
          )
        )
    } catch (err) {
      console.error(err)
    }
  })
  yield takeEvery(dashboardActionTypes.TopUsers, function* topUsersSaga(action: any): any {
    let param: dashboardListGetParams = {
      limit: 10,
    }
    try {
      if (action.payload.filterKey !== '' && action.payload.filterValue !== '') {
        param = {
          ...param,
          filterKey: action.payload.filterKey,
          filterValue: action.payload.filterValue,
          sortingKey: action.payload.sortingKey,
          sortingValue: action.payload.sortingValue,
          type: action.payload.type,
          operator: action.payload.operator,
        }
      }
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUsersList,
        {
          params: param,
        }
      )
      let resultLength = true
      if (!response.data.results.length) resultLength = false
      let userArray: UserWrapperModel[] = response.data.results

      yield put(dashboardActions.setTopUsers(userArray, resultLength, action.payload.dataLabel))
    } catch (err) {
      console.error(err)
    }
  })
}
