export function getFlagEmoji(countryCode: any) {
  if (countryCode === 'en') countryCode = 'en-US'

  const codePoints = countryCode
    .slice(-2)
    .toUpperCase()
    .split('')
    .map((char: any) => 127397 + char.charCodeAt())
  return String.fromCodePoint(...codePoints)
}
