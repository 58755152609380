/* eslint-disable jsx-a11y/anchor-is-valid */
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Typeahead} from 'react-bootstrap-typeahead'
import {filterCase, specificFilterCase} from '../../../helpers/FilterCase'
import {selectCase, selectCaseOperator} from '../../../helpers/SelectCase'
import * as users from '../../users/redux/UserRedux'
import {createRef, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {RootState} from '../../../../setup'
import {FiltersModel} from '../../users/models/FiltersModel'
import {BountiesFilterBar} from './BountiesFilterBar'
import {KTSVG} from '../../../../_metronic/helpers'
export const FilterBar = (props: any) => {
  const currentFilters = useSelector<RootState>(
    ({users}) => users.currentFilters,
    shallowEqual
  ) as FiltersModel
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const {filters, setFilters, setLoading} = props
  const ref: any = createRef()
  const bountieNameRef: any = createRef()

  const intl = useIntl()
  let findType = selectCase.find((o) => o.key === filters.filterKey)
  let calendarOption = findType?.option
  const [term, setTerm] = useState<string>('')
  const [bountiesFilters, setBountiesFilters] = useState<any>({
    filterLabel: '',
    name: '',
    unlockedBy: '',
    keyType: 'key',
    value: '',
  })

  useEffect(() => {
    if (currentFilters !== null || undefined)
      setFilters({
        filterKey: currentFilters.filterKey,
        filterValue: currentFilters.filterValue,
        filterOperator: currentFilters.filterOperator,
        filterType: currentFilters.filterType,
        sortingOrder: currentFilters.sortingOrder,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFilters({...filters, filterType: findType?.type})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.filterKey])

  function handleClear() {
    setFilters({
      filterKey: '',
      filterValue: '',
      filterOperator: '==',
      filterType: '',
      sortingOrder: 'ASC',
    })
  }

  function typeaheadClear() {
    if (ref.current) ref.current.clear()

    if (ref.bountieNameRef) ref.bountieNameref.clear()
  }

  function handleSearch() {
    dispatch(users.userActions.searchUsers(term))
    setLoading(true)
    handleClear()
  }

  function handleKeypress(e: any) {
    if (e.key === 'Enter') handleSearch()
  }

  return (
    <div className='d-flex flex-wrap ms-10' style={{justifyContent: 'space-between'}}>
      <div className='d-flex flex-wrap'>
        <div className='d-flex align-items-center position-relative me-5 mt-2'>
          <span className='svg-icon svg-icon-1 position-absolute ms-6'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2x svg-icon'
            />
          </span>
          <input
            className='form-control form-control-solid w-250px ps-14 '
            placeholder='Search user'
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            onKeyPress={handleKeypress}
          />
        </div>
        {term && (
          <>
            <button
              className={'btn btn-sm btn-light-info me-5 mt-2'}
              onClick={() => {
                handleSearch()
              }}
            >
              {intl.formatMessage({id: 'FILTER.SEARCH'})}
            </button>
            <button
              className={'btn btn-sm btn-light-danger mt-2'}
              onClick={() => {
                dispatch(users.userActions.getUsers(''))
                setLoading(true)
                setTerm('')
                handleClear()
              }}
            >
              {intl.formatMessage({id: 'FILTER.CLEAR'})}
            </button>
          </>
        )}
      </div>
      <button
        type='button'
        className={'btn btn-sm w-150px btn-light-info me-20 mt-2'}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'FILTER.KEY'})}
        <span className='svg-icon svg-icon-5 rotate-180 ms-3 me-0'>
          <KTSVG
            path='/media/icons/duotune/general/gen031.svg'
            className='svg-icon-2x svg-icon-danger'
          />
        </span>
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-bold fs-7 w-300px py-4'
        data-kt-menu='true'
      >
        <div className='d-flex align-items-center mt-3'>
          <span className='ms-6'>{intl.formatMessage({id: 'FILTER.KEY'})} :</span>
          <select
            className='btn btn-sm w-150px btn-light-info ms-6'
            value={filters.filterKey}
            onChange={(e) => {
              typeaheadClear()
              setFilters({
                ...filters,
                filterKey: e.target.value,
                filterValue: '',
                filterOperator: '==',
              })
              dispatch(
                users.userActions.setBountiesFilters({...bountiesFilters, name: '', unlockedBy: ''})
              )
              setBountiesFilters({...bountiesFilters, name: '', unlockedBy: ''})
            }}
          >
            <option value=''>{intl.formatMessage({id: 'FILTER.SELECT'})}</option>
            {selectCase.map((option) => (
              <option value={option.key} key={option.key}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          {filters.filterType === 'number' && (
            <div className='d-flex align-items-center mt-3'>
              <span className='ms-6'>{intl.formatMessage({id: 'FILTER.OPERATOR'})} :</span>

              <select
                className='btn btn-sm w-150px btn-light-info ms-6'
                value={filters.filterOperator}
                onChange={(e) => {
                  typeaheadClear()
                  setFilters({...filters, filterOperator: e.target.value, filterValue: ''})
                }}
              >
                {selectCaseOperator.map((option) => (
                  <option value={option.key} key={option.key}>
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
          )}
          {filters.filterKey !== '' && calendarOption !== 'calendar' ? (
            filters.filterKey in filterCase ? (
              <div className='d-flex align-items-center justify-content-start mt-3'>
                <span className='ms-6'>{intl.formatMessage({id: 'FILTER.VALUE'})} :</span>
                <Typeahead
                  id=''
                  defaultInputValue={filters.filterValue}
                  ref={ref}
                  onChange={(e) => {
                    setFilters({...filters, filterValue: e[0]})
                  }}
                  options={filterCase[filters.filterKey]}
                  inputProps={{
                    className: 'ms-6 mw-150px form-control form-control-sm form-control-solid',
                  }}
                />
              </div>
            ) : filters.filterKey in specificFilterCase ? (
              <>
                <BountiesFilterBar
                  filters={filters}
                  setFilters={setFilters}
                  setLoading={setLoading}
                  bountiesFilters={bountiesFilters}
                  setBountiesFilters={setBountiesFilters}
                  parentsRef={ref}
                  bountieNameRef={bountieNameRef}
                />
              </>
            ) : (
              <>
                <div className='d-flex align-items-center text-start  mt-3'>
                  <span className='ms-6'>{intl.formatMessage({id: 'FILTER.VALUE'})} :</span>
                  <input
                    className='ms-6 form-control form-control-sm form-control-solid mw-100px'
                    value={filters.filterValue}
                    onChange={(e) => {
                      setFilters({...filters, filterValue: e.target.value})
                    }}
                  />
                </div>
              </>
            )
          ) : (
            calendarOption === 'calendar' && (
              <div className='d-flex align-items-center mt-3'>
                <span className='ms-6'>{intl.formatMessage({id: 'FILTER.VALUE'})} :</span>
                <div className='ms-6'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date) => {
                      setStartDate(date)
                      setFilters({...filters, filterValue: Math.floor(date.getTime())})
                    }}
                  />
                </div>
              </div>
            )
          )}
          {!(filters.filterKey in specificFilterCase) && filters.filterValue !== '' && (
            <div className='d-flex align-items-center mt-3'>
              <span className='ms-6'>{intl.formatMessage({id: 'FILTER.ORDER'})} :</span>
              <select
                className='btn btn-sm w-150px btn-light-info ms-6'
                onChange={(e) => {
                  setFilters({...filters, filterOperator: e.target.value})
                }}
              >
                <option value='ASC'> {intl.formatMessage({id: 'FILTER.ASC'})}</option>
                <option value='DESC'> {intl.formatMessage({id: 'FILTER.DESC'})}</option>
              </select>
            </div>
          )}
        </div>
        <div className='d-flex ms-10 me-10' style={{justifyContent: 'space-between'}}>
          {filters.sortingOrder !== '' && filters.filterValue !== '' && (
            <>
              <div className='d-flex align-items-center mt-3'>
                <button
                  className={'btn btn-sm btn-light-info'}
                  onClick={() => {
                    dispatch(
                      users.userActions.getUsers(
                        null,
                        filters.filterKey,
                        filters.filterValue,
                        filters.sortingOrder,
                        filters.filterType,
                        filters.filterOperator
                      )
                    )
                    dispatch(users.userActions.setFilters(filters))
                    setTerm('')
                    setLoading(true)
                  }}
                  data-kt-menu-dismiss='true'
                >
                  {intl.formatMessage({id: 'FILTER.SEARCH'})}
                </button>
              </div>
            </>
          )}
          <button
            className={'btn btn-sm btn-light-danger mt-3'}
            onClick={() => {
              dispatch(users.userActions.getUsers(''))
              setTerm('')
              handleClear()
            }}
          >
            {intl.formatMessage({id: 'FILTER.CLEAR'})}
          </button>
        </div>
      </div>
    </div>
  )
}
