import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {useIntl} from 'react-intl'
import * as users from '../../../users/redux/UserRedux'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {UserWrapperModel} from '../../../auth/models/AccountUserWrapperModel'
import {RootState} from '../../../../../setup'

type Props = {
  userData: UserWrapperModel
}
const UserHive: React.FC<Props> = ({userData}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const currentUser = useSelector<RootState>(
    ({users}) => users.currentUser,
    shallowEqual
  ) as UserWrapperModel

  const loader = useSelector<RootState>(
    ({users}) => users.monitoringLoader,
    shallowEqual
  ) as boolean

  let currentMonitoringStatus = currentUser.user.moderation?.monitoringStatus

  return (
    <div>
      <div className='mt-10 mb-20 d-flex justify-content-center'>
        {loader ? (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        ) : (
          <button
            onClick={() =>
              dispatch(
                users.userActions.setMonitoringStatus(
                  userData.user.uid,
                  !currentMonitoringStatus ? 'HIVE_RECORDING' : '',
                  currentUser
                )
              )
            }
            className={'btn btn-' + (currentMonitoringStatus ? 'danger' : 'success')}
          >
            {!currentMonitoringStatus
              ? intl.formatMessage({id: 'HIVE.ENABLE'})
              : intl.formatMessage({id: 'HIVE.DISABLE'})}
          </button>
        )}
      </div>
    </div>
  )
}
export default UserHive
