import {Modal, ModalFooter} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {roleKey} from '../../helpers/provider/RoleProvider'
import {AccountUserWrapperModel} from '../../modules/auth/models/AccountUserWrapperModel'
import {useDispatch} from 'react-redux'
import * as accounts from './redux/AccountRedux'
import {useState} from 'react'

type Props = {
  show: any
  setShow: any
  account: AccountUserWrapperModel
}

const ChangeRoleModal: React.FC<Props> = ({show, setShow, account}) => {
  const dispatch = useDispatch()
  const [role, setRole] = useState('')

  const intl = useIntl()
  const handleClose = () => setShow(false)

  function handleSave() {
    if (role) {
      dispatch(accounts.accountActions.changeAccountRole(role, account))
    }
    handleClose()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        scrollable
        size='lg'
        style={{top: '20%'}}
      >
        <Modal.Body>
          <div className='d-flex flex-column mt-10 h-100px'>
            <span className=' d-block fs-3 mb-2'>
              {intl.formatMessage({id: 'TEAM.EDIT'})}
              <p className='text-dark fw-bolder d-block fs-2 mb-2'>
                {'  ' + account.account.email + ' (' + account.account.role + ')'}
              </p>
            </span>
            <div>
              <span className='text-dark fw-bolder d-block fs-6 mb-2'></span>
              <select
                name='edit'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm w-200px me-10 mb-10'
                onChange={(e) => {
                  setRole(e.target.value)
                }}
              >
                <option value=''></option>
                {Object.keys(roleKey).map(
                  (role, index) =>
                    role !== account.account.role && (
                      <option key={index} value={role}>
                        {role}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>
        </Modal.Body>
        <ModalFooter style={{justifyContent: 'space-between'}}>
          <button className='btn btn-light-danger' onClick={() => setShow(false)}>
            {intl.formatMessage({id: 'TEAM.CANCEL'})}
          </button>
          <button className=' btn btn-light-success' onClick={() => handleSave()}>
            {intl.formatMessage({id: 'TEAM.SAVE'})}
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ChangeRoleModal
