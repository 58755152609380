import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {call, put, takeLatest, select} from 'redux-saga/effects'
import axios from 'axios'
import {ApiRequestManager} from '../../../logic/ApiRequestManager'
export interface ProfanitiyActionWithPayload<T> extends Action {
  payload?: T
}

export interface profanitiyListGetParams {}

export const profanitiyActionTypes = {
  GetLanguages: '[GetLanguages] Action',
  SetLanguages: '[SetLanguages] Action',
  GetProfanitiesList: '[GetProfanitiesList] Action',
  SetProfanitiesList: '[SetProfanitiesList] Action',
  RemoveProfanity: '[RemoveProfanity] Action',
  AddProfanity: '[AddProfanity] Action',
  SetLoading: '[SetLoading] Action',
}

const initialProfanitiyState: IProfanitiyState = {
  profanitiesList: {},
  languagesList: {},
  loading: false,
}

export interface IProfanitiyState {
  profanitiesList: any
  languagesList: any
  loading: boolean
}

export const profanityReducer = persistReducer(
  {storage, key: 'v100-intra-profanities', whitelist: ['profanities']},
  (state: IProfanitiyState = initialProfanitiyState, action: ProfanitiyActionWithPayload<any>) => {
    switch (action.type) {
      case profanitiyActionTypes.SetProfanitiesList: {
        const {profanitiesList} = action.payload
        const {resultLength} = action.payload
        const {languageCode} = action.payload

        const updatedProfanitiesList = {...state.profanitiesList}

        updatedProfanitiesList[languageCode] = profanitiesList

        return {...state, profanitiesList: updatedProfanitiesList, result: resultLength}
      }
      case profanitiyActionTypes.SetLanguages: {
        const {languagesList} = action.payload

        return {...state, languagesList: languagesList}
      }

      case profanitiyActionTypes.SetLoading: {
        const {loading} = action.payload
        return {...state, loading: loading}
      }
      default:
        return state
    }
  }
)

export const profanitiyActions = {
  getLanguages: () => ({type: profanitiyActionTypes.GetLanguages}),
  getProfanities: (languageCode: string) => ({
    type: profanitiyActionTypes.GetProfanitiesList,
    payload: {languageCode},
  }),
  setProfanities: (profanitiesList: any, resultLengh: any, languageCode: string) => ({
    type: profanitiyActionTypes.SetProfanitiesList,
    payload: {profanitiesList, resultLengh, languageCode},
  }),
  setLanguages: (languagesList: any) => ({
    type: profanitiyActionTypes.SetLanguages,
    payload: {languagesList},
  }),
  removeProfanity: (languageCode: string, profanityKey: string) => ({
    type: profanitiyActionTypes.RemoveProfanity,
    payload: {languageCode, profanityKey},
  }),
  addProfanity: (languageCode: string, profanityKey: string) => ({
    type: profanitiyActionTypes.AddProfanity,
    payload: {languageCode, profanityKey},
  }),
  setLoading: (loading: boolean) => ({
    type: profanitiyActionTypes.SetLoading,
    payload: {loading},
  }),
}

export function* profanitiesSaga() {
  yield takeLatest(
    profanitiyActionTypes.GetProfanitiesList,
    function* getProfanitiesSaga(action: any): any {
      try {
        yield put(profanitiyActions.setLoading(true))
        const response = yield call(
          axios.post,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraGetProfanitiesList,
          {
            languageCode: action.payload.languageCode,
          }
        )
        let resultLength = true
        if (!response.data.results.length) resultLength = false
        let profanitiesList: any = response.data.results
        yield put(
          profanitiyActions.setProfanities(
            profanitiesList,
            resultLength,
            action.payload.languageCode
          )
        )
        yield put(profanitiyActions.setLoading(false))
      } catch (err) {
        console.error(err)
      }
    }
  )
  yield takeLatest(profanitiyActionTypes.GetLanguages, function* getLanguages(action: any): any {
    try {
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraGetLanguages
      )
      let languagesList: any = response.data.results
      yield put(profanitiyActions.setLanguages(languagesList))
    } catch (err) {
      console.error(err)
    }
  })
  yield takeLatest(
    profanitiyActionTypes.RemoveProfanity,
    function* removeProfanity(action: any): any {
      try {
        yield put(profanitiyActions.setLoading(true))
        const response = yield call(
          axios.post,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraRemoveProfanity,
          {
            languageCode: action.payload.languageCode,
            profanityKey: action.payload.profanityKey,
          }
        )
        yield put(profanitiyActions.getProfanities(action.payload.languageCode))
      } catch (err) {
        console.error(err)
      }
    }
  )
  yield takeLatest(profanitiyActionTypes.AddProfanity, function* addProfanity(action: any): any {
    try {
      yield put(profanitiyActions.setLoading(true))
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAddProfanity,
        {
          languageCode: action.payload.languageCode,
          profanityKey: action.payload.profanityKey,
        }
      )
      yield put(profanitiyActions.getProfanities(action.payload.languageCode))
    } catch (err) {
      console.error(err)
    }
  })
}
