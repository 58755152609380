export interface HexColorProvider {
  [key: string]: string
}

export const hex: HexColorProvider = {
  // https://isotropic.co/tool/hex-color-to-css-filter/ hex converter link
  '#FFD008':
    'invert(79%) sepia(73%) saturate(747%) hue-rotate(349deg) brightness(100%) contrast(103%)',
  '#FF8A65':
    'invert(70%) sepia(44%) saturate(2011%) hue-rotate(317deg) brightness(102%) contrast(101%)',
  '#DCE775':
    'invert(96%) sepia(15%) saturate(1381%) hue-rotate(9deg) brightness(102%) contrast(81%)',
  '#AED581':
    'invert(91%) sepia(31%) saturate(550%) hue-rotate(29deg) brightness(88%) contrast(89%)',
  '#81C784':
    'invert(78%) sepia(12%) saturate(1165%) hue-rotate(73deg) brightness(92%) contrast(86%)',
  '#4DB6AC':
    'invert(59%) sepia(79%) saturate(272%) hue-rotate(125deg) brightness(92%) contrast(90%)',
  '#4FC3F7':
    'invert(68%) sepia(79%) saturate(1463%) hue-rotate(169deg) brightness(102%) contrast(94%)',
  '#8C97D3':
    'invert(78%) sepia(11%) saturate(6260%) hue-rotate(192deg) brightness(89%) contrast(84%)',
  '#A489D4':
    'invert(64%) sepia(34%) saturate(554%) hue-rotate(218deg) brightness(87%) contrast(91%)',
  '#C47ED0':
    'invert(66%) sepia(24%) saturate(809%) hue-rotate(243deg) brightness(83%) contrast(95%)',
  '#F279A2':
    'invert(68%) sepia(44%) saturate(2230%) hue-rotate(298deg) brightness(101%) contrast(90%)',
  '#E57373':
    'invert(72%) sepia(7%) saturate(5751%) hue-rotate(311deg) brightness(97%) contrast(84%)',
  '#E0E0E0':
    'invert(100%) sepia(67%) saturate(682%) hue-rotate(189deg) brightness(116%) contrast(76%)',
  '#90A4AE':
    'invert(73%) sepia(12%) saturate(401%) hue-rotate(155deg) brightness(86%) contrast(87%)',
  '#D3DBDF':
    'invert(89%) sepia(5%) saturate(255%) hue-rotate(156deg) brightness(99%) contrast(90%)',
  '#0073A5':
    'invert(20%) sepia(99%) saturate(2210%) hue-rotate(180deg) brightness(103%) contrast(101%)',
  '#CFE8F6':
    'invert(91%) sepia(9%) saturate(483%) hue-rotate(163deg) brightness(97%) contrast(98%)',
  '#593951':
    'invert(22%) sepia(8%) saturate(2869%) hue-rotate(263deg) brightness(89%) contrast(80%)',
  '#4B5B47':
    'invert(32%) sepia(27%) saturate(324%) hue-rotate(62deg) brightness(93%) contrast(90%)',
  '#283A49':
    'invert(18%) sepia(14%) saturate(1561%) hue-rotate(165deg) brightness(93%) contrast(85%)',
  '#575D66':
    'invert(35%) sepia(10%) saturate(465%) hue-rotate(177deg) brightness(98%) contrast(90%)',
  '#EA3349':
    'invert(34%) sepia(51%) saturate(6896%) hue-rotate(337deg) brightness(97%) contrast(89%)',
  '#8C5746':
    'invert(35%) sepia(20%) saturate(1093%) hue-rotate(328deg) brightness(98%) contrast(85%)',
  '#FFD1AE':
    'invert(100%) sepia(31%) saturate(4362%) hue-rotate(298deg) brightness(100%) contrast(118%)',
  '#0EAF0E':
    'invert(40%) sepia(70%) saturate(2771%) hue-rotate(93deg) brightness(106%) contrast(89%)',
  '#C1AEFC':
    'invert(69%) sepia(7%) saturate(2004%) hue-rotate(212deg) brightness(104%) contrast(98%)',
  '#00C7A3':
    'invert(79%) sepia(62%) saturate(4483%) hue-rotate(122deg) brightness(89%) contrast(101%)',
  '#9B66F5':
    'invert(42%) sepia(89%) saturate(470%) hue-rotate(220deg) brightness(97%) contrast(97%)',
  '#78CEFF':
    'invert(72%) sepia(38%) saturate(1108%) hue-rotate(177deg) brightness(105%) contrast(103%)',
  '#D159E4':
    'invert(69%) sepia(58%) saturate(5635%) hue-rotate(247deg) brightness(88%) contrast(103%)',
  '#FF7A5C':
    'invert(60%) sepia(56%) saturate(1340%) hue-rotate(321deg) brightness(100%) contrast(102%)',
  '#F4A043':
    'invert(74%) sepia(92%) saturate(1182%) hue-rotate(327deg) brightness(99%) contrast(93%)',
  '#A3AE76':
    'invert(71%) sepia(18%) saturate(597%) hue-rotate(31deg) brightness(92%) contrast(88%)',
  '#CE6F29':
    'invert(45%) sepia(39%) saturate(1610%) hue-rotate(352deg) brightness(97%) contrast(76%)',
  '#FFFF5C':
    'invert(95%) sepia(25%) saturate(4382%) hue-rotate(318deg) brightness(126%) contrast(105%)',
  '#EA60B8':
    'invert(73%) sepia(47%) saturate(6796%) hue-rotate(291deg) brightness(99%) contrast(85%)',
  '#BC1515':
    'invert(16%) sepia(44%) saturate(5658%) hue-rotate(349deg) brightness(98%) contrast(102%)',
  '#B5F583':
    'invert(85%) sepia(42%) saturate(456%) hue-rotate(39deg) brightness(102%) contrast(92%)',
  '#AEAEFF':
    'invert(76%) sepia(77%) saturate(4067%) hue-rotate(206deg) brightness(115%) contrast(110%)',
  '#71A4FF':
    'invert(60%) sepia(33%) saturate(1566%) hue-rotate(193deg) brightness(101%) contrast(102%)',
  '#C0D8C0': 'invert(95%) sepia(9%) saturate(511%) hue-rotate(64deg) brightness(91%) contrast(86%)',
  '#8A26C4':
    'invert(17%) sepia(68%) saturate(4391%) hue-rotate(273deg) brightness(93%) contrast(92%)',
  '#B9EDDD':
    'invert(84%) sepia(25%) saturate(284%) hue-rotate(107deg) brightness(108%) contrast(86%)',
  '#FEA21A':
    'invert(75%) sepia(32%) saturate(3609%) hue-rotate(345deg) brightness(101%) contrast(99%)',
  '#FFEA11':
    'invert(92%) sepia(48%) saturate(3462%) hue-rotate(341deg) brightness(101%) contrast(101%)',
}
