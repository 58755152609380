import {bundleKeys} from './BountiesProvider'

export interface BundleColorInterface {
  [key: string]: string
}

export let ProviderShellAvatarColors: BundleColorInterface = {
  '#FEA21A': bundleKeys.NEW_YEAR_2024,
  '#FFEA11': bundleKeys.SKI_CLUB_2024,
  '#B9EDDD': bundleKeys.CHRISTMAS_2023,
  '#FFD008': 'free',
  '#FFFF5C': bundleKeys.TEARS_OF_THE_WALKIE,
  '#DCE775': 'free',
  '#B5F583': bundleKeys.PRIDE_MONTH_2023,
  '#C0D8C0': bundleKeys.BACK_TO_SCHOOL_2023,
  '#AED581': 'free',
  '#A3AE76': bundleKeys.MAY_THE_4TH,
  '#81C784': 'free',
  '#0EAF0E': bundleKeys.GREAT_TALKY_BROS,
  '#00C7A3': 'free',
  '#CFE8F6': bundleKeys.STRANGER_TALKIES_2022,
  '#78CEFF': 'free',
  '#71A4FF': bundleKeys.AQUATIC_SUMMER_2023,
  '#8C97D3': 'free',
  '#C1AEFC': bundleKeys.EASTER_2023,
  '#AEAEFF': bundleKeys.AQUATIC_SUMMER_2023,
  '#9B66F5': 'free',
  '#8A26C4': bundleKeys.HALLOWEEN_2023,
  '#D159E4': 'free',
  '#EA60B8': bundleKeys.SPIDER_TALKIE,
  '#FF8A65': 'free',
  '#F279A2': 'free',
  '#E57373': 'free',
  '#8C5746': bundleKeys.CHRISTMAS_2022,
  '#BC1515': bundleKeys.SPIDER_TALKIE,
  '#EA3349': bundleKeys.CHRISTMAS_2022,
  '#FF7A5C': 'free',
  '#CE6F29': bundleKeys.TEARS_OF_THE_WALKIE,
  '#F4A043': bundleKeys.MAY_THE_4TH,
  '#FFD1AE': bundleKeys.CHINESE_NEW_YEAR_2023,
  '#90A4AE': 'free',
  '#575D66': bundleKeys.STRANGER_TALKIES_2022,
  '#283A49': bundleKeys.STRANGER_TALKIES_2022,
  '#593951': bundleKeys.STRANGER_TALKIES_2022,
  '#4B5B47': bundleKeys.STRANGER_TALKIES_2022,
  '#0073A5': 'SPECIAL',
}

// TODO: Implement theme selector instead of background colors for custom themes
export let ProviderBkgdAvatarColors: BundleColorInterface = {
  '#FFD008': 'free',
  '#DCE775': 'free',
  '#AED581': 'free',
  '#81C784': 'free',
  '#00C7A3': 'free',
  '#78CEFF': 'free',
  '#8C97D3': 'free',
  '#9B66F5': 'free',
  '#D159E4': 'free',
  '#F279A2': 'free',
  '#E57373': 'free',
  '#FF7A5C': 'free',
  '#E0E0E0': 'free',
  '#90A4AE': 'free',
}

export interface OpacInterface {
  [key: string]: string
}

export let ProviderOpacAvatarColors: OpacInterface = {
  // primary // light
  '#FFD008': '#FFF6CE',
  '#DCE775': '#F1F5C8',
  '#AED581': '#DFEECD',
  '#81C784': '#CDE9CE',
  '#8C97D3': '#D1D5ED',
  '#F279A2': '#FAC9DA',
  '#E57373': '#F5C7C7',
  '#E0E0E0': '#F2F2F2',
  '#90A4AE': '#D3DBDF',
  '#78CEFF': '#B3E3FF',
  '#00C7A3': '#8DF6E3',
  '#9B66F5': '#D1CAFF',
  '#D159E4': '#ECC1F0',
  '#FF7A5C': '#FFD5CC',

  '#E9A9AB': '#F6DDDD',
  '#F68A00': '#FBD099',
  '#FF4040': '#FFB3B3',
  '#CBF1CE': '#EAF9EB',
  '#76839B': '#C8CDD7',
  '#5C7251': '#BEC7B9',
  '#41AA7D': '#B3DDCB',
  '#FFC0D2': '#FFE6ED',
  '#82E2FD': '#CDF3FE',
  '#C5FFF0': '#E8FFF9',
  '#5082BC': '#B9CDE4',
  '#CAF4FF': '#E6FAFF',
  '#B78CE5': '#E2D1F5',
  '#FFE98D': '#FFF6D1',
  '#7FE5C0': '#CCF5E6',
  '#FFDB94': '#FFF1D4',
  '#E2BBF5': '#F3E4FB',
  '#F2EADB': '#FAF7F1',
  '#777DFF': '#C9CBFF',
  '#FF9E99': '#FFD8D6',
  '#FFA75F': '#FFDCBF',
  '#FFC5C4': '#FFE8E7',
  '#0D82BC': '#9ECDE4',
}
