import {getAuth} from 'firebase/auth'

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      if (getAuth().currentUser != null) {
        return getAuth()
          .currentUser?.getIdToken()
          .then((idToken) => {
            config.headers.Authorization = `Bearer ${idToken}`
            return config
          })
      } else {
        return config
      }
    },
    (err: any) => {
      console.error(err)
      Promise.reject(err)
    }
  )
}
