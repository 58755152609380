function cleanUpExpression(element: string) {
  let characterWl =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$+-*/=%"\'#@&_(),.;:?!\\|{}<>[]`^~©'
  element = element.padEnd(3, '8')
  for (let i = 0; i < element.length; i++) {
    if (!characterWl.includes(element.charAt(i))) {
      element = element.substring(0, i) + '8' + element.substring(i + 1)
    }
  }
  return element
}

export default cleanUpExpression
