import {FC, useRef, useEffect, useState} from 'react'
import {connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {getAuth} from 'firebase/auth'
import {useIntl} from 'react-intl'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [loading, setLoading] = useState(true)
  // We should request user by authToken before rendering the application

  useEffect(() => {
    const requestUser = async () => {
      setLoading(true)
      try {
        if (!didRequest.current) {
          const {data: user} = await getUserByToken()
          dispatch(props.fulfillUser(user))
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
        setLoading(false)
      }

      return () => (didRequest.current = true)
    }

    getAuth().onAuthStateChanged((user) => {
      if (user != null) {
        requestUser()
      } else {
        dispatch(props.logout())
        setShowSplashScreen(false)
        setLoading(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !loading ? (
    showSplashScreen ? (
      <LayoutSplashScreen />
    ) : (
      <>{props.children}</>
    )
  ) : (
    <span
      className='indicator-progress'
      style={{
        display: 'block',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    >
      {intl.formatMessage({id: 'LOADER'})}{' '}
      <span className='spinner-border spinner-border align-middle ms-1'></span>
    </span>
  )
}

export default connector(AuthInit)
