import {AccountUserWrapperModel} from '../models/AccountUserWrapperModel'
import {FirebaseHelper} from '../../../logic/FirebaseHelper'
import {ApiRequestManager} from '../../../logic/ApiRequestManager'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

export async function login(email: string, password: string) {
  let isWhitelisted: boolean = await ApiRequestManager.Instance.isUserWhitelisted(email)
  if (isWhitelisted) {
    await FirebaseHelper.login(email, password)
    return
  }
  return Promise.reject({email: 'Not whitelisted'})
}

export async function getUserByToken() {
  let getUser: AccountUserWrapperModel = await ApiRequestManager.Instance.getMyUser()
  if (!('uid' in getUser.account) || !getUser.account.uid)
    console.error('Failed to retrieve current user’s account data')
  return {data: getUser}
}
