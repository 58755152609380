import axios from 'axios'
import {AccountUserWrapperModel} from '../modules/auth/models/AccountUserWrapperModel'
export class ApiRequestManager {
  private static _instance: ApiRequestManager
  public static readonly apiUrlRoute: string =
    'https://api' +
    (process.env.REACT_APP_INTRA_ENV === 'staging' ? '-staging' : '') +
    '.walkie-talkie.io'
  // Add flag to package.json for staging or prod env and change url endpoint accordingly
  // NODE_ENV=developement yarn build
  // NODE_ENV=production yarn build
  public static readonly apiIntraUsersList: string = '/intra/users/list'
  public static readonly apiIntraUserSearch: string = '/intra/users/search'
  public static readonly apiIntraUserUpdate: string = '/intra/users/update'
  public static readonly apiIntraDeleteAccount: string = '/intra/users/delete'
  public static readonly apiIntraCountBy: string = '/intra/users/countBy'
  public static readonly apiIntraInvalidateUserAttribute: string = '/intra/moderation/invalidate'
  public static readonly apiIntraUserIgnore: string = '/intra/moderation/ignore'
  public static readonly apiIntraUserSuspend: string = '/intra/moderation/suspend'
  public static readonly apiIntraUserUnSuspend: string = '/intra/moderation/unsuspend'
  public static readonly apiIntraUserBan: string = '/intra/moderation/ban'
  public static readonly apiIntraUserUnBan: string = '/intra/moderation/unban'
  public static readonly apiIntraUserKick: string = '/intra/moderation/kick'
  public static readonly apiIntraUsersGetRoute: string = '/intra/users'
  public static readonly apiIntraUsersRemoveFriends: string = '/intra/users/removeFriends'
  public static readonly apiIntraBountiesAcquire: string = '/intra/bounties/acquire'
  public static readonly apiIntraBountiesRemove: string = '/intra/bounties/remove'
  public static readonly apiIntraAchievementsAcquire: string = '/intra/achievements/progress'
  public static readonly apiIntraAchievementsReset: string = '/intra/achievements/reset'
  public static readonly apiIntraGetRecords: string = '/intra/recordings/list'
  public static readonly apiIntraGetRecordingMeta: string = '/intra/recordings/getMeta'
  public static readonly apiIntraDeleteRecord: string = '/intra/recordings/delete'
  public static readonly apiIntraInviteAccount: string = '/intra/accounts/invite'
  public static readonly apiIntraAccountsList: string = '/intra/accounts/list'
  public static readonly apiIntraDeactivateAdminAccount: string = '/intra/accounts/deactivate'
  public static readonly apiIntraActivateAdminAccount: string = '/intra/accounts/activate'
  public static readonly apiIntraDeviceList: string = '/intra/devices/list'
  public static readonly apiIntraGetDevice: string = '/intra/devices/get'
  public static readonly apiIntraChangeAccountRole: string = '/intra/accounts/role'
  public static readonly apiIntraGetParticipantFrequency: string =
    '/intra/frequencies/getParticipantFrequency'
  public static readonly apiIntraAgoraGetParticipantFrequency: string =
    '/intra/agora/getParticipantFrequency'
  public static readonly apiIntraSetHiveMonitoring: string = '/intra/hive/setMonitoringStatus'
  public static readonly apiIntraRestorePurchase = '/intra/purchases/restore'
  public static readonly apiIntraGetProfanitiesList = '/intra/profanities/list'
  public static readonly apiIntraGetLanguages = '/intra/profanities/getLanguages'
  public static readonly apiIntraAddProfanity = '/intra/profanities/add'
  public static readonly apiIntraRemoveProfanity = '/intra/profanities/remove'
  public static readonly apiIntraTestFcmToken = '/intra/fcmToken/testFcmToken'
  private static readonly apiIntraAccountWhitelistedEndpoint: string =
    '/intra/accounts/isWhitelisted'
  private static readonly apiIntraAccountMeEndpoint: string = '/intra/accounts/me'
  private currentAccount = {} as AccountUserWrapperModel

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  public isUserWhitelisted(email: string) {
    return axios
      .post(
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAccountWhitelistedEndpoint,
        {
          email: email,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
        }
      )
      .then((result) => {
        return true
      })
      .catch((error) => {
        console.error(error)
        return false
      })
  }

  public async getMyUser() {
    return axios
      .get(ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAccountMeEndpoint, {
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      })
      .then((result) => {
        this.currentAccount.account = {
          uid: result.data.account.uid,
          email: result.data.account.email,
          role: result.data.account.role,
          status: result.data.account.status,
        }
        this.currentAccount.user = {
          nickname: result.data.user.nickname,
          uid: result.data.account.uid,
          nicknameId: result.data.user.nicknameId,
          email: result.data.account.email,
          avatarLayers: result.data.user.avatarLayers,
          wtUserUid: result.data.user.uid,
        }
        return this.currentAccount
      })
      .catch((error) => {
        console.error(error)
        return {} as AccountUserWrapperModel
      })
  }
}
