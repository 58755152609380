export interface SelectCaseProvider {
  key: string
  value: string
  type: string
  option: string
}
export interface SelectCaseOperator {
  key: string
  value: string
}

export const selectCase: SelectCaseProvider[] = [
  {key: 'uid', value: 'UID', type: 'string', option: 'input'},
  {key: 'localeCode', value: 'Locale Code', type: 'string', option: 'input'},
  {key: 'moderation.flag', value: 'Moderation Flag', type: 'string', option: 'input'},
  {key: 'status', value: 'Status', type: 'string', option: 'input'},
  {key: 'email', value: 'Email', type: 'string', option: 'input'},
  {key: 'nickname', value: 'Nickname', type: 'string', option: 'input'},
  {key: 'nicknameId', value: 'Nickname Id', type: 'string', option: 'input'},
  {key: 'phone.countryCode', value: 'Phone Country Code', type: 'number', option: 'input'},
  {
    key: 'phone.internationalNumber',
    value: 'International Number',
    type: 'number',
    option: 'input',
  },
  {
    key: 'metaData.notifiedContacts.status',
    value: 'Notified Contacts Status',
    type: 'boolean',
    option: 'input',
  },
  {key: 'profile.languageCode', value: 'Language Code', type: 'string', option: 'input'},
  {key: 'profile.age', value: 'Age', type: 'number', option: 'input'},
  {key: 'metaData.createdTimestamp', value: 'Date of creation', type: 'number', option: 'calendar'},
  {
    key: 'metaData.lastModifiedTimestamp',
    value: 'Last Modified',
    type: 'number',
    option: 'calendar',
  },
  {key: 'bounties', value: 'Bounties', type: 'string', option: 'input'},
  {key: 'achievements', value: 'Achievements', type: 'string', option: 'input'},
]

export const selectCaseOperator: SelectCaseOperator[] = [
  {key: '==', value: '=='},
  {key: '!=', value: '!='},
  {key: '>', value: '>'},
  {key: '>=', value: '>='},
  {key: '<', value: '<'},
  {key: '<=', value: '<='},
  {key: 'in', value: 'in'},
  {key: 'not-in', value: 'not-in'},
  {key: 'array-contains', value: 'array-contains'},
  {key: 'array-contains-any', value: 'array-contains-any'},
]

export const bountiesCase: SelectCaseOperator[] = [
  {key: 'EASTER_2022', value: 'EASTER_2022'},
  {key: 'HALLOWEEN_2021', value: 'HALLOWEEN_2021'},
  {key: 'CHRISTMAS_2021', value: 'CHRISTMAS_2021'},
  {key: 'LOVE_2022', value: 'LOVE_2022'},
  {key: 'WT_PLUS', value: 'WT_PLUS'},
  {key: 'COLOR_PICKER', value: 'COLOR_PICKER'},
  {key: 'STRANGER_TALKIES_2022', value: 'STRANGER_TALKIES_2022'},
  {key: 'HALLOWEEN_2022', value: 'HALLOWEEN_2022'},
  {key: 'CHRISTMAS_2022', value: 'CHRISTMAS_2022'},
  {key: 'CHINESE_NEW_YEAR_2023', value: 'CHINESE_NEW_YEAR_2023'},
  {key: 'VALENTINES_2023', value: 'VALENTINES_2023'},
  {key: 'GREAT_TALKY_BROS', value: 'GREAT_TALKY_BROS'},
  {key: 'EASTER_2023', value: 'EASTER_2023'},
  {key: 'MAY_THE_4TH', value: 'MAY_THE_4TH'},
  {key: 'TEARS_OF_THE_WALKIE', value: 'TEARS_OF_THE_WALKIE'},
  {key: 'CHRISTMAS_2023', value: 'CHRISTMAS_2023'},
  {key: 'NEW_YEAR_2024', value: 'NEW_YEAR_2024'},
  {key: 'SKI_CLUB_2024', value: 'SKI_CLUB_2024'},
  {key: 'CHINESE_NEW_YEAR_2024', value: 'CHINESE_NEW_YEAR_2024'},
  {key: 'VALENTINES_2024', value: 'VALENTINES_2024'},
  {key: 'SUPERBOWL_2024', value: 'SUPERBOWL_2024'},
]

export const selectDeviceCase: SelectCaseProvider[] = [
  {key: 'sanction.moderatorId', value: 'Moderator ID', type: 'string', option: 'input'},
  {key: 'sanction.type', value: 'Type', type: 'string', option: 'input'},
  {key: 'sanction.uid', value: 'Uid', type: 'string', option: 'input'},
  {key: 'udid', value: 'Udid', type: 'string', option: 'input'},
  {key: 'platform', value: 'Platform', type: 'string', option: 'input'},
  {key: 'ts', value: 'Timestamp', type: 'number', option: 'calendar'},
]

export const selectRecordCase: SelectCaseProvider[] = [
  {key: 'conference.confId', value: 'ConfId', type: 'string', option: 'input'},
  {key: 'conference.confAlias', value: 'Host', type: 'string', option: 'input'},
  {key: 'duration', value: 'Duration (mins)', type: 'number', option: 'input'},
  {key: 'ts', value: 'Date', type: 'number', option: 'calendar'},
]

export const selectAccountCase: SelectCaseProvider[] = [
  {key: 'uid', value: 'Uid', type: 'string', option: 'input'},
  {key: 'email', value: 'Email', type: 'string', option: 'input'},
  {key: 'role', value: 'Role', type: 'string', option: 'input'},
  {key: 'wtUserUid', value: 'Wt User Uid', type: 'string', option: 'input'},
]
