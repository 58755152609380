/* eslint-disable jsx-a11y/anchor-is-valid */

import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {RecordsModel} from './models/RecordModel'
import * as record from '../dashboard/redux/RecordRedux'
import {timestampConverter} from '../../helpers/converter/Common'
import {toast, ToastContainer} from 'react-toastify'
import {portionTime} from '../../helpers/PortionTime'
import {hostsConverter} from '../../helpers/provider/ProviderRecordHosts'
import swal from 'sweetalert'
import {RecordFilters} from './components/RecordFilters'
import {FiltersModel} from '../../modules/users/models/FiltersModel'

const Records: React.FC = () => {
  const intl = useIntl()
  const recordList = useSelector<RootState>(
    ({record}) => record.recordsList,
    shallowEqual
  ) as RecordsModel[]
  const currentFilters = useSelector<RootState>(
    ({record}) => record.recordFilters,
    shallowEqual
  ) as FiltersModel
  const result = useSelector<RootState>(({record}) => record.result, shallowEqual) as RecordsModel[]
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [noMoreResult, setNoMoreResult] = useState(false)

  let recordUrl: any
  if (window.location.pathname.length > 40)
    recordUrl = window.location.pathname.replace('/records/', '')

  const [filters, setFilters] = useState({
    filterKey: '',
    filterValue: '',
    filterOperator: '==',
    filterType: '',
    sortingOrder: 'ASC',
  })

  useEffect(() => {
    if (recordList === null || recordList.length === 0)
      dispatch(
        record.recordActions.getRecords(
          null,
          false,
          'ts',
          String(Date.now()),
          'DESC',
          'number',
          '<',
          'ts'
        )
      )
    if (currentFilters !== null)
      setFilters({
        filterKey: currentFilters.filterKey,
        filterValue: currentFilters.filterValue,
        filterOperator: currentFilters.filterOperator,
        filterType: currentFilters.filterType,
        sortingOrder: currentFilters.sortingOrder,
      })

    if (recordUrl)
      dispatch(
        record.recordActions.getRecords(
          null,
          false,
          'conference.confId',
          recordUrl,
          filters.sortingOrder,
          filters.filterType,
          filters.filterOperator
        )
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!result) {
      setLoadMore(false)
      setNoMoreResult(true)
    }
  }, [result])

  useEffect(() => {
    if (recordList.length > 0) {
      setLoading(false)
      setLoadMore(false)
      setNoMoreResult(false)
    }
  }, [recordList])

  const handleUpdate = function (url: string) {
    window.open(url)
  }

  function handleDelete(recordId: string) {
    dispatch(record.recordActions.deleteRecords(recordId))
    setLoading(true)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {intl.formatMessage({id: 'MENU.RECORDS'})}
            </span>
          </h3>
        </div>
        <div className='me-10'>
          <RecordFilters filters={filters} setFilters={setFilters} setLoading={setLoading} />
        </div>
        {!result && recordList.length === 0 ? (
          <div className=' text-center mt-10 mb-10'>
            <span> {intl.formatMessage({id: 'FILTER.NORESULT'})}</span>
          </div>
        ) : loading ? (
          <div className=' text-center mt-10 mb-10'>
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'LOADER'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 card-body py-5'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th>{intl.formatMessage({id: 'RECORD.HOST'})}</th>
                    <th>Conf Alias</th>
                    <th>Conf Id</th>
                    <th>{intl.formatMessage({id: 'RECORD.DURATION'})}</th>
                    <th>Region</th>
                    <th> {intl.formatMessage({id: 'RECORD.SIZE'})}</th>
                    <th> {intl.formatMessage({id: 'RECORD.TIMESTAMP'})}</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {recordList.map((recordItem, index) => (
                    <tr key={index}>
                      <th className='text-dark fw-bolder'>
                        {hostsConverter[recordItem.conference.confAlias]}
                      </th>
                      <th>
                        <div className='d-flex'>
                          <span
                            className=' w-100px overflow-hidden'
                            style={{
                              textOverflow: 'ellipsis',
                              lineHeight: '40px',
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            {recordItem.conference.confAlias}
                          </span>
                          <span
                            className='fa fa-copy btn btn-icon'
                            style={{verticalAlign: 'top'}}
                            onClick={() =>
                              navigator.clipboard
                                .writeText(recordItem.conference.confAlias)
                                .then(() =>
                                  toast.info(intl.formatMessage({id: 'TEXTCOPIED'}), {
                                    position: 'bottom-right',
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                  })
                                )
                            }
                          >
                            <ToastContainer />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className='d-flex'>
                          <span
                            className=' w-100px overflow-hidden'
                            style={{
                              textOverflow: 'ellipsis',
                              lineHeight: '40px',
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            {recordItem.conference.confId}
                          </span>
                          <span
                            className='fa fa-copy btn btn-icon'
                            style={{verticalAlign: 'top'}}
                            onClick={() =>
                              navigator.clipboard.writeText(recordItem.conference.confId).then(() =>
                                toast.info(intl.formatMessage({id: 'TEXTCOPIED'}), {
                                  position: 'bottom-right',
                                  autoClose: 2000,
                                  hideProgressBar: true,
                                })
                              )
                            }
                          >
                            <ToastContainer />
                          </span>
                        </div>
                      </th>
                      <th>{portionTime(recordItem.duration / 1000)}</th>
                      <th>{recordItem.region}</th>
                      <th>{recordItem.size ? Math.round(recordItem.size / 1000) + ' KB' : ''}</th>
                      <th>{timestampConverter(recordItem.ts)}</th>
                      <th>
                        <div className='d-flex'>
                          {recordItem.isLoading ? (
                            <button className='btn btn-light btn-sm'>
                              <span className='spinner-border text-primary'></span>
                            </button>
                          ) : (
                            <button
                              className='btn btn-sm btn-success btn-active-light-primary'
                              disabled={recordItem.size ? false : true}
                              onClick={() => {
                                dispatch(
                                  record.recordActions.getRecordingMeta(
                                    recordItem.conference.confId,
                                    handleUpdate,
                                    recordItem
                                  )
                                )
                              }}
                            >
                              <span className='fa fa-download fs-4 btn-icon' />
                            </button>
                          )}
                          <button
                            className='btn btn-sm btn-info btn-active-light-primary ms-2'
                            onClick={() =>
                              window.open(
                                'https://dashboard.dolby.io/dashboard/applications/361885/interactivity-apis/monitor/' +
                                  recordItem.conference.confId,
                                '_blank'
                              )
                            }
                          >
                            Monitor API
                          </button>
                          <button
                            className='btn btn-sm btn-primary ms-2'
                            onClick={() =>
                              navigator.clipboard
                                .writeText(
                                  window.location.host + '/records/' + recordItem.conference.confId
                                )
                                .then(() =>
                                  toast.info(intl.formatMessage({id: 'TEXTCOPIED'}), {
                                    position: 'bottom-right',
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                  })
                                )
                            }
                          >
                            URL
                          </button>
                          <button
                            className='btn btn-sm btn-danger ms-2'
                            onClick={() => {
                              swal({
                                title:
                                  `${recordItem.conference.confId} ` +
                                  intl.formatMessage({id: 'ALERT.WILLDELETE'}),
                                text: intl.formatMessage({id: 'ALERT.SECURITY.RECORD'}),
                                buttons: [
                                  intl.formatMessage({id: 'ALERT.CANCEL'}),
                                  intl.formatMessage({id: 'ALERT.DELETE'}),
                                ],
                                icon: 'warning',
                                content: {
                                  element: 'input',
                                  attributes: {
                                    placeholder: `${recordItem.conference.confId}`,
                                    type: 'text',
                                  },
                                },
                              }).then((recordIdValidation) => {
                                if (recordIdValidation) {
                                  if (recordIdValidation === recordItem.conference.confId) {
                                    swal(intl.formatMessage({id: 'ALERT.DELETING.RECORD'}), {
                                      icon: 'success',
                                    })
                                    handleDelete(recordItem.conference.confId)
                                  } else {
                                    swal(intl.formatMessage({id: 'ALERT.WRONGCONFID'}), {
                                      icon: 'error',
                                    })
                                  }
                                } else {
                                  swal(intl.formatMessage({id: 'ALERT.STILLEXISTING.RECORD'}), {
                                    icon: 'error',
                                  })
                                }
                              })
                            }}
                          >
                            <span className='fas fa-trash btn-icon'></span>
                          </button>
                        </div>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className='btn-custom align-items-center text-center mb-5'>
          {recordList.length % 10 !== 0 ? (
            ''
          ) : recordList.length === 0 ? (
            ''
          ) : (
            <div>
              {noMoreResult ? (
                <p> {intl.formatMessage({id: 'FILTER.NORESULT'})}</p>
              ) : loadMore ? (
                <button
                  type='button'
                  className='btn btn-sm btn-info btn-active-light-primary w-100px'
                >
                  <span className='spinner-border spinner-border-sm text-light'></span>
                </button>
              ) : (
                <button
                  className='btn btn-sm btn-info btn-active-light-primary w-100px'
                  onClick={() => {
                    dispatch(
                      record.recordActions.getRecords(
                        recordList[recordList.length - 1].conference.confId,
                        true,
                        filters.filterKey,
                        filters.filterValue,
                        filters.sortingOrder,
                        filters.filterType,
                        filters.filterOperator
                      )
                    )
                    setLoadMore(true)
                  }}
                >
                  {intl.formatMessage({id: 'LOADMORE'})}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export {Records}
