import {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {DeviceBlackList} from '../pages/dashboard/DeviceBlackList'
import {ModerationList} from '../pages/dashboard/ModerationList'
import {Frequencies} from '../pages/dashboard/Frequencies'
import UserPage from '../modules/profile/components/userpage/UserPage'
import {GetUsersList} from '../modules/profile/components/GetUsersList'
import {Records} from '../pages/dashboard/Records'
import {Team} from '../pages/dashboard/Team'
import {RoleCheckMin} from '../helpers/RoleRestriction'
import {Roles} from '../modules/auth/models/RolesEnum'
import {AccountUserWrapperModel} from '../modules/auth/models/AccountUserWrapperModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import {Profanities} from '../pages/dashboard/Profanities'

export function PrivateRoutes() {
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        {RoleCheckMin(Roles.Admin, admin.account.role) && (
          <Route path='/deviceblacklist' component={DeviceBlackList} />
        )}
        {RoleCheckMin(Roles.Admin, admin.account.role) && (
          <Route path='/profanities' component={Profanities} />
        )}
        <Route path='/frequencies' component={Frequencies} />
        <Route path='/moderation' component={ModerationList} />
        {RoleCheckMin(Roles.Admin, admin.account.role) && (
          <Route path='/records' component={Records} />
        )}
        {RoleCheckMin(Roles.Admin, admin.account.role) && <Route path='/team' component={Team} />}
        <Route exact path='/users/:id' component={UserPage} />
        <Route exact path='/users/' component={GetUsersList} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
