import React, {useEffect, useRef, useState} from 'react'
import {FriendsCard} from '../../../../../_metronic/partials/content/cards/FriendsCard'
import {useIntl} from 'react-intl'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../../auth/models/AccountUserWrapperModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as users from '../../../users/redux/UserRedux'
import {RootState} from '../../../../../setup'
import {UsersFriendsModel} from '../../../users/models/UsersFriendsModel'
import {RoleCheckMin} from '../../../../helpers/RoleRestriction'
import {Roles} from '../../../auth/models/RolesEnum'
import swal from 'sweetalert'

type Props = {
  userData: UserWrapperModel
}
const UserFriends: React.FC<Props> = ({userData}) => {
  const user = userData.user

  const intl = useIntl()
  const dispatch = useDispatch()

  const containerRef = useRef<HTMLDivElement[]>([])

  const [option, setOption] = useState<any>('friendsApproved')
  const [update, setUpdated] = useState(false)
  const [remove, setRemove] = useState(false)
  const [uidList, setUidList] = useState<any>([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  let friendsUserList = useSelector<RootState>(
    ({users}) => users.friendsUserList,
    shallowEqual
  ) as UserWrapperModel[]
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel

  const handleChange = (e: any) => {
    setOption(e.target.value)
    setRemove(false)
    setUidList([])
    setIsAllSelected(false)
  }
  const options = {
    root: null, // default is the viewport
    threshold: 0, // percentage of target's visible area. Triggers "onIntersection"
  }
  const callbackFunction = (entries: any) => {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        let currentFriend = user.friends[option as keyof UsersFriendsModel].find(
          (el) => el.uid === entry.target.id
        )
        if (currentFriend && !currentFriend.isLoading) {
          GetFriendProfile(currentFriend.uid)
          setUpdated(true)
        }
      }
    })
  }
  function GetFriendProfile(friendUid: any) {
    let uidArray: any = []
    user.friends[option as keyof UsersFriendsModel].forEach((item) => {
      if (
        (item.uid === friendUid || (uidArray.length > 0 && uidArray.length < 30)) &&
        !item.profile &&
        !item.isLoading
      ) {
        item.isLoading = true
        uidArray.push(item.uid)
      }
    })
    dispatch(users.userActions.getMultipleUsers(uidArray.join(',')))
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)

    if (containerRef.current) {
      containerRef.current.forEach((item) => observer.observe(item))
    }
    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        containerRef.current.forEach((item) => observer.unobserve(item))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, options])

  useEffect(() => {
    friendsUserList.forEach((item) => {
      let profile = item.user.profile
      let friendToUpdate = user.friends[option as keyof UsersFriendsModel].find(
        (el) => el.uid === item.user.uid
      )
      if (friendToUpdate) {
        friendToUpdate.profile = profile
        friendToUpdate.nickname = item.user.nickname
        friendToUpdate.nicknameId = item.user.nicknameId
      }
      setUpdated(!update)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendsUserList])

  function selectUidToRemove(uid: string) {
    const checkIfUidAlreadyExist = uidList.find((element: any) => element === uid)

    if (checkIfUidAlreadyExist) setUidList(uidList.filter((e: any) => e !== uid))
    // will return ['A', 'C']
    else setUidList([...uidList, uid])
  }

  function handleCancel() {
    setRemove(false)
    setUidList([])
  }
  function handleRemove() {
    let clearAll = false
    if (uidList.length === user.friends[option as keyof UsersFriendsModel].length) clearAll = true
    dispatch(
      users.userActions.removeFriends(user.uid, uidList.toString(), option, userData, clearAll)
    )
    setRemove(!remove)
  }
  let friendUidList: string[] = []

  function selectAll() {
    if (!isAllSelected) {
      user.friends[option as keyof UsersFriendsModel].forEach((data) =>
        friendUidList.push(data.uid)
      )
      setUidList(friendUidList)
    }
    if (isAllSelected) {
      setUidList([])
    }
  }
  return (
    <div>
      <div className='d-flex justify-content-between my-2 me-5 ms-5'>
        <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select form-select-sm w-125px'
          defaultValue='Sanctions'
          onChange={(e) => handleChange(e)}
        >
          <option value='friendsApproved'>
            {intl.formatMessage({id: 'FRIENDS.APPROVED'})} ({user.friends.friendsApproved.length})
          </option>
          <option value='friendsPendingValidation'>
            {intl.formatMessage({id: 'FRIENDS.PENDING'})} (
            {user.friends.friendsPendingValidation.length})
          </option>
          <option value='friendsRequest'>
            {intl.formatMessage({id: 'FRIENDS.REQUEST'})} ({user.friends.friendsRequest.length})
          </option>
        </select>
        {user.isLoading && (
          <div
            className='position-absolute'
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              zIndex: 10,
              width: '100%',
              height: '100%',
            }}
          >
            <span
              className='spinner-border text-primary'
              style={{
                display: 'block',
                margin: 'auto',
                marginTop: '5%',
              }}
            ></span>
          </div>
        )}
        {RoleCheckMin(Roles.Admin, admin.account.role) && (
          <div>
            {remove ? (
              <div className='d-flex'>
                <div>
                  {uidList.length > 0 && (
                    <span
                      className='btn btn-icon btn-danger  ms-5'
                      onClick={() => {
                        swal({
                          title:
                            `${uidList.length} ` +
                            intl.formatMessage({id: 'ALERT.WILLDELETE.FRIENDS'}) +
                            `(${user.nicknameId}) `,
                          buttons: [
                            intl.formatMessage({id: 'ALERT.CANCEL'}),
                            intl.formatMessage({id: 'ALERT.DELETE'}),
                          ],
                          icon: 'warning',
                        }).then((nicknameValidation) => {
                          if (nicknameValidation) {
                            handleRemove()
                            swal(intl.formatMessage({id: 'ALERT.DELETING.FRIENDS'}), {
                              icon: 'success',
                            })
                            handleRemove()
                          } else {
                            swal(intl.formatMessage({id: 'ALERT.STILLEXISTING.FRIENDS'}), {
                              icon: 'error',
                            })
                          }
                        })
                      }}
                    >
                      <i className='fas fa-trash fs-4'></i>
                    </span>
                  )}
                </div>
                <div>
                  <span className='btn btn-icon btn-secondary ms-5' onClick={() => handleCancel()}>
                    <i className='fas fa-times fs-4'></i>
                  </span>
                </div>
                <button
                  className='btn btn-icon btn-info ms-5 '
                  onClick={() => {
                    selectAll()
                    setIsAllSelected(!isAllSelected)
                  }}
                >
                  {intl.formatMessage({id: 'ALL'})}
                </button>
              </div>
            ) : (
              <button className={'btn btn-danger'} onClick={() => setRemove(!remove)}>
                {intl.formatMessage({id: 'USER.USERPAGE.REMOVEFRIENDS'})}
              </button>
            )}
          </div>
        )}
      </div>
      <div></div>
      {option === 'friendsApproved' &&
        (user.friends.friendsApproved.length === 0 ? (
          <div className=''>
            <div className='fs-1 mb-20 text-center fw-bolder'>
              {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
            </div>
          </div>
        ) : (
          <>
            <div className='row g-6 mb-6 g-xl-9 mb-xl-9 '>
              {user.friends.friendsApproved.map((friend, friendIndex) => (
                <>
                  <div
                    className='d-flex flex-column col-md-6 col-xxl-4 '
                    ref={(el) => el && (containerRef.current[friendIndex] = el)}
                    id={friend.uid}
                  >
                    {remove && (
                      <div className='text-center'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={uidList.find((element: any) => element === friend.uid)}
                          onChange={() => selectUidToRemove(friend.uid)}
                        ></input>
                      </div>
                    )}
                    <FriendsCard
                      avatar={friend.profile?.avatarLayers ? friend.profile.avatarLayers : null}
                      name={friend.nickname + friend.nicknameId}
                      uid={friend.uid}
                    />
                  </div>
                </>
              ))}
            </div>
          </>
        ))}
      {option === 'friendsPendingValidation' &&
        (user.friends.friendsPendingValidation.length === 0 ? (
          <div className='fs-1 mb-20 text-center fw-bolder'>
            {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
          </div>
        ) : (
          <>
            <div className='row g-6 mb-6 g-xl-9 mb-xl-9 '>
              {user.friends.friendsPendingValidation.map((friend, friendIndex) => (
                <div
                  className='col-md-6 col-xxl-4'
                  ref={(el) => el && (containerRef.current[friendIndex] = el)}
                  id={friend.uid}
                >
                  {remove && (
                    <div className='text-center'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={uidList.find((element: any) => element === friend.uid)}
                        onChange={() => selectUidToRemove(friend.uid)}
                      ></input>
                    </div>
                  )}
                  <FriendsCard
                    avatar={friend.profile?.avatarLayers ? friend.profile.avatarLayers : null}
                    name={friend.nickname + friend.nicknameId}
                    uid={friend.uid}
                  />
                </div>
              ))}
            </div>
          </>
        ))}
      {option === 'friendsRequest' &&
        (user.friends.friendsRequest.length === 0 ? (
          <div className=''>
            <div className='fs-1 mb-20 text-center fw-bolder'>
              {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
            </div>
          </div>
        ) : (
          <>
            <div className='row g-6 mb-6 g-xl-9 mb-xl-9 '>
              {user.friends.friendsRequest.map((friend, friendIndex) => (
                <div
                  className='col-md-6 col-xxl-4'
                  ref={(el) => el && (containerRef.current[friendIndex] = el)}
                  id={friend.uid}
                >
                  {remove && (
                    <div className='text-center'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={uidList.find((element: any) => element === friend.uid)}
                        onChange={() => selectUidToRemove(friend.uid)}
                      ></input>
                    </div>
                  )}
                  <FriendsCard
                    avatar={friend.profile?.avatarLayers ? friend.profile.avatarLayers : null}
                    name={friend.nickname + friend.nicknameId}
                    uid={friend.uid}
                  />
                </div>
              ))}
            </div>
          </>
        ))}
    </div>
  )
}
export default UserFriends
