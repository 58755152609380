import {Modal, ModalFooter} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as users from '../../modules/users/redux/UserRedux'
import {useState} from 'react'

type Props = {
  show: any
  setShow: any
  user: any
  fcmToken: any
}

const SendNotificationModal: React.FC<Props> = ({show, setShow, user, fcmToken}) => {
  const dispatch = useDispatch()
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationTitle, setNotificationTitle] = useState('')

  const intl = useIntl()
  const handleClose = () => setShow(false)

  function handleSave() {
    dispatch(
      users.userActions.testFcmToken(
        user.user.uid,
        [fcmToken],
        user,
        notificationMessage,
        notificationTitle
      )
    )
    handleClose()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        scrollable
        size='lg'
        style={{top: '20%'}}
      >
        <Modal.Header style={{marginLeft: 'auto', left: '0', right: '0', marginRight: 'auto'}}>
          <h1> {intl.formatMessage({id: 'FCMTOKEN.TITLE'})}</h1>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex h-100px' style={{justifyContent: 'space-between'}}>
            <div style={{width: '100%'}}>
              <span className=' d-block fs-3 mb-2'>Notification Title</span>
              <input
                style={{width: '80%'}}
                className='form-check-input'
                onChange={(e) => setNotificationTitle(e.target.value)}
              ></input>
            </div>
            <div style={{width: '100%'}}>
              <span className=' d-block fs-3 mb-2'>Notification Message</span>
              <input
                style={{width: '80%'}}
                className='form-check-input'
                onChange={(e) => setNotificationMessage(e.target.value)}
              ></input>
            </div>
            <div>
              <span className='text-dark fw-bolder d-block fs-6 mb-2'></span>
            </div>
          </div>
        </Modal.Body>
        <ModalFooter style={{justifyContent: 'space-between'}}>
          <button className='btn btn-light-danger' onClick={() => setShow(false)}>
            {intl.formatMessage({id: 'TEAM.CANCEL'})}
          </button>
          <button className=' btn btn-light-success' onClick={() => handleSave()}>
            Send
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SendNotificationModal
