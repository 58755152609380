export interface ProviderDataLogoType {
  [key: string]: string
}

export let logos: ProviderDataLogoType = {
  'google.com': '/media/logos/google.svg',
  'tiktok.com': '/media/logos/tiktok.svg',
  'facebook.com': '/media/logos/facebook.svg',
  phone: '/media/logos/phone.svg',
  'apple.com': '/media/logos/apple.svg',
  password: '/media/logos/anonymous.svg',
  anonymous: '/media/logos/anonymous.svg',
  ios: '/media/logos/ios.plateform.svg',
  android: '/media/logos/android.svg',
}
