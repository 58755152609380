import moment from 'moment'
import {useIntl} from 'react-intl'

export function DiffDate(date1: any, date2: any) {
  const intl = useIntl()

  let m = moment(date1)
  let years = m.diff(date2, 'years')
  m.add(-years, 'years')
  let months = m.diff(date2, 'months')
  m.add(-months, 'months')
  let days = m.diff(date2, 'days')

  return (
    years +
    intl.formatMessage({id: 'USER.YEARS'}) +
    months +
    intl.formatMessage({id: 'USER.MONTHS'}) +
    days +
    intl.formatMessage({id: 'USER.DAYS'})
  )
}

export function DiffTimestamp(date1: any, date2: any, intl: any) {
  let seconds = Math.floor((date2 - date1) / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  let days = Math.floor(hours / 24)

  hours = hours - days * 24
  minutes = minutes - days * 24 * 60 - hours * 60
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

  let finalDate = ''

  if (days !== 0) finalDate += days + intl.formatMessage({id: 'USER.DAYS'})
  if (hours !== 0) finalDate += hours + intl.formatMessage({id: 'USER.HOURS'})
  if (minutes !== 0) finalDate += minutes + intl.formatMessage({id: 'USER.MINS'})
  if (seconds !== 0) finalDate += seconds + intl.formatMessage({id: 'USER.SEC'})

  return finalDate
}
