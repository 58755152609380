/* eslint-disable jsx-a11y/anchor-is-valid */
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Typeahead} from 'react-bootstrap-typeahead'
import {filterCase, specificFilterCase} from '../../../helpers/FilterCase'
import {selectCaseOperator, selectRecordCase} from '../../../helpers/SelectCase'
import * as record from '../redux/RecordRedux'
import {createRef, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {RootState} from '../../../../setup'
import {FiltersModel} from '../../../modules/users/models/FiltersModel'
import {KTSVG} from '../../../../_metronic/helpers'
import {hostsConverter} from '../../../helpers/provider/ProviderRecordHosts'

export const RecordFilters = (props: any) => {
  const currentFilters = useSelector<RootState>(
    ({record}) => record.recordFilters,
    shallowEqual
  ) as FiltersModel
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const {filters, setFilters, setLoading} = props
  const recordRef: any = createRef()

  const intl = useIntl()
  let findType = selectRecordCase.find((o) => o.key === filters.filterKey)
  let calendarOption = findType?.option

  useEffect(() => {
    if (currentFilters !== null || undefined)
      setFilters({
        filterKey: currentFilters.filterKey,
        filterValue: currentFilters.filterValue,
        filterOperator: currentFilters.filterOperator,
        filterType: currentFilters.filterType,
        sortingOrder: currentFilters.sortingOrder,
        sortingKey: currentFilters.sortingKey,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFilters({...filters, filterType: findType?.type})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.filterKey])

  function handleClear() {
    setFilters({
      filterKey: '',
      filterValue: '',
      filterOperator: '==',
      filterType: '',
      sortingOrder: 'ASC',
    })
  }

  function typeaheadClear() {
    if (recordRef.current) recordRef.current.clear()

    if (recordRef.bountieNameRef) recordRef.bountieNameref.clear()
  }

  return (
    <div className='d-flex ms-10' style={{justifyContent: 'space-between'}}>
      <button
        type='button'
        className={'btn btn-sm w-150px btn-light-info'}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{marginLeft: 'auto'}}
      >
        {intl.formatMessage({id: 'FILTER.KEY'})}
        <span className='svg-icon svg-icon-5 rotate-180 ms-3 me-0'>
          <KTSVG
            path='/media/icons/duotune/general/gen031.svg'
            className='svg-icon-2x svg-icon-danger'
          />
        </span>
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-bold fs-7 w-300px py-4'
        data-kt-menu='true'
      >
        <div className='d-flex align-items-center mt-3'>
          <span className='ms-6'>{intl.formatMessage({id: 'FILTER.KEY'})} :</span>
          <select
            className='btn btn-sm w-150px btn-light-info ms-6'
            value={filters.filterKey}
            onChange={(e) => {
              typeaheadClear()
              setFilters({
                ...filters,
                filterKey: e.target.value,
                filterValue: '',
                filterOperator: '==',
              })
            }}
          >
            <option value=''>{intl.formatMessage({id: 'FILTER.SELECT'})}</option>
            {selectRecordCase.map((option) => (
              <option value={option.key} key={option.key}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          {filters.filterType === 'number' && (
            <div className='d-flex align-items-center mt-3'>
              <span className='ms-6'>{intl.formatMessage({id: 'FILTER.OPERATOR'})} :</span>

              <select
                className='btn btn-sm w-150px btn-light-info ms-6'
                value={filters.filterOperator}
                onChange={(e) => {
                  typeaheadClear()
                  setFilters({...filters, filterOperator: e.target.value, filterValue: ''})
                }}
              >
                {selectCaseOperator.map((option) => (
                  <option value={option.key} key={option.key}>
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
          )}
          {filters.filterKey !== '' && calendarOption !== 'calendar' ? (
            filters.filterKey in filterCase ? (
              <div className='d-flex align-items-center justify-content-start mt-3'>
                <span className='ms-6'>{intl.formatMessage({id: 'FILTER.VALUE'})} :</span>
                <Typeahead
                  id=''
                  defaultInputValue={filters.filterValue}
                  ref={recordRef}
                  onChange={(e) => {
                    setFilters({...filters, filterValue: e[0]})
                    if (filters.filterKey === 'conference.confAlias')
                      setFilters({
                        ...filters,
                        filterValue: Object.keys(hostsConverter).find(
                          (key) => hostsConverter[key] === e[0]
                        ),
                      })
                  }}
                  options={filterCase[filters.filterKey]}
                  inputProps={{
                    className: 'ms-6 mw-150px form-control form-control-sm form-control-solid',
                  }}
                />
              </div>
            ) : (
              <div className='d-flex align-items-center text-start  mt-3'>
                <span className='ms-6'>{intl.formatMessage({id: 'FILTER.VALUE'})} :</span>
                <input
                  className='ms-6 form-control form-control-sm form-control-solid mw-100px'
                  value={filters.filterValue}
                  onChange={(e) => {
                    setFilters({...filters, filterValue: e.target.value})
                  }}
                />
              </div>
            )
          ) : (
            calendarOption === 'calendar' && (
              <div className='d-flex align-items-center mt-3'>
                <span className='ms-6'>{intl.formatMessage({id: 'FILTER.VALUE'})} :</span>
                <div className='ms-6'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date) => {
                      setStartDate(date)
                      setFilters({...filters, filterValue: Math.floor(date.getTime())})
                    }}
                  />
                </div>
              </div>
            )
          )}
          {!(filters.filterKey in specificFilterCase) && filters.filterValue !== '' && (
            <div className='d-flex align-items-center mt-3'>
              <span className='ms-6'>{intl.formatMessage({id: 'FILTER.ORDER'})} :</span>
              <select
                className='btn btn-sm w-150px btn-light-info ms-6'
                onChange={(e) => {
                  setFilters({...filters, sortingOrder: e.target.value})
                }}
              >
                <option value='ASC'> {intl.formatMessage({id: 'FILTER.ASC'})}</option>
                <option value='DESC'> {intl.formatMessage({id: 'FILTER.DESC'})}</option>
              </select>
            </div>
          )}
        </div>
        <div className='d-flex ms-10 me-10' style={{justifyContent: 'space-between'}}>
          {filters.sortingOrder !== '' && filters.filterValue !== '' && (
            <>
              <div className='d-flex align-items-center mt-3'>
                <button
                  className={'btn btn-sm btn-light-info'}
                  onClick={() => {
                    dispatch(
                      record.recordActions.getRecords(
                        null,
                        false,
                        filters.filterKey,
                        filters.filterValue,
                        filters.sortingOrder,
                        filters.filterType,
                        filters.filterOperator
                      )
                    )
                    dispatch(record.recordActions.setRecordFilters(filters))
                    setLoading(true)
                  }}
                  data-kt-menu-dismiss='true'
                >
                  {intl.formatMessage({id: 'FILTER.SEARCH'})}
                </button>
              </div>
            </>
          )}
          <button
            className={'btn btn-sm btn-light-danger mt-3'}
            onClick={() => {
              dispatch(record.recordActions.getRecords(''))
              handleClear()
            }}
          >
            {intl.formatMessage({id: 'FILTER.CLEAR'})}
          </button>
        </div>
      </div>
    </div>
  )
}
