import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import axios from 'axios'
import {ApiRequestManager} from '../../../logic/ApiRequestManager'
import {BountiesFiltersModel, FiltersModel} from '../models/FiltersModel'
import {AvatarLayerModel} from '../models/ProfileModel'
import {AccountUserWrapperModel, UserWrapperModel} from '../../auth/models/AccountUserWrapperModel'
import {errorProvider} from '../../../helpers/provider/ErrorProvider'
import {FrequencyModel} from '../models/FrequencyModel'
export interface UserActionWithPayload<T> extends Action {
  payload?: T
}

export interface userListGetParams {
  cursorLastDocumentKey: string
  limit: number
  filterKey?: string
  filterValue?: string
  sortingValue?: string
  type?: string
  operator?: string
}
export const userActionTypes = {
  Ignore: '[Ignore] Action',
  InvalidateUserAttribute: '[InvalidateUserAttribute] Action',
  Ban: '[Ban] Action',
  Suspend: '[Suspend X day] Action',
  Unsuspend: '[Unsuspend] Action',
  Unban: '[Unban] Action',
  Kick: '[Kick] Action',
  GetUsers: '[GetUsers] Action',
  SearchUsers: '[SearchUsers] Action',
  SetUsers: '[SetUsers] Action',
  RemoveFriends: '[RemoveFriends] Action',
  GetModerationUsers: '[GetModerationUsers] Action',
  SetModerationUsers: '[SetModerationUsers] Action',
  Get1User: '[Get1User] Action',
  GetMultipleUsers: '[GetMultipleUsers] Action',
  LoadCurrentUser: '[LoadCurrentUser] Action',
  SetCurrentUser: '[SetCurrentUser] Action',
  UpdateUser: '[UpdateUser] Action',
  EditUser: '[EditUser] Action',
  LoadUser: '[LoadUser] Action',
  SetCurrentFilters: '[SetFilters] Action',
  SetCurrentBountiesFilters: '[SetCurrentBountiesFilters] Action',
  SetCurrentModerationFilters: '[SetModerationFilters] Action',
  BountiesAcquire: '[BountiesAcquire] Action',
  BountiesRemove: '[BountiesRemove] Action',
  AchievementAcquire: '[AchievementAcquire] Action',
  AchievementReset: '[AchievementReset] Action',
  DeleteAccount: '[DeleteAccount] Action',
  CountBy: '[CountBy] Action',
  SetCount: '[SetCount] Action',
  GetBlacklistedDevices: '[GetBlacklistedDevices] Action',
  GetParticipantFrequency: '[GetParticipantFrequency] Action',
  AgoraGetParticipantFrequency: '[AgoraGetParticipantFrequency] Action',
  SetParticipantFrequency: '[SetParticipantFrequency] Action',
  FindParticipantFrequency: '[FindParticipantFrequency] Action',
  SetError: '[SetError] Action',
  SetMonitoringStatus: '[SetMonitoringStatus] Action',
  SetMonitoringLoader: '[SetMonitoringLoader] Action',
  GetUserDevicesList: '[GetUserdevicesList] Action',
  SetUserDevicesList: '[SetUserDevicesList] Action',
  SetUserDevicesResult: '[SetUserDevicesResult] Action',
  RestorePurchases: '[RestorePurchases] Action',
  SetRestorePuchasesLoader: '[SetRestorePuchasesLoader] Action',
  TestFcmToken: '[TestFcmToken] Action',
}

const initialUserState: IUserState = {
  usersList: [],
  moderationUsersList: [],
  friendsUserList: [],
  devicesUserList: [],
  devicesPendingResult: [0, 0, ''],
  targetUidDevicesUserList: '',
  currentUser: null,
  searchOnly: false,
  result: true,
  currentUserResult: true,
  verificationResult: false,
  moderationResult: true,
  currentFilters: null,
  bountiesFilters: null,
  currentModerationFilters: null,
  userFrequency: null,
  error: '',
  getFrequencyLoader: false,
  monitoringLoader: false,
  currentUserLoading: false,
  purchaseLoader: false,
}

export interface IUserState {
  usersList: UserWrapperModel[]
  moderationUsersList: UserWrapperModel[]
  friendsUserList: UserWrapperModel[]
  devicesUserList: UserWrapperModel[]
  targetUidDevicesUserList: string
  devicesPendingResult: any
  currentUser: UserWrapperModel | null
  searchOnly: boolean
  result: boolean
  currentUserResult: boolean
  verificationResult: boolean
  moderationResult: boolean
  currentFilters: FiltersModel | null
  bountiesFilters: BountiesFiltersModel | null
  currentModerationFilters: FiltersModel | null
  userFrequency: FrequencyModel | null
  error: string
  getFrequencyLoader: boolean
  monitoringLoader: boolean
  currentUserLoading: string | boolean
  purchaseLoader: boolean
}

export const userReducer = persistReducer(
  {storage, key: 'v100-intra-users', whitelist: ['users']},
  (state: IUserState = initialUserState, action: UserActionWithPayload<any>) => {
    switch (action.type) {
      case userActionTypes.Ban: {
        return {...state}
      }
      case userActionTypes.Suspend: {
        return {...state}
      }
      case userActionTypes.Unsuspend: {
        return {...state}
      }
      case userActionTypes.Unban: {
        return {...state}
      }
      case userActionTypes.EditUser: {
        return {...state}
      }
      case userActionTypes.LoadCurrentUser: {
        const id = action.payload.id
        return {...state, currentUserLoading: id, currentUserResult: false}
      }
      case userActionTypes.SetCurrentUser: {
        const userWrapped = action.payload.wrappedUser
        const resultLength = action.payload.resultLength
        const newResult = !state.verificationResult
        const currentLoading = action.payload.wrappedUser
          ? action.payload.wrappedUser.user.uid
          : false
        if (userWrapped) {
          userWrapped.user.isLoading = false
        }

        return {
          ...state,
          currentUser: userWrapped,
          verificationResult: newResult,
          currentUserResult: resultLength,
          currentUserLoading: currentLoading,
        }
      }
      case userActionTypes.SearchUsers: {
        return {...state, searchOnly: true}
      }
      case userActionTypes.GetUsers: {
        return {...state, searchOnly: false}
      }
      case userActionTypes.SetCurrentFilters: {
        const {filters} = action.payload
        return {...state, currentFilters: filters}
      }
      case userActionTypes.SetCurrentBountiesFilters: {
        const {filters} = action.payload
        return {...state, bountiesFilters: filters}
      }
      case userActionTypes.SetCurrentModerationFilters: {
        const {filters} = action.payload
        return {...state, currentModerationFilters: filters}
      }
      case userActionTypes.SetUsers: {
        const {usersList} = action.payload
        const {concat} = action.payload
        const {resultLength} = action.payload
        const {userFriends} = action.payload
        let finalUserArray: UserWrapperModel[] = concat
          ? state.usersList.concat(usersList)
          : usersList

        if (userFriends) return {...state, friendsUserList: finalUserArray}

        return {...state, usersList: finalUserArray, result: resultLength}
      }
      case userActionTypes.SetModerationUsers: {
        const {usersList} = action.payload
        const {concat} = action.payload
        const {resultLength} = action.payload
        let finalUserArray: UserWrapperModel[] = concat
          ? state.moderationUsersList.concat(usersList)
          : usersList
        return {...state, moderationUsersList: finalUserArray, moderationResult: resultLength}
      }
      case userActionTypes.UpdateUser: {
        let userInfo = action.payload.wrappedUser
        const finalUserArray = [...state.usersList]
        const finalModerationUserArray = [...state.moderationUsersList]
        finalUserArray.forEach((data, i) => {
          if (data.user.uid === userInfo.user.uid) {
            finalUserArray[i] = userInfo
            finalUserArray[i].user.isLoading = false
          }
        })
        finalModerationUserArray.forEach((data, i) => {
          if (data.user.uid === userInfo.user.uid) {
            finalModerationUserArray[i] = userInfo
            finalModerationUserArray[i].user.isLoading = false
          }
        })
        if (!state.currentUser || state.currentUser?.user.uid === userInfo.user.uid) {
          state.currentUser = {...userInfo, user: {...userInfo.user, isLoading: false}}
        }
        return {
          ...state,
          usersList: finalUserArray,
          moderationUsersList: finalModerationUserArray,
          error: '',
        }
      }
      case userActionTypes.LoadUser: {
        let userInfo = action.payload.wrappedUser
        let finalUserArray = [...state.usersList]
        const finalModerationUserArray = [...state.moderationUsersList]
        finalUserArray.forEach((data, i) => {
          if (data.user.uid === userInfo.user.uid) {
            finalUserArray[i] = {...data, user: {...data.user, isLoading: true}}
          }
        })
        finalModerationUserArray.forEach((data, i) => {
          if (data.user.uid === userInfo.user.uid) {
            finalModerationUserArray[i] = {...data, user: {...data.user, isLoading: true}}
          }
        })

        if (!state.currentUser || state.currentUser?.user.uid === userInfo.user.uid) {
          state.currentUser = {...userInfo, user: {...userInfo.user, isLoading: true}}
        }
        return {
          ...state,
          usersList: finalUserArray,
          moderationUsersList: finalModerationUserArray,
          error: '',
        }
      }
      case userActionTypes.SetParticipantFrequency: {
        let frequency = action.payload.frequency
        return {
          ...state,
          userFrequency: frequency,
          getFrequencyLoader: false,
        }
      }
      case userActionTypes.FindParticipantFrequency: {
        return {...state, userFrequency: null, getFrequencyLoader: true}
      }
      case userActionTypes.SetError: {
        let error = action.payload.error
        const userWrapped = action.payload.wrappedUser
        if (userWrapped) userWrapped.user.isLoading = false
        return {...state, error: error, currentUser: userWrapped}
      }
      case userActionTypes.SetMonitoringLoader: {
        let isActive = state.monitoringLoader
        return {...state, monitoringLoader: !isActive}
      }
      case userActionTypes.SetUserDevicesList: {
        const {devicesUserList} = action.payload
        const {targetUid} = action.payload
        const {udid} = action.payload
        const reducedUser: any = []
        let result = state.devicesPendingResult

        devicesUserList.forEach((device: any, deviceIndex: any) => {
          const newObjet = {
            uid: device.user.uid,
            nickname: device.user.nickname,
            avatarLayers: device.user.profile.avatarLayers,
            udid: udid,
            platform: device.user.devices[udid].platform,
            status: device.user.status,
          }
          reducedUser.push(newObjet)
        })
        result = [result[0] + 1, result[1]]
        let finalUserDevicesArray: UserWrapperModel[] =
          state.targetUidDevicesUserList === targetUid
            ? state.devicesUserList.concat(reducedUser)
            : reducedUser
        return {
          ...state,
          devicesUserList: finalUserDevicesArray,
          targetUidDevicesUserList: targetUid,
          devicesPendingResult: result,
        }
      }
      case userActionTypes.SetUserDevicesResult: {
        let result = state.devicesPendingResult
        const {targetUid} = action.payload

        if (result[2] === targetUid || !state.targetUidDevicesUserList) {
          result = [result[0], result[1] + 1, (result[2] = targetUid)]
        } else {
          result = [0, 1, targetUid]
        }

        return {...state, devicesPendingResult: result}
      }
      case userActionTypes.SetRestorePuchasesLoader: {
        let isActive = state.purchaseLoader
        return {...state, purchaseLoader: !isActive}
      }

      default:
        return state
    }
  }
)

export const userActions = {
  ignore: (wrappedUser: UserWrapperModel, admin: AccountUserWrapperModel) => ({
    type: userActionTypes.Ignore,
    payload: {wrappedUser, admin},
  }),
  invalidateUserAttribute: (wrappedUser: UserWrapperModel, attribute: any) => ({
    type: userActionTypes.InvalidateUserAttribute,
    payload: {wrappedUser, attribute},
  }),
  ban: (
    wrappedUser: UserWrapperModel,
    admin: AccountUserWrapperModel,
    moderatorComment?: string
  ) => ({
    type: userActionTypes.Ban,
    payload: {wrappedUser, admin, moderatorComment},
  }),
  suspend: (
    wrappedUser: UserWrapperModel,
    duration: number,
    admin: AccountUserWrapperModel,
    callback?: any,
    moderatorComment?: string
  ) => ({
    type: userActionTypes.Suspend,
    payload: {wrappedUser, duration, admin, callback, moderatorComment},
  }),
  unsuspend: (wrappedUser: UserWrapperModel, admin: AccountUserWrapperModel) => ({
    type: userActionTypes.Unsuspend,
    payload: {wrappedUser, admin},
  }),
  unban: (wrappedUser: UserWrapperModel, admin: AccountUserWrapperModel) => ({
    type: userActionTypes.Unban,
    payload: {wrappedUser, admin},
  }),
  kick: (
    wrappedUser: UserWrapperModel,
    frequency: string,
    frequencyAlias?: string,
    callBack?: any
  ) => ({
    type: userActionTypes.Kick,
    payload: {wrappedUser, frequency, frequencyAlias, callBack},
  }),
  searchUsers: (term: string) => ({type: userActionTypes.SearchUsers, payload: {term}}),
  setFilters: (filters: FiltersModel) => ({
    type: userActionTypes.SetCurrentFilters,
    payload: {filters},
  }),
  setBountiesFilters: (filters: BountiesFiltersModel) => ({
    type: userActionTypes.SetCurrentBountiesFilters,
    payload: {filters},
  }),
  setModerationFilters: (filters: FiltersModel) => ({
    type: userActionTypes.SetCurrentModerationFilters,
    payload: {filters},
  }),
  getUsers: (
    lastUid?: string | null,
    filterKey?: string,
    filterValue?: string,
    sortingValue?: string,
    type?: string,
    operator?: string,
    concat?: boolean
  ) => ({
    type: userActionTypes.GetUsers,
    payload: {lastUid, filterKey, filterValue, sortingValue, type, operator, concat},
  }),
  getModerationUsers: (filterValue?: string, lastUid?: string | null, concat?: boolean) => ({
    type: userActionTypes.GetModerationUsers,
    payload: {lastUid, filterValue, concat},
  }),
  get1User: (uid: string) => ({type: userActionTypes.Get1User, payload: {uid}}),
  getMultipleUsers: (uids: string, concat?: boolean) => ({
    type: userActionTypes.GetMultipleUsers,
    payload: {uids, concat},
  }),
  setCurrentUser: (wrappedUser: UserWrapperModel | null, newResult?: boolean) => ({
    type: userActionTypes.SetCurrentUser,
    payload: {wrappedUser, newResult},
  }),
  setUsers: (
    usersList: UserWrapperModel[],
    concat?: boolean,
    resultLength?: boolean,
    userFriends?: boolean
  ) => ({
    type: userActionTypes.SetUsers,
    payload: {usersList, concat, resultLength, userFriends},
  }),
  setModerationUsers: (
    usersList: UserWrapperModel[],
    concat?: boolean,
    resultLength?: boolean
  ) => ({type: userActionTypes.SetModerationUsers, payload: {usersList, concat, resultLength}}),
  editUser: (
    wrappedUser: UserWrapperModel,
    nickname?: string | null,
    avatarLayers?: AvatarLayerModel[] | null,
    currentTheme?: string
  ) => ({
    type: userActionTypes.EditUser,
    payload: {wrappedUser, nickname, avatarLayers, currentTheme},
  }),
  removeFriends: (
    targetUid: string,
    uids: string,
    friendsType: string,
    wrappedUser: UserWrapperModel,
    clearAll: boolean
  ) => ({
    type: userActionTypes.RemoveFriends,
    payload: {targetUid, uids, friendsType, wrappedUser, clearAll},
  }),
  updateUser: (wrappedUser: UserWrapperModel) => ({
    type: userActionTypes.UpdateUser,
    payload: {wrappedUser},
  }),
  loadUser: (wrappedUser: UserWrapperModel) => ({
    type: userActionTypes.LoadUser,
    payload: {wrappedUser},
  }),
  bountiesAcquire: (
    wrappedUser: UserWrapperModel,
    uid: string,
    key: string,
    systemKey: string,
    expiration?: number | null
  ) => ({
    type: userActionTypes.BountiesAcquire,
    payload: {wrappedUser, uid, key, systemKey, expiration},
  }),
  bountiesRemove: (
    wrappedUser: UserWrapperModel,
    uid: string,
    key: string,
    unlockedByKey: string,
    unlockedByType: string
  ) => ({
    type: userActionTypes.BountiesRemove,
    payload: {wrappedUser, uid, key, unlockedByKey, unlockedByType},
  }),
  achievementAcquire: (
    wrappedUser: UserWrapperModel,
    uid: string,
    key: string,
    type: string,
    progress?: number | null
  ) => ({
    type: userActionTypes.AchievementAcquire,
    payload: {wrappedUser, uid, key, type, progress},
  }),

  achievementReset: (wrappedUser: UserWrapperModel, uid: string, key: string, type: string) => ({
    type: userActionTypes.AchievementReset,
    payload: {wrappedUser, uid, key, type},
  }),

  deleteAccount: (wrappedUser: UserWrapperModel, uid: string) => ({
    type: userActionTypes.DeleteAccount,
    payload: {wrappedUser, uid},
  }),
  countBy: (
    uid: string,
    countByKey: string,
    countByValues: string,
    type: string,
    callBack: any,
    wrappedUser: UserWrapperModel,
    ignoreDetailedIndexes: boolean
  ) => ({
    type: userActionTypes.CountBy,
    payload: {uid, countByKey, countByValues, type, callBack, wrappedUser, ignoreDetailedIndexes},
  }),
  getBlacklistedDevices: (udids: string, wrappedUser: UserWrapperModel, callBack: any) => ({
    type: userActionTypes.GetBlacklistedDevices,
    payload: {udids, wrappedUser, callBack},
  }),
  getParticipantFrequency: (uid: string, callback?: any) => ({
    type: userActionTypes.GetParticipantFrequency,
    payload: {uid, callback},
  }),
  agoraGetParticipantFrequency: (uid: string, callback?: any) => ({
    type: userActionTypes.AgoraGetParticipantFrequency,
    payload: {uid, callback},
  }),
  setParticipantFrequency: (frequency: string | null) => ({
    type: userActionTypes.SetParticipantFrequency,
    payload: {frequency},
  }),
  findParticipantFrequency: () => ({
    type: userActionTypes.FindParticipantFrequency,
  }),
  setError: (error: string, wrappedUser: UserWrapperModel | null) => ({
    type: userActionTypes.SetError,
    payload: {error, wrappedUser},
  }),
  setMonitoringStatus: (
    targetUid: string,
    monitoringStatus: string | null,
    wrappedUser: UserWrapperModel | null
  ) => ({
    type: userActionTypes.SetMonitoringStatus,
    payload: {targetUid, monitoringStatus, wrappedUser},
  }),
  setMonitoringLoader: () => ({
    type: userActionTypes.SetMonitoringLoader,
  }),
  getUserDevicesList: (filterKey: string, filterValue: string, targetUid?: string) => ({
    type: userActionTypes.GetUserDevicesList,
    payload: {filterKey, filterValue, targetUid},
  }),
  setUserDevicesList: (devicesUserList: UserWrapperModel[], udid: string, targetUid?: string) => ({
    type: userActionTypes.SetUserDevicesList,
    payload: {devicesUserList, udid, targetUid},
  }),
  setUserDevicesResult: (targetUid?: string) => ({
    type: userActionTypes.SetUserDevicesResult,
    payload: {targetUid},
  }),
  loadCurrentUser: (id: string) => ({
    type: userActionTypes.LoadCurrentUser,
    payload: {id},
  }),
  restorePurchase: (targetUid: string) => ({
    type: userActionTypes.RestorePurchases,
    payload: {targetUid},
  }),
  setRestorePurchasesLoader: () => ({
    type: userActionTypes.SetRestorePuchasesLoader,
  }),
  testFcmToken: (
    userUid: string,
    fcmToken: any,
    wrappedUser: UserWrapperModel,
    notificationMessage?: string,
    notificationTitle?: string
  ) => ({
    type: userActionTypes.TestFcmToken,
    payload: {userUid, fcmToken, wrappedUser, notificationMessage, notificationTitle},
  }),
}

export function* userSaga() {
  yield takeLatest(userActionTypes.Ignore, function* ignoreSaga(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUserIgnore,
        {
          targetUid: action.payload.wrappedUser.user.uid,
          moderatorUid: action.payload.admin.user.wtUserUid,
        }
      )
      let userWrapped: UserWrapperModel = action.payload.wrappedUser
      userWrapped.user = response.data
      yield put(userActions.updateUser(userWrapped))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(
    userActionTypes.InvalidateUserAttribute,
    function* invalidateUserAttributeSaga(action: any): any {
      try {
        yield put(userActions.loadUser(action.payload.wrappedUser))
        const response = yield call(
          axios.post,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraInvalidateUserAttribute,
          {
            targetUid: action.payload.wrappedUser.user.uid,
            invalidationReason: action.payload.attribute,
          }
        )
        let userWrapped: UserWrapperModel = action.payload.wrappedUser
        userWrapped.user = response.data
        yield put(userActions.updateUser(userWrapped))
      } catch (err) {
        console.error(err)
      }
    }
  )

  yield takeLatest(userActionTypes.Suspend, function* suspendSaga(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUserSuspend,
        {
          targetUid: action.payload.wrappedUser.user.uid,
          moderatorUid: action.payload.admin.user.wtUserUid,
          sanctionDuration: action.payload.duration,
          moderatorComment: action.payload.moderatorComment,
        }
      )
      let userWrapped: UserWrapperModel = action.payload.wrappedUser
      userWrapped.user = response.data
      yield put(userActions.updateUser(userWrapped))

      if (action.payload.callBack && response.data) action.payload.callBack()
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(userActionTypes.Unsuspend, function* unsuspendSaga(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUserUnSuspend,
        {
          targetUid: action.payload.wrappedUser.user.uid,
          moderatorUid: action.payload.admin.user.wtUserUid,
        }
      )
      let userWrapped: UserWrapperModel = action.payload.wrappedUser
      userWrapped.user = response.data
      yield put(userActions.updateUser(userWrapped))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(userActionTypes.Ban, function* banSaga(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUserBan,
        {
          targetUid: action.payload.wrappedUser.user.uid,
          moderatorUid: action.payload.admin.user.wtUserUid,
          moderatorComment: action.payload.moderatorComment,
        }
      )
      let userWrapped: UserWrapperModel = action.payload.wrappedUser
      userWrapped.user = response.data
      yield put(userActions.updateUser(userWrapped))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(userActionTypes.Unban, function* unBanSaga(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUserUnBan,
        {
          targetUid: action.payload.wrappedUser.user.uid,
          moderatorUid: action.payload.admin.user.wtUserUid,
        }
      )
      let userWrapped: UserWrapperModel = action.payload.wrappedUser
      userWrapped.user = response.data
      yield put(userActions.updateUser(userWrapped))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(userActionTypes.Kick, function* kickSaga(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUserKick,
        {
          confId: action.payload.frequency,
          targetUid: action.payload.wrappedUser.user.uid,
          audioProviderKey: 'AGORA',
          frequencyAlias: action.payload.frequencyAlias,
        }
      )
      yield put(userActions.updateUser(action.payload.wrappedUser))
      if (action.payload.callBack) action.payload.callBack(response.data.results)
    } catch (err) {
      console.error(err)
      yield put(userActions.updateUser(action.payload.wrappedUser))
    }
  })

  yield takeLatest(userActionTypes.EditUser, function* editUser(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      let params = {
        targetUid: action.payload.wrappedUser.user.uid,
        nickname: action.payload.nickname,
        avatarLayers: action.payload.avatarLayers,
        currentTheme: action.payload.currentTheme,
        age: action.payload.wrappedUser.user.age,
        languageCode: action.payload.wrappedUser.user.languageCode,
      }
      if (action.payload.nickname === null) delete params.nickname
      if (action.payload.avatarLayers === null) delete params.avatarLayers
      if (action.payload.currentTheme === null) delete params.currentTheme

      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUserUpdate,
        params
      )
      let userWrapped: UserWrapperModel = action.payload.wrappedUser
      userWrapped.user = response.data
      yield put(userActions.updateUser(userWrapped))
    } catch (err: any) {
      if (err.response.data.details.errorKey === 'ERROR_MSG_NICKNAME__PROFANITY')
        yield put(
          userActions.setError(
            errorProvider.ERROR_MSG_NICKNAME__PROFANITY,
            action.payload.wrappedUser
          )
        )
      if (err.response.data.details.errorKey === 'ERROR_MSG_NICKNAME__RESERVED')
        yield put(
          userActions.setError(
            errorProvider.ERROR_MSG_NICKNAME__RESERVED,
            action.payload.wrappedUser
          )
        )
      if (err.response.data.details.errorKey === 'ERROR_MSG_NICKNAME__IDENTICAL')
        yield put(
          userActions.setError(
            errorProvider.ERROR_MSG_NICKNAME__IDENTICAL,
            action.payload.wrappedUser
          )
        )
      if (err.response.data.details.errorKey === 'ERROR_MSG_USER__UNAUTHORIZED_AVATAR_LAYER_SRC')
        yield put(
          userActions.setError(
            errorProvider.ERROR_MSG_USER__UNAUTHORIZED_AVATAR_LAYER_SRC,
            action.payload.wrappedUser
          )
        )
    }
  })

  yield takeLatest(userActionTypes.RemoveFriends, function* removeFriendsSaga(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      const response = yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUsersRemoveFriends,
        {
          friendsType: action.payload.friendsType,
          targetUid: action.payload.targetUid,
          clearAll: action.payload.clearAll,
          uids: action.payload.uids,
        }
      )
      let userWrapped: UserWrapperModel = action.payload.wrappedUser
      userWrapped.user = response.data
      yield put(userActions.updateUser(userWrapped))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(userActionTypes.GetUsers, function* getUsersSaga(action: any): any {
    const getUsersList = (state: any) => state.users.usersList
    const usersList = yield select(getUsersList)
    let param: userListGetParams = {
      cursorLastDocumentKey:
        usersList != null && usersList.length > 0 && action.payload.lastUid
          ? usersList[usersList.length - 1].user.uid
          : '',
      limit: 10,
    }
    try {
      if (action.payload.filterKey !== '' && action.payload.filterValue !== '') {
        param = {
          ...param,
          filterKey: action.payload.filterKey,
          filterValue: action.payload.filterValue,
          sortingValue: action.payload.sortingValue,
          type: action.payload.type,
          operator: action.payload.operator,
        }
      }
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUsersList,
        {
          params: param,
        }
      )
      let resultLength = true
      if (!response.data.results.length) resultLength = false
      let userArray: UserWrapperModel[] = response.data.results
      yield put(userActions.setUsers(userArray, action.payload.concat, resultLength))
    } catch (err) {
      console.error(err)
    }
  })
  yield takeLatest(userActionTypes.SearchUsers, function* searchUsersSaga(action: any): any {
    let param = {
      term: '',
      excludeAnonymous: false,
      limit: 50,
    }
    try {
      param = {
        ...param,
        term: action.payload.term,
      }
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUserSearch,
        {
          params: param,
        }
      )
      let resultLength = true
      if (!response.data.results.length) resultLength = false
      let userArray: UserWrapperModel[] = response.data.results
      yield put(userActions.setUsers(userArray, action.payload.concat, resultLength))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(
    userActionTypes.GetModerationUsers,
    function* getModerationUsersSaga(action: any): any {
      const getModerationUsersList = (state: any) => state.users.moderationUsersList
      const moderationUsersList = yield select(getModerationUsersList)
      let param: userListGetParams = {
        cursorLastDocumentKey:
          moderationUsersList != null && moderationUsersList.length > 0 && action.payload.lastUid
            ? moderationUsersList[moderationUsersList.length - 1].user.uid
            : '',
        limit: 10,
      }
      try {
        if (action.payload.filterKey !== '' && action.payload.filterValue !== '') {
          param = {
            ...param,
            filterKey: 'moderation.flag',
            filterValue: action.payload.filterValue,
            sortingValue: 'ASC',
            type: 'string',
            operator: '==',
          }
        }
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUsersList,
          {
            params: param,
          }
        )
        let resultLength = true
        if (!response.data.results.length) resultLength = false
        let userArray: UserWrapperModel[] = response.data.results
        yield put(userActions.setModerationUsers(userArray, action.payload.concat, resultLength))
      } catch (err) {
        console.error(err)
      }
    }
  )

  yield takeLatest(userActionTypes.Get1User, function* get1UserSaga(action: any): any {
    try {
      yield put(userActions.loadCurrentUser(action.payload.uid))
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUsersGetRoute,
        {
          params: {
            uids: action.payload.uid,
          },
        }
      )
      let userArray: UserWrapperModel[] = response.data.results
      yield put(userActions.setCurrentUser(userArray[0]))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeEvery(
    userActionTypes.GetMultipleUsers,
    function* getMultipleUsersSaga(action: any): any {
      try {
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUsersGetRoute,
          {
            params: {
              uids: action.payload.uids,
            },
          }
        )
        let userArray: UserWrapperModel[] = response.data.results
        yield put(userActions.setUsers(userArray, action.payload.concat, false, true))
      } catch (err) {
        console.error(err)
      }
    }
  )
  yield takeLatest(userActionTypes.BountiesAcquire, function* bountyAcquire(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraBountiesAcquire,
        {
          userUid: action.payload.uid,
          bounties: [
            {
              key: action.payload.key,
              type: 'BUNDLE',
              unlockedByKey: action.payload.systemKey,
              unlockedByType: 'SYSTEM',
              expiration: action.payload.expiration,
            },
          ],
        }
      )
      yield put(userActions.get1User(action.payload.uid))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(userActionTypes.BountiesRemove, function* bountyRemove(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraBountiesRemove,
        {
          userUid: action.payload.uid,
          bounties: [
            {
              key: action.payload.key,
              type: 'BUNDLE',
              unlockedByKey: action.payload.unlockedByKey,
              unlockedByType: action.payload.unlockedByType,
            },
          ],
        }
      )
      yield put(userActions.get1User(action.payload.uid))
    } catch (err) {
      console.error(err)
    }
  })
  yield takeLatest(
    userActionTypes.AchievementAcquire,
    function* achievementAcquire(action: any): any {
      try {
        yield put(userActions.loadUser(action.payload.wrappedUser))
        yield call(
          axios.post,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAchievementsAcquire,
          {
            userUid: action.payload.uid,
            key: action.payload.key,
            type: 'PROGRESS',
            progress: '0',
          }
        )
        yield put(userActions.get1User(action.payload.uid))
      } catch (err) {
        console.error(err)
      }
    }
  )

  yield takeLatest(userActionTypes.AchievementReset, function* achievementReset(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAchievementsReset,
        {
          userUid: action.payload.uid,
          key: action.payload.key,
          type: 'PROGRESS',
        }
      )
      yield put(userActions.get1User(action.payload.uid))
    } catch (err) {
      console.error(err)
    }
  })
  yield takeLatest(userActionTypes.DeleteAccount, function* deleteAccount(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraDeleteAccount,
        {
          targetUid: action.payload.uid,
        }
      )
      yield put(userActions.getUsers(''))
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(userActionTypes.CountBy, function* countBy(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      const response = yield call(
        axios.get,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraCountBy,
        {
          params: {
            countByKey: action.payload.countByKey,
            countByValues: action.payload.countByValues,
            type: action.payload.type,
            ignoreDetailedIndexes: action.payload.ignoreDetailedIndexes,
          },
        }
      )
      yield put(userActions.updateUser(action.payload.wrappedUser))
      if (action.payload.callBack) action.payload.callBack(response.data.totalUnique)
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(
    userActionTypes.GetBlacklistedDevices,
    function* getBlacklistedDevices(action: any): any {
      try {
        yield put(userActions.loadUser(action.payload.wrappedUser))
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraGetDevice,
          {
            params: {
              udids: action.payload.udids,
            },
          }
        )
        yield put(userActions.updateUser(action.payload.wrappedUser))
        if (action.payload.callBack) action.payload.callBack(response.data.results)
      } catch (err) {
        console.error(err)
      }
    }
  )

  yield takeLatest(
    userActionTypes.GetParticipantFrequency,
    function* getParticipantFrequency(action: any): any {
      try {
        yield put(userActions.findParticipantFrequency())
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraGetParticipantFrequency,
          {
            params: {
              userUid: action.payload.uid,
            },
          }
        )
        if (response.data) yield put(userActions.setParticipantFrequency(response.data))
        if (action.payload.callback && response.data) {
          action.payload.callback(response.data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  )
  yield takeLatest(
    userActionTypes.AgoraGetParticipantFrequency,
    function* agoraGetParticipantFrequency(action: any): any {
      try {
        yield put(userActions.findParticipantFrequency())
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraAgoraGetParticipantFrequency,
          {
            params: {
              userUid: action.payload.uid,
            },
          }
        )
        if (response.data) yield put(userActions.setParticipantFrequency(response.data))
        if (action.payload.callback && response.data) {
          action.payload.callback(response.data)
        }
      } catch (err) {
        yield put(userActions.setParticipantFrequency(null))
      }
    }
  )

  yield takeLatest(
    userActionTypes.SetMonitoringStatus,
    function* setMonitoringStatus(action: any): any {
      try {
        yield put(userActions.setMonitoringLoader())
        yield call(
          axios.post,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraSetHiveMonitoring,
          {
            monitoringStatus: action.payload.monitoringStatus,
            targetUid: action.payload.targetUid,
          }
        )
        let userWrapped: UserWrapperModel = action.payload.wrappedUser
        userWrapped.user.moderation.monitoringStatus = action.payload.monitoringStatus
        yield put(userActions.updateUser(userWrapped))
        yield put(userActions.setMonitoringLoader())
      } catch (err) {
        console.error(err)
      }
    }
  )
  yield takeEvery(
    userActionTypes.GetUserDevicesList,
    function* getUserDeviceListSaga(action: any): any {
      try {
        yield put(userActions.setUserDevicesResult(action.payload.targetUid))
        const response = yield call(
          axios.get,
          ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraUsersList,
          {
            params: {
              filterKey: action.payload.filterKey,
              filterValue: action.payload.filterValue,
            },
          }
        )
        let userDeviceArray: UserWrapperModel[] = response.data.results
        yield put(
          userActions.setUserDevicesList(
            userDeviceArray,
            action.payload.filterValue,
            action.payload.targetUid
          )
        )
      } catch (err) {
        console.error(err)
      }
    }
  )
  yield takeLatest(userActionTypes.RestorePurchases, function* restorePurchase(action: any): any {
    try {
      yield put(userActions.setRestorePurchasesLoader())
      yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraRestorePurchase,
        {
          targetUid: action.payload.targetUid,
        }
      )
      yield put(userActions.setRestorePurchasesLoader())
    } catch (err) {
      console.error(err)
    }
  })
  yield takeLatest(userActionTypes.TestFcmToken, function* testFcmToken(action: any): any {
    try {
      yield put(userActions.loadUser(action.payload.wrappedUser))
      yield call(
        axios.post,
        ApiRequestManager.apiUrlRoute + ApiRequestManager.apiIntraTestFcmToken,
        {
          userUid: action.payload.userUid,
          fcmToken: action.payload.fcmToken,
          notificationTitle: action.payload.notificationTitle,
          notificationMessage: action.payload.notificationMessage,
        }
      )
      yield put(userActions.updateUser(action.payload.wrappedUser))
    } catch (err) {
      console.error(err)
    }
  })
}
