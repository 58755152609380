export function getUserAgeFromBirthdate(birthdateTs: number) {
  // Note: Birthdate is a timestamp in ms
  const today = new Date()
  const birthDate = new Date(birthdateTs)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}
