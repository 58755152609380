/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {AvatarLayerTypeEnum} from '../../../../app/modules/auth/models/AvatarLayerTypeEnum'
import AvatarRenderer from '../../../../app/modules/profile/components/AvatarRenderer'
import {AvatarLayerModel} from '../../../../app/modules/users/models/ProfileModel'

type Props = {
  color?: string
  avatar?: AvatarLayerModel[] | null
  online?: boolean
  name: string
  uid: string
}

const FriendsCard: FC<Props> = ({color = '', avatar, online = false, name, uid}) => {
  return (
    <div className='card'>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='symbol symbol-75px symbol-circle'>
            {color ? (
              <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                {name.charAt(0)}
              </span>
            ) : (
              <AvatarRenderer
                avatarLayers={
                  avatar
                    ? avatar
                    : [
                        {
                          type: AvatarLayerTypeEnum.bkgdColor,
                          src: '#D3DBDF',
                        },
                        {
                          type: AvatarLayerTypeEnum.shellColor,
                          src: '#E0E0E0',
                        },
                        {
                          type: AvatarLayerTypeEnum.expressionTxt,
                          src: '...',
                        },
                      ]
                }
                dimension={80}
                uid={uid}
              />
            )}
            {online && (
              <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>
            )}
          </div>
        </div>
        <Link className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0' to={{pathname: uid}}>
          {name}
        </Link>
      </div>
    </div>
  )
}

export {FriendsCard}
