import React, {useState} from 'react'
import {
  timestampCountConverter,
  timestampHoursConverter,
} from '../../../../helpers/converter/Common'
import {ModerationChart} from './Chart'
import {DateRangePicker} from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useIntl} from 'react-intl'
import {UserWrapperModel} from '../../../auth/models/AccountUserWrapperModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import swal from 'sweetalert'

type Props = {
  userData: UserWrapperModel
}

const UserModeration: React.FC<Props> = ({userData}) => {
  const intl = useIntl()
  const [option, setOption] = useState<String>('Sanctions')
  const [showDateFilters, setShowDateFilters] = useState(false)
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const moderatorList = useSelector<RootState>(
    ({account}) => account.moderatorsList,
    shallowEqual
  ) as any

  const user = userData.user
  const handleChange = (e: any) => {
    setOption(e.target.value)
  }

  let screenWidth = window.screen.width
  return (
    <div>
      {user.moderation.events.reports.length !== 0 ||
      user.moderation.events.sanctions.length !== 0 ? (
        <>
          {screenWidth > 500 && (
            <div className='mt-2'>
              <button
                className='btn btn-sm btn-light'
                onClick={() => setShowDateFilters(!showDateFilters)}
              >
                {intl.formatMessage({id: 'FILTER.KEY'})}
              </button>
              {showDateFilters ? (
                <div className='table-responsive'>
                  <DateRangePicker
                    ranges={rangeDate}
                    onChange={(e) => {
                      let selection = {
                        key: 'selection',
                        startDate: !e.selection.startDate ? new Date() : e.selection.startDate,
                        endDate: !e.selection.endDate ? new Date() : e.selection.endDate,
                      }
                      return setRangeDate([selection])
                    }}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    direction='horizontal'
                    preventSnapRefocus={true}
                  />
                </div>
              ) : null}

              <ModerationChart
                ModerationSanctionData={user.moderation.events.sanctions}
                ModerationReportData={user.moderation.events.reports}
                startDate={rangeDate[0].startDate}
                endDate={rangeDate[0].endDate}
              />
            </div>
          )}
        </>
      ) : null}
      <div className='d-flex my-2'>
        <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select form-select-sm w-125px'
          defaultValue='Sanctions'
          onChange={(e) => handleChange(e)}
        >
          <option value='Sanctions'>Sanctions</option>
          <option value='Reports'>{intl.formatMessage({id: 'USER.REPORTS'})}</option>
          <option value='Invalidations'>Invalidations</option>
        </select>
      </div>
      <div className='table-responsive'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {option === 'Sanctions' ? (
            <>
              {user.moderation.events.sanctions.length === 0 ? (
                <tr className=''>
                  <div className='fs-1 mb-20 text-center fw-bolder'>
                    {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
                  </div>
                </tr>
              ) : (
                <>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-50px '>{intl.formatMessage({id: 'SANCTION.TYPE'})}</th>
                      <th className='min-w-50px '>{intl.formatMessage({id: 'SANCTION.BEGIN'})}</th>
                      <th className='min-w-130px '>{intl.formatMessage({id: 'SANCTION.END'})}</th>
                      <th className='min-w-50px '>
                        {intl.formatMessage({id: 'SANCTION.DURATION'})}
                      </th>
                      <th>Reason</th>
                      <th className='min-w-140px '>
                        {intl.formatMessage({id: 'SANCTION.MODERATOR'})}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.moderation.events.sanctions.map((sanction, sanctionsIndex) => (
                      <tr key={sanctionsIndex}>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6'>
                            {sanction.type === 'SUSPENDED' ? (
                              <span className='badge badge-warning'>
                                {intl.formatMessage({id: 'SANCTION.SUSPENDED'})}
                              </span>
                            ) : (
                              <span className='badge badge-danger'>
                                {intl.formatMessage({id: 'SANCTION.BANNED'})}
                              </span>
                            )}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6'>
                            {timestampHoursConverter(sanction.date)}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6'>
                            {timestampHoursConverter(sanction.expiration)}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6'>
                            {sanction.type === 'SUSPENDED'
                              ? timestampCountConverter(sanction.date, sanction.expiration, intl)
                              : 'END OF TIME'}
                          </span>
                        </td>
                        <td>
                          <span
                            className='text-dark fw-bolder fs-6'
                            style={{
                              width: '100px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              lineHeight: '40px',
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            {sanction.moderatorComment && sanction.moderatorComment}
                          </span>
                          {sanction.moderatorComment && (
                            <span
                              className='btn btn-sm btn-icon btn-info ms-1 mb-1'
                              onClick={() => {
                                swal({
                                  text: sanction.moderatorComment,
                                })
                              }}
                            >
                              <i className='fas fa-info'></i>
                            </span>
                          )}
                        </td>
                        <td>
                          <span className='text-dark fw-bolder fs-6'>
                            {moderatorList[sanction.moderatorId] &&
                              moderatorList[sanction.moderatorId]}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </>
          ) : option === 'Reports' ? (
            <>
              {user.moderation.events.reports.length === 0 ? (
                <tr className=''>
                  <div className='fs-1 mb-20 text-center fw-bolder'>
                    {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
                  </div>
                </tr>
              ) : (
                <>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-50px '>{intl.formatMessage({id: 'MENU.USERS'})}</th>
                      <th className='min-w-130px '>{intl.formatMessage({id: 'REPORT.DATE'})}</th>
                      <th className='min-w-140px '>{intl.formatMessage({id: 'REPORT.REASON'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.moderation.events.reports.map((report, reportIndex) => (
                      <tr key={reportIndex}>
                        <td>
                          <a
                            href={'/users/' + report.uid}
                            className='text-dark fw-bolder d-block fs-6'
                          >
                            {report.uid}
                          </a>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6'>
                            {timestampHoursConverter(report.date)}
                          </span>
                        </td>
                        <td>
                          <span className='badge badge-warning w-100px'>{report.reason}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </>
          ) : (
            <>
              {!user.moderation.events.invalidations ||
              user.moderation.events.invalidations.length === 0 ? (
                <tr className=''>
                  <div className='fs-1 mb-20 text-center fw-bolder'>
                    {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
                  </div>
                </tr>
              ) : (
                <>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-50px '>
                        {intl.formatMessage({id: 'INVALIDATION.VALUE'})}
                      </th>
                      <th className='min-w-50px '>
                        {intl.formatMessage({id: 'INVALIDATION.MODERATOR'})}
                      </th>
                      <th className='min-w-130px '>{intl.formatMessage({id: 'REPORT.DATE'})}</th>
                      <th className='min-w-140px '>
                        {intl.formatMessage({id: 'INVALIDATION.TYPE'})}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.moderation.events.invalidations.map(
                      (invalidations, invalidationsIndex) => (
                        <tr key={invalidationsIndex}>
                          <td className='text-dark fw-bolder d-block fs-6'>
                            {invalidations.value}
                          </td>
                          <td>
                            <span className='text-dark fw-bolder d-block fs-6'>
                              {invalidations.moderatorId}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bolder d-block fs-6'>
                              {timestampHoursConverter(invalidations.date)}
                            </span>
                          </td>
                          <td>
                            <span className='badge badge-warning'>{invalidations.type}</span>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </>
              )}
            </>
          )}
        </table>
      </div>
    </div>
  )
}
export default UserModeration
