import React, {useState} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {useIntl} from 'react-intl'
import {timestampConverter} from '../../../../helpers/converter/Common'
import * as users from '../../../users/redux/UserRedux'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {RoleCheckMin} from '../../../../helpers/RoleRestriction'
import {Roles} from '../../../auth/models/RolesEnum'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../../auth/models/AccountUserWrapperModel'
import UserAchievementsModal from './UserAchievementsModal'

type Props = {
  userData: UserWrapperModel
}
const UserAchievements: React.FC<Props> = ({userData}) => {
  const intl = useIntl()
  const currentUser = useSelector<RootState>(
    ({users}) => users.currentUser,
    shallowEqual
  ) as UserWrapperModel
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel
  const user = currentUser.user
  const dispatch = useDispatch()
  const [modalShow, setModalShow] = useState(false)
  let achievementData = achievementsObjectsToArray()

  function achievementsObjectsToArray() {
    let achievementsArray = []

    for (const achievementsKey in user.achievements) {
      const achievements = user.achievements[achievementsKey]
      achievementsArray.push(achievements)
    }
    return achievementsArray
  }

  function handleRemove(key: string, type: string) {
    dispatch(users.userActions.achievementReset(currentUser, user.uid, key, type))
  }

  return (
    <>
      {user.providerData && !user.providerData.length ? (
        <div className='fs-1 mb-20 text-center fw-bolder'>
          {intl.formatMessage({id: 'USER.ACHIEVEMENTS.ANONYMOUS'})}
        </div>
      ) : (
        <>
          {RoleCheckMin(Roles.Admin, admin.account.role) && (
            <div>
              <button className='btn btn-sm btn-light-success' onClick={() => setModalShow(true)}>
                {intl.formatMessage({id: 'USER.ACHIEVEMENTS.ACTIVATION'})}
              </button>
              {modalShow && <UserAchievementsModal show={modalShow} setShow={setModalShow} />}
            </div>
          )}
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th>{intl.formatMessage({id: 'USER.ACHIEVEMENTS.ACHIEVEMENT'})}</th>
                  <th>{intl.formatMessage({id: 'USER.BOUNTIES.TYPE'})}</th>
                  <th>{intl.formatMessage({id: 'USER.ACHIEVEMENTS.LASTUPDATE'})}</th>
                  {RoleCheckMin(Roles.Admin, admin.account.role) && (
                    <th>{intl.formatMessage({id: 'USER.BOUNTIES.ACTION'})}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {achievementData.map((achievement, achievementIndex) => (
                  <tr key={achievementIndex}>
                    <td>
                      <span className='text-dark fw-bolder d-block fs-6'>{achievement.key}</span>
                    </td>
                    <td>
                      <span className='fw-bolder d-block fs-6 badge badge-success'>
                        {achievement.type}
                      </span>
                    </td>
                    <td>
                      <div
                        style={{minHeight: '35px'}}
                        className='mt-1 mb-1 d-flex align-items-center'
                      >
                        <span
                          className='text-dark fw-bolder fs-6'
                          style={{overflowX: 'scroll', whiteSpace: 'nowrap', maxWidth: '200px'}}
                        >
                          {timestampConverter(achievement.tsUpdate)}{' '}
                        </span>
                      </div>
                    </td>
                    {RoleCheckMin(Roles.Admin, admin.account.role) && (
                      <td>
                        <div className='d-block '>
                          <div>
                            <button
                              className=' btn btn-sm btn-light-danger mt-1 mb-1'
                              onClick={() => handleRemove(achievement.key, achievement.type)}
                            >
                              {intl.formatMessage({id: 'USER.BOUNTIES.REMOVE'})}
                            </button>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}
export default UserAchievements
