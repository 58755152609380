export function timestampConverter(time: number) {
  return new Date(time).toISOString().slice(0, 10)
}

export function timestampHoursConverter(time: number) {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(time)
}

export function timestampCountConverter(date1: number, date2: number, intl: any) {
  let result = Math.ceil((date2 - date1) / 1000 / 60)

  if (result > 1439) return Math.round(result / 24 / 60) + intl.formatMessage({id: 'DAYS'})
  if (result > 59 && result < 1440)
    return Math.round(result / 60) + intl.formatMessage({id: 'HOURS'})
  return result + intl.formatMessage({id: 'MINS'})
}
