interface dateArrayEntry {
  [key: string]: number
}

export function arrayReduce(dataArray: string[], behavior: any) {
  return dataArray.reduce((r: any, s: string) => {
    const key = s[0].toUpperCase() + s.substring(1).toLowerCase()
    r[key] = behavior(r[key])
    return r
  }, {})
}

export function dataAssign(dataArray: dateArrayEntry, finalArray: dateArrayEntry) {
  for (let i = 0; i < Object.keys(dataArray).length; i++) {
    const dataNumber = Object.values(dataArray)[i]
    const dataKey = Object.keys(dataArray)[i]
    for (let j = 0; j < Object.keys(finalArray).length; j++) {
      if (dataKey === Object.keys(finalArray)[j]) {
        finalArray[Object.keys(finalArray)[j]] = dataNumber
      }
    }
  }
}
