import React from 'react'
import {useDispatch} from 'react-redux'
import * as profanities from '../dashboard/redux/ProfanitiesRedux'

type Props = {
  dataList: any
  languageCode: string
}

const TableRow: React.FC<Props> = ({dataList, languageCode}) => {
  let numberOfTables = window.screen.width < 800 ? 1 : dataList.length < 3 ? dataList.length : 3
  const elementsPerTable = dataList.length / numberOfTables

  const dispatch = useDispatch()

  const generateTables = () => {
    const tables = []

    for (let i = 0; i < numberOfTables; i++) {
      const startIndex = i * elementsPerTable
      const endIndex = startIndex + elementsPerTable
      const tableRows = dataList.slice(startIndex, endIndex).map((item: any) => (
        <tr key={item.id}>
          <td style={{verticalAlign: 'middle'}}>{item.key}</td>
          <td>
            <button
              className='btn btn-sm btn-danger'
              onClick={() =>
                dispatch(profanities.profanitiyActions.removeProfanity(languageCode, item.key))
              }
            >
              Remove
            </button>
          </td>
        </tr>
      ))
      const tableStyle: React.CSSProperties = {
        borderRight: i < numberOfTables - 1 ? '2px dotted #ccc' : 'none',
      }
      const table = (
        <div className='col-md-4' key={`table-${i}`} style={tableStyle}>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Word</th>
                <th scope='col'>Action</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </div>
      )
      tables.push(table)
    }
    return tables
  }

  return (
    <div className='container'>
      <div className='row'>{generateTables()}</div>
    </div>
  )
}

export default TableRow
