import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {UserWrapperModel} from '../../../auth/models/AccountUserWrapperModel'
import * as users from '../../../users/redux/UserRedux'
import {achievementsKeys} from '../../../../helpers/provider/AchievementsProvider'

type Props = {
  show: any
  setShow: any
}
const UserAchievementsModal: React.FC<Props> = ({show, setShow}) => {
  const currentUser = useSelector<RootState>(
    ({users}) => users.currentUser,
    shallowEqual
  ) as UserWrapperModel
  let user = currentUser.user
  let expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + 1)

  const dispatch = useDispatch()
  const intl = useIntl()
  const handleClose = () => setShow(false)
  const [achievement, setAchievement] = useState('')
  const [system, setSystem] = useState('')

  function handleSave() {
    dispatch(users.userActions.achievementAcquire(currentUser, user.uid, achievement, system))
    handleClose()
  }

  let permanentAchievements: any = []

  if (user.achievements)
    Object.keys(user.achievements).map(
      (permanent) =>
        user.achievements[permanent] && permanentAchievements.push(user.achievements[permanent].key)
    )
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        scrollable
        size='lg'
        style={{top: '20%'}}
      >
        <Modal.Body>
          <div className='d-flex mt-10 h-200px'>
            <div>
              <span className='text-dark fw-bolder d-block fs-6 mb-2'>
                {' '}
                {intl.formatMessage({id: 'USER.ACHIEVEMENTS.TOUNLOCK'})}{' '}
              </span>
              <select
                name='achievement'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-sm w-200px me-10 mb-10'
                onChange={(e) => {
                  setAchievement(e.target.value)
                  setSystem('')
                }}
              >
                <option value=''></option>
                {Object.keys(achievementsKeys).map(
                  (achievement, index) =>
                    !permanentAchievements.includes(achievement) && (
                      <option key={index} value={achievement}>
                        {achievement}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>
          <div className='d-flex'>
            <button
              className='btn btn-light-danger ms-20 mt-20 me-auto'
              onClick={() => setShow(false)}
            >
              {intl.formatMessage({id: 'USER.BOUNTIES.CANCEL'})}
            </button>
            {achievement && (
              <button className=' btn btn-light-success me-20 mt-20' onClick={() => handleSave()}>
                {intl.formatMessage({id: 'USER.BOUNTIES.SAVE'})}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserAchievementsModal
