export const bundleKeys = {
  EASTER_2022: 'EASTER_2022',
  HALLOWEEN_2021: 'HALLOWEEN_2021',
  CHRISTMAS_2021: 'CHRISTMAS_2021',
  LOVE_2022: 'LOVE_2022',
  WT_PLUS: 'WT_PLUS',
  COLOR_PICKER: 'COLOR_PICKER',
  STRANGER_TALKIES_2022: 'STRANGER_TALKIES_2022',
  HALLOWEEN_2022: 'HALLOWEEN_2022',
  CHRISTMAS_2022: 'CHRISTMAS_2022',
  VALENTINES_2023: 'VALENTINES_2023',
  CHINESE_NEW_YEAR_2023: 'CHINESE_NEW_YEAR_2023',
  GREAT_TALKY_BROS: 'GREAT_TALKY_BROS',
  EASTER_2023: 'EASTER_2023',
  MAY_THE_4TH: 'MAY_THE_4TH',
  TEARS_OF_THE_WALKIE: 'TEARS_OF_THE_WALKIE',
  SPIDER_TALKIE: 'SPIDER_TALKIE',
  PRIDE_MONTH_2023: 'PRIDE_MONTH_2023',
  AQUATIC_SUMMER_2023: 'AQUATIC_SUMMER_2023',
  BACK_TO_SCHOOL_2023: 'BACK_TO_SCHOOL_2023',
  HALLOWEEN_2023: 'HALLOWEEN_2023',
  CHRISTMAS_2023: 'CHRISTMAS_2023',
  NEW_YEAR_2024: 'NEW_YEAR_2024',
  SKI_CLUB_2024: 'SKI_CLUB_2024',
  CHINESE_NEW_YEAR_2024: 'CHINESE_NEW_YEAR_2024',
  VALENTINES_2024: 'VALENTINES_2024',
  SUPERBOWL_2024: 'SUPERBOWL_2024',
}
