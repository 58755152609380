import {ProviderThemeLogoType} from './ProviderThemeData'

export const ProviderAudioFilter: ProviderThemeLogoType = {
  BROKEN_ROBOT: '/media/audioFilter/broken_robot.svg',
  DARK_MODULATION: '/media/audioFilter/dark_modulation.svg',
  FEMININE: '/media/audioFilter/feminine.svg',
  HELIUM: '/media/audioFilter/helium.svg',
  MASCULINE: '/media/audioFilter/masculine.svg',
  NONE: '/media/audioFilter/no_filter.svg',
  INTERFERENCE: '/media/audioFilter/No_picto_filter.svg',
  ABYSS: '/media/audioFilter/No_picto_filter.svg',
  WOBBLE: '/media/audioFilter/No_picto_filter.svg',
  STARSHIP_CAPTAIN: '/media/audioFilter/No_picto_filter.svg',
  NERVOUS_ROBOT: '/media/audioFilter/No_picto_filter.svg',
  SWARM: '/media/audioFilter/No_picto_filter.svg',
  AM_RADI: '/media/audioFilter/No_picto_filter.svg',
  VM_BABY: '/media/audioFilter/baby.svg',
  VM_CAVE: '/media/audioFilter/cave.svg',
  VM_DEEP: '/media/audioFilter/deep.svg',
  VM_BLOCKS: '/media/audioFilter/blocks.svg',
  VM_MAGIC_CHORDS: '/media/audioFilter/magic_chords.svg',
}
