import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {timestampHoursConverter} from '../../../../helpers/converter/Common'
import {useIntl} from 'react-intl'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../../auth/models/AccountUserWrapperModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import SendNotificationModal from '../../../../pages/dashboard/SendNotificationModal'
import {RoleCheckMin} from '../../../../helpers/RoleRestriction'
import {Roles} from '../../../auth/models/RolesEnum'
import {RootState} from '../../../../../setup'
import {ToastContainer, toast} from 'react-toastify'

type Props = {
  userData: UserWrapperModel
}
const UserUdids: React.FC<Props> = ({userData}) => {
  const intl = useIntl()
  const user = userData.user
  const [option, setOption] = useState('device')
  const [modalShow, setModalShow] = useState(false)
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel

  let udidData = user.devices ? Object.values(user.devices) : null
  let fcmTokenData = user.fcmTokens
  const handleChange = (e: any) => {
    setOption(e.target.value)
  }
  const dispatch = useDispatch()

  return (
    <div>
      {!user.devices && !user.fcmTokens ? (
        <div className='fs-1 mb-20 text-center fw-bolder mt-15'>
          {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
        </div>
      ) : (
        <>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-sm w-125px'
            defaultValue='Sanctions'
            onChange={(e) => handleChange(e)}
          >
            <option value='device'>Devices</option>
            <option value='fcmToken'>FcmTokens</option>
          </select>

          {option === 'device' && (
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-50px '>{intl.formatMessage({id: 'UDIDS.CREATEDDATE'})}</th>
                    <th className='min-w-130px '>{intl.formatMessage({id: 'UDIDS.DEVICEID'})}</th>
                    <th className='min-w-140px text-center'>
                      {intl.formatMessage({id: 'USER.PLATFORM'})}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {udidData &&
                    udidData.map((device, deviceIndex) => (
                      <tr key={deviceIndex}>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6'>
                            {timestampHoursConverter(device.ts)}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6'>{device.udid}</span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6 text-center'>
                            <img
                              alt=''
                              className='h-30px justify-content-center logo '
                              src={toAbsoluteUrl(
                                device.platform === 'ios'
                                  ? '/media/logos/apple.svg'
                                  : '/media/logos/android.svg'
                              )}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          {option === 'fcmToken' && (
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-50px '>{intl.formatMessage({id: 'UDIDS.CREATEDDATE'})}</th>
                    <th className='min-w-130px '>Token</th>
                    <th className='min-w-140px text-center'>
                      {intl.formatMessage({id: 'USER.PLATFORM'})}
                    </th>
                    <th> Action </th>
                  </tr>
                </thead>
                <tbody>
                  {fcmTokenData &&
                    fcmTokenData.map((fcmToken, fcmTokenIndex) => (
                      <tr key={fcmTokenIndex}>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6'>
                            {timestampHoursConverter(fcmToken.date)}
                          </span>
                        </td>
                        <td>
                          <span
                            className=' w-200px overflow-hidden'
                            style={{
                              textOverflow: 'ellipsis',
                              lineHeight: '40px',
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            {fcmToken.token}
                          </span>
                          <span
                            className=' fa fa-copy btn btn-icon'
                            style={{verticalAlign: 'top'}}
                            onClick={() =>
                              navigator.clipboard.writeText(fcmToken.token).then(() =>
                                toast.info(intl.formatMessage({id: 'TEXTCOPIED'}), {
                                  position: 'bottom-right',
                                  autoClose: 2000,
                                  hideProgressBar: true,
                                })
                              )
                            }
                          >
                            <ToastContainer />
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block fs-6 text-center'>
                            <img
                              alt=''
                              className='h-30px justify-content-center logo '
                              src={toAbsoluteUrl(
                                fcmToken.platform === 'ios'
                                  ? '/media/logos/apple.svg'
                                  : '/media/logos/android.svg'
                              )}
                            />
                          </span>
                        </td>
                        <td>
                          <button
                            className='btn btn-sm btn-info'
                            onClick={() => setModalShow(true)}
                          >
                            <span className='fas fa-bell btn-icon'></span>
                          </button>
                          {RoleCheckMin(Roles.Admin, admin.account.role) && (
                            <SendNotificationModal
                              show={modalShow}
                              setShow={setModalShow}
                              user={userData}
                              fcmToken={fcmToken}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default UserUdids
