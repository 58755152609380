import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {AccountUserWrapperModel, UserWrapperModel} from '../../auth/models/AccountUserWrapperModel'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {timestampConverter} from '../../../helpers/converter/Common'
import {logos} from '../../../helpers/provider/ProviderDataLogo'
import * as users from '../../users/redux/UserRedux'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import AvatarRenderer from './AvatarRenderer'
import ModerationDropdown from '../../../../_metronic/partials/content/dropdown/ModerationDropdown'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import BlockUI from './BlockUi'
import {Link} from 'react-router-dom'
import {getFlagEmoji} from '../../../helpers/converter/LocaleCodeToFlagConverter'
import {FilterBar} from './FilterBar'
import {RoleCheckMin} from '../../../helpers/RoleRestriction'
import {Roles} from '../../auth/models/RolesEnum'
import {useIntl} from 'react-intl'
import {FiltersModel} from '../../users/models/FiltersModel'

type Props = {
  className: string
}

const GetUsersList: React.FC<Props> = ({className}) => {
  const admin: AccountUserWrapperModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as AccountUserWrapperModel
  const usersList = useSelector<RootState>(
    ({users}) => users.usersList,
    shallowEqual
  ) as UserWrapperModel[]
  const result = useSelector<RootState>(
    ({users}) => users.result,
    shallowEqual
  ) as UserWrapperModel[]
  const currentFilters = useSelector<RootState>(
    ({users}) => users.currentFilters,
    shallowEqual
  ) as FiltersModel
  const searchOnly = useSelector<RootState>(
    ({users}) => users.searchOnly,
    shallowEqual
  ) as FiltersModel

  const intl = useIntl()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [noMoreResult, setNoMoreResult] = useState(false)
  const [filters, setFilters] = useState({
    filterKey: '',
    filterValue: '',
    filterOperator: '==',
    filterType: '',
    sortingOrder: 'ASC',
  })

  useEffect(() => {
    if (usersList === null || (usersList.length === 0 && !currentFilters))
      dispatch(users.userActions.getUsers(''))
    if (currentFilters !== null)
      setFilters({
        filterKey: currentFilters.filterKey,
        filterValue: currentFilters.filterValue,
        filterOperator: currentFilters.filterOperator,
        filterType: currentFilters.filterType,
        sortingOrder: currentFilters.sortingOrder,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!result) {
      setLoadMore(false)
      setNoMoreResult(true)
    }
  }, [result])

  useEffect(() => {
    if (usersList.length > 0) {
      setLoading(false)
      setLoadMore(false)
      setNoMoreResult(false)
    }
    // initialize moderation dropdowns to their buttons
    setTimeout(function () {
      MenuComponent.reinitialization()
    }, 200)
  }, [usersList])

  let uniquePlatform: string[] = []
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {intl.formatMessage({id: 'USER.USERLIST'})}
          </span>
        </h3>
      </div>
      <div>
        <FilterBar filters={filters} setFilters={setFilters} setLoading={setLoading} />
      </div>
      <div className='card-body py-3'>
        {!result && usersList.length === 0 ? (
          <div className=' text-center mt-10 mb-10'>
            <span> {intl.formatMessage({id: 'FILTER.NORESULT'})}</span>
          </div>
        ) : loading ? (
          <div className=' text-center mt-10 mb-10'>
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'LOADER'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-50px '>Avatar</th>
                  <th className='min-w-130px '>
                    {intl.formatMessage({id: 'USER.USERBOARD.USER'})}
                  </th>
                  <th className='min-w-140px '>
                    {intl.formatMessage({id: 'USER.USERBOARD.STATUS'})}
                  </th>
                  <th className='min-w-130px '>
                    {intl.formatMessage({id: 'USER.USERBOARD.EVENTS'})}
                  </th>
                  <th className='min-w-90px text-center'>
                    {intl.formatMessage({id: 'USER.PLATFORM'})}
                  </th>
                  <th className='min-w-110px text-center'>
                    {intl.formatMessage({id: 'USER.PROVIDER'})}
                  </th>
                  <th className='min-w-90px '>Creation</th>
                  {RoleCheckMin(Roles.ModeratorVeteran, admin.account.role) && (
                    <th className='min-w-50px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {usersList.map(({user, account}, index) => {
                  uniquePlatform = []
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <AvatarRenderer
                            avatarLayers={user.profile.avatarLayers}
                            dimension={50}
                            uid={user.uid}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link to={{pathname: user.uid, state: {userData: user}}}>
                              <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {user.nickname + user.nicknameId}
                              </span>
                            </Link>
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                              {user.uid}
                            </span>
                            <div className='d-flex'>
                              {user.moderation.invalidationReasons
                                ?.INVALID_AVATAR_OFFENSIVE_EXPRESSION && (
                                <span className='d-block badge badge-danger me-2'>
                                  {intl.formatMessage({id: 'MODERATION.INVALID.EXPRESSION'})}
                                </span>
                              )}
                              {user.moderation.invalidationReasons?.INVALID_NICKNAME_PROFANITY && (
                                <span className='d-block badge badge-danger'>
                                  {intl.formatMessage({id: 'MODERATION.INVALID.NICKNAME'})}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-dark fw-bolder d-block fs-6 text-center'>
                          {getFlagEmoji(user.localeCode) + '   ' + user.localeCode}
                        </span>
                        <span
                          className={
                            'd-block badge badge-' +
                            (user.status === 'ACTIVE'
                              ? 'success'
                              : user.status === 'SUSPENDED'
                              ? 'warning'
                              : 'danger')
                          }
                        >
                          {user.status}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {intl.formatMessage({id: 'USER.REPORTS'})} :{' '}
                          {user.moderation.events.reports.length}
                        </span>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          Sanctions : {user.moderation.events.sanctions.length}
                        </span>
                      </td>
                      <td className='text-center'>
                        {user.fcmTokens.map((fcmToken, fcmTokenIndex) => {
                          if (!uniquePlatform.includes(fcmToken.platform)) {
                            uniquePlatform.push(fcmToken.platform)
                            return (
                              <span key={fcmTokenIndex}>
                                <img
                                  alt='platform logo'
                                  className='h-20px logo'
                                  src={toAbsoluteUrl(logos[fcmToken.platform])}
                                />
                              </span>
                            )
                          }
                          return null
                        })}
                      </td>
                      <td className='text-center'>
                        {user.providerData && user.providerData.length !== 0 ? (
                          user.providerData.map((providerdata, providerdataIndex) => (
                            <span key={providerdataIndex}>
                              {providerdata.providerId != null ? (
                                <img
                                  alt='provider logo'
                                  className='h-20px logo flex-shrink-0 me-1 mb-1'
                                  src={toAbsoluteUrl(logos[providerdata.providerId])}
                                />
                              ) : null}
                            </span>
                          ))
                        ) : (
                          <img
                            alt='provider logo'
                            className='h-20px logo flex-shrink-0 me-1 mb-1'
                            src={toAbsoluteUrl('/media/logos/anonymous.svg')}
                          />
                        )}
                      </td>
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {timestampConverter(user.metaData.createdTimestamp)}
                        </span>
                      </td>
                      <td className='text-center'>
                        {RoleCheckMin(Roles.ModeratorConfirmed, account?.role) ? (
                          <img
                            alt='moderator logo'
                            className='h-35px logo'
                            src={toAbsoluteUrl('/media/logos/moderator.svg')}
                          />
                        ) : (
                          RoleCheckMin(Roles.ModeratorNew, admin.account.role) &&
                          !RoleCheckMin(Roles.ModeratorNew, account?.role) && (
                            <div className='d-flex my-4'>
                              {user.isLoading ? (
                                <BlockUI loading={user.isLoading} />
                              ) : (
                                <ModerationDropdown userWrappedData={usersList[index]} />
                              )}
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className='btn-custom align-items-center text-center mb-5'>
        {usersList.length % 10 !== 0 ? (
          ''
        ) : usersList.length === 0 ? (
          ''
        ) : (
          <div>
            {noMoreResult ? (
              <p> {intl.formatMessage({id: 'FILTER.NORESULT'})}</p>
            ) : loadMore ? (
              <button
                type='button'
                className='btn btn-sm btn-info btn-active-light-primary w-100px'
              >
                <span className='spinner-border spinner-border-sm text-light'></span>
              </button>
            ) : (
              !searchOnly && (
                <button
                  className='btn btn-sm btn-info btn-active-light-primary w-100px'
                  onClick={() => {
                    dispatch(
                      users.userActions.getUsers(
                        usersList[usersList.length - 1].user.uid,
                        filters.filterKey,
                        filters.filterValue,
                        filters.sortingOrder,
                        filters.filterType,
                        filters.filterOperator,
                        true
                      )
                    )
                    setLoadMore(true)
                  }}
                >
                  {intl.formatMessage({id: 'LOADMORE'})}
                </button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export {GetUsersList}
