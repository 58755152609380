import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

// Firebase
// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const stagingFirebaseConfig = {
  apiKey: 'AIzaSyAx-RASylmJ5AJaYH6pBawE6LLzpsKjg4o',
  authDomain: 'walkie-talkie-staging.firebaseapp.com',
  projectId: 'walkie-talkie-staging',
  storageBucket: 'walkie-talkie-staging.appspot.com',
  messagingSenderId: '915227829519',
  appId: '1:915227829519:web:099a22d52b9bc32fdaca7a',
  measurementId: 'G-PJ03MHDR83',
}
const productionFirebaseConfig = {
  apiKey: 'AIzaSyBpE7mRVz5faY1JAf0IX8HPMqex3ClnV3o',
  authDomain: 'walkie-talkie-communication.firebaseapp.com',
  databaseURL: 'https://walkie-talkie-communication.firebaseio.com',
  projectId: 'walkie-talkie-communication',
  storageBucket: 'walkie-talkie-communication.appspot.com',
  messagingSenderId: '397887285898',
  appId: '1:397887285898:web:499de6cf5566973699db73',
  measurementId: 'G-QS0CECRG63',
}

// Initialize Firebase
initializeApp(
  process.env.REACT_APP_INTRA_ENV === 'staging' ? stagingFirebaseConfig : productionFirebaseConfig
)
// const analytics = getAnalytics(app);

const {PUBLIC_URL} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

Chart.register(...registerables)

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
