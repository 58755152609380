import {Roles} from '../../modules/auth/models/RolesEnum'

export interface SanctionProvider {
  [key: string]: {
    sanctionValue: number | string
    levelRequired: Roles
    trad: string
    reason?: boolean
  }
}

export const moderationValues: SanctionProvider = {
  MODERATION_INVALID_NICKNAME_PROFANITY: {
    sanctionValue: 'INVALID_NICKNAME_PROFANITY',
    levelRequired: Roles.ModeratorNew,
    trad: 'DROPDOWN.INVALIDATENICKNAME',
  },
  MODERATION_INVALID_AVATAR_OFFENSIVE_EXPRESSION: {
    sanctionValue: 'INVALID_AVATAR_OFFENSIVE_EXPRESSION',
    levelRequired: Roles.ModeratorNew,
    trad: 'DROPDOWN.INVALIDATEEXPRESSION',
  },
  MODERATION_DURATION_SUSPENSION_STANDARD_7DAYS_MS: {
    sanctionValue: 7 * 24 * 60 * 60 * 1000,
    levelRequired: Roles.ModeratorConfirmed,
    trad: 'DROPDOWN.SUSPEND7',
    reason: true,
  },
  MODERATION_DURATION_SUSPENSION_STANDARD_5DAYS_MS: {
    sanctionValue: 5 * 24 * 60 * 60 * 1000,
    levelRequired: Roles.ModeratorConfirmed,
    trad: 'DROPDOWN.SUSPEND5',
    reason: true,
  },
  MODERATION_DURATION_SUSPENSION_STANDARD_3DAYS_MS: {
    sanctionValue: 3 * 24 * 60 * 60 * 1000,
    levelRequired: Roles.ModeratorConfirmed,
    trad: 'DROPDOWN.SUSPEND3',
    reason: true,
  },
  MODERATION_DURATION_SUSPENSION_STANDARD_1DAYS_MS: {
    sanctionValue: 1 * 24 * 60 * 60 * 1000,
    levelRequired: Roles.ModeratorConfirmed,
    trad: 'DROPDOWN.SUSPEND1',
    reason: true,
  },
  MODERATION_DURATION_SUSPENSION_STANDARD_1HOUR_MS: {
    sanctionValue: 1 * 60 * 60 * 1000,
    levelRequired: Roles.ModeratorNew,
    trad: 'DROPDOWN.SUSPEND1H',
  },
  MODERATION_DURATION_SUSPENSION_STANDARD_30MN_MS: {
    sanctionValue: 30 * 60 * 1000,
    levelRequired: Roles.ModeratorNew,
    trad: 'DROPDOWN.SUSPEND30MN',
  },
  MODERATION_DURATION_SUSPENSION_STANDARD_10MN_MS: {
    sanctionValue: 10 * 60 * 1000,
    levelRequired: Roles.ModeratorNew,
    trad: 'DROPDOWN.SUSPEND10MN',
  },
}
