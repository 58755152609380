import React from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useIntl} from 'react-intl'
import {UserWrapperModel} from '../../../auth/models/AccountUserWrapperModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import * as users from '../../../users/redux/UserRedux'

type Props = {
  userData: UserWrapperModel
}
const UserPurchases: React.FC<Props> = ({userData}) => {
  const loader = useSelector<RootState>(({users}) => users.purchaseLoader, shallowEqual) as boolean
  const dispatch = useDispatch()

  const intl = useIntl()
  const user = userData.user
  return (
    <>
      {loader ? (
        <div className='text-center mt-5'>
          <button className='btn btn-success'>
            <span className='spinner-border spinner-border-sm align-middle'></span>
          </button>
        </div>
      ) : (
        <div className='text-center mt-5'>
          <button
            className='btn btn-success'
            onClick={() => dispatch(users.userActions.restorePurchase(userData.user.uid))}
          >
            {' '}
            {intl.formatMessage({id: 'PURCHASES.RESTORE'})}
          </button>
        </div>
      )}
      <div>
        {!user.iaps || user.iaps.length === 0 ? (
          <div className='fs-1 mb-20 text-center fw-bolder mt-15'>
            {intl.formatMessage({id: 'USER.USERPAGE.EMPTYLIST'})}
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-50px'>Token</th>
                  <th className='min-w-50px'>Sku</th>
                  <th className='min-w-50px'>SkuType</th>
                  <th className='min-w-50px'>{intl.formatMessage({id: 'USER.PLATFORM'})}</th>
                </tr>
              </thead>
              <tbody>
                {user.iaps.map((purchase, purchaseIndex) => (
                  <tr key={purchaseIndex}>
                    <td>
                      <div>
                        <span
                          className='text-dark fw-bolder fs-6 w-150px overflow-hidden'
                          style={{
                            textOverflow: 'ellipsis',
                            lineHeight: '40px',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {purchase.token}
                        </span>
                        <span
                          className=' fa fa-copy btn btn-icon'
                          style={{verticalAlign: 'top'}}
                          onClick={() =>
                            navigator.clipboard.writeText(purchase.token).then(() =>
                              toast.info(intl.formatMessage({id: 'TEXTCOPIED'}), {
                                position: 'bottom-right',
                                autoClose: 2000,
                                hideProgressBar: true,
                              })
                            )
                          }
                        >
                          <ToastContainer />
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder d-block fs-6'>{purchase.sku}</span>
                    </td>
                    <td>
                      <span className='badge badge-success'>{purchase.skuType}</span>
                    </td>
                    <td>
                      <span className='text-dark fw-bolder d-block fs-6 text-center'>
                        <img
                          alt='platform logo'
                          className='h-30px justify-content-center logo'
                          src={toAbsoluteUrl(
                            purchase.platform === 'android'
                              ? '/media/logos/android.svg'
                              : '/media/logos/apple.svg'
                          )}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}
export default UserPurchases
