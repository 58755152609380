import {hostsConverter} from '../helpers/provider/ProviderRecordHosts'

export interface FilterCaseProvider {
  [key: string]: string[]
}

// prettier-ignore
export const filterCase: FilterCaseProvider = {
  "status": ["", "ACTIVE", "SUSPENDED", "BANNED"],
  "moderation.flag": ["INVESTIGATION", "ACTION", "SANCTION"],
  "localeCode": ["", "fr-FR", "en-US", "af", "ar", "af-ZA", "ar-AE", "ar-BH", "ar-DZ", "ar-EG", "ar-IQ", "ar-JO", "ar-KW", "ar-LB", "ar-LY", "ar-MA", "ar-OM", "ar-QA", "ar-SA", "ar-SY", "ar-TN", "ar-YE", "az", "az-AZ", "az-AZ", "be", "be-BY", "bg", "bg-BG", "bs-BA", "ca", "ca-ES", "cs", "cs-CZ", "cy", "cy-GB", "da", "da-DK", "de", "de-AT", "de-CH", "de-DE", "de-LI", "de-LU", "dv", "dv-MV", "el", "el-GR", "en", "en-AU", "en-BZ", "en-CA", "en-CB", "en-GB", "en-IE", "en-JM", "en-NZ", "en-PH", "en-TT", "en-ZA", "en-ZW", "eo", "es", "es-AR", "es-BO", "es-CL", "es-CO", "es-CR", "es-DO", "es-EC", "es-ES", "es-GT", "es-HN", "es-MX", "es-NI", "es-PA", "es-PE", "es-PR", "es-PY", "es-SV", "es-UY", "es-VE", "et", "et-EE", "eu", "eu-ES", "fa", "fa-IR", "fi", "fi-FI", "fo", "fo-FO", "fr", "fr-BE", "fr-CA", "fr-CH", "fr-LU", "fr-MC", "gl", "gl-ES", "gu", "gu-IN", "he", "he-IL", "hi", "hi-IN", "hr", "hr-BA", "hr-HR", "hu", "hu-HU", "hy", "hy-AM", "id", "id-ID", "is", "is-IS", "it", "it-CH", "it-IT", "ja", "ja-JP", "ka", "ka-GE", "kk", "kk-KZ", "kn", "kn-IN", "ko", "ko-KR", "kok", "kok-IN", "ky", "ky-KG", "lt", "lt-LT", "lv", "lv-LV", "mi", "mi-NZ", "mk", "mk-MK", "mn", "mn-MN", "mr", "mr-IN", "ms", "ms-BN", "ms-MY", "mt", "mt-MT", "nb", "nb-NO", "nl", "nl-BE", "nl-NL", "nn-NO", "ns", "ns-ZA", "pa", "pa-IN", "pl", "pl-PL", "ps", "ps-AR", "pt", "pt-BR", "pt-PT", "qu", "qu-BO", "qu-EC", "qu-PE", "ro", "ro-RO", "ru", "ru-RU", "sa", "sa-IN", "se", "se-FI", "se-FI", "se-FI", "se-NO", "se-NO", "se-NO", "se-SE", "se-SE", "se-SE", "sk", "sk-SK", "sl", "sl-SI", "sq", "sq-AL", "sr-BA", "sr-BA", "sr-SP", "sr-SP", "sv", "sv-FI", "sv-SE", "sw", "sw-KE", "syr", "syr-SY", "ta", "ta-IN", "te", "te-IN", "th", "th-TH", "tl", "tl-PH", "tn", "tn-ZA", "tr", "tr-TR", "tt", "tt-RU", "ts", "uk", "uk-UA", "ur", "ur-PK", "uz", "uz-UZ", "uz-UZ", "vi", "vi-VN", "xh", "xh-ZA", "zh", "zh-CN", "zh-HK", "zh-MO", "zh-SG", "zh-TW", "zu", "zu-ZA",
  ],
  "metaData.notifiedContacts.status": ['true', 'false'],
  "phone.countryCode": ["1", "1242", "1246", "1264", "1268", "1284", "1340", "1441", "1473", "1649", "1664", "1670", "1671", "1684", "1758", "1767", "1784", "1849", "1868", "1869", "1876", "1939", "20", "211", "212", "213", "216", "218", "220", "221", "222", "223", "224", "225", "226", "227", "228", "229", "230", "231", "232", "233", "234", "235", "236", "237", "238", "239", "240", "241", "242", "243", "244", "245", "246", "248", "249", "250", "251", "252", "253", "254", "255", "256", "257", "258", "260", "261", "262", "263", "264", "265", "266", "267", "268", "269", "27", "290", "291", "297", "298", "299", "30", "31", "32", "33", "34", "345", "350", "351", "352", "353", "354", "355", "356", "357", "358", "359", "36", "370", "371", "372", "373", "374", "375", "376", "377", "378", "379", "380", "381", "382", "383", "385", "386", "387", "389", "39", "40", "41", "420", "421", "423", "43", "44", "45", "46", "47", "48", "49", "500", "501", "502", "503", "504", "505", "506", "507", "508", "509", "51", "52", "53", "54", "55", "56", "57", "58", "590", "591", "592", "593", "594", "595", "596", "597", "598", "599", "60", "61", "62", "63", "64", "65", "66", "67", "672", "673", "674", "675", "676", "677", "678", "679", "680", "681", "682", "683", "685", "686", "687", "688", "689", "690", "691", "692", "7", "81", "82", "84", "850", "852", "853", "855", "856", "86", "880", "886", "90", "91", "92", "93", "94", "95", "960", "961", "962", "963", "964", "965", "966", "967", "968", "970", "971", "972", "973", "974", "975", "976", "977", "98", "992", "993", "994", "995", "996", "998",],
  "profile.languageCode": ['aa', 'ab', 'ae', 'af', 'ak', 'am', 'an', 'ar', 'as', 'av', 'ay', 'az', 'ba', 'be', 'bg', 'bh', 'bi', 'bm', 'bn', 'bo', 'br', 'bs', 'ca', 'ce', 'ch', 'co', 'cr', 'cs', 'cu', 'cv', 'cy', 'da', 'de', 'dv', 'dz', 'ee', 'el', 'en', 'eo', 'es', 'et', 'eu', 'fa', 'ff', 'fi', 'fj', 'fo', 'fr', 'fy', 'ga', 'gd', 'gl', 'gn', 'gu', 'gv', 'ha', 'he', 'hi', 'ho', 'hr', 'ht', 'hu', 'hy', 'hz', 'ia', 'id', 'ie', 'ig', 'ii', 'ik', 'io', 'is', 'it', 'iu', 'ja', 'jv', 'ka', 'kg', 'ki', 'kj', 'kk', 'kl', 'km', 'kn', 'ko', 'kr', 'ks', 'ku', 'kv', 'kw', 'ky', 'la', 'lb', 'lg', 'li', 'ln', 'lo', 'lt', 'lu', 'lv', 'mg', 'mh', 'mi', 'mk', 'ml', 'mn', 'mo', 'mr', 'ms', 'mt', 'my', 'na', 'nb', 'nd', 'ne', 'ng', 'nl', 'nn', 'no', 'nr', 'nv', 'ny', 'oc', 'oj', 'om', 'or', 'os', 'pa', 'pi', 'pl', 'ps', 'pt', 'qu', 'rc', 'rm', 'rn', 'ro', 'ru', 'rw', 'sa', 'sc', 'sd', 'se', 'sg', 'sh', 'si', 'sk', 'sl', 'sm', 'sn', 'so', 'sq', 'sr', 'ss', 'st', 'su', 'sv', 'sw', 'ta', 'te', 'tg', 'th', 'ti', 'tk', 'tl', 'tn', 'to', 'tr', 'ts', 'tt', 'tw', 'ty', 'ug', 'uk', 'ur', 'uz', 've', 'vi', 'vo', 'wa', 'wo', 'xh', 'yi', 'yo', 'za', 'zh', 'zu',],
  "sanction.type": ["SUSPENDED", "BANNED"],
  "platform": ["ios", "android"],
  "conference.confAlias": Object.values(hostsConverter),
  "role": ["VIEWER", "MODERATOR", "ADMIN"],
}

let skus =
  process.env.REACT_APP_INTRA_ENV === 'staging'
    ? {
        stranger: 'io.walkietalkie.staging.bundle.strangertalkies2022',
        colorPicker: 'io.walkietalkie.staging.colorpicker',
        wtPlus: 'io.walkietalkie.staging.plus.monthly',
      }
    : {
        stranger: 'io.walkietalkie.bundle.strangertalkies2022',
        colorPicker: 'com.corentinlarroque.wt.color',
        wtPlus: 'io.walkietalkie.plus.monthly',
      }

export const specificFilterCase: FilterCaseProvider = {
  bounties: [
    'EASTER_2022',
    'HALLOWEEN_2021',
    'CHRISTMAS_2021',
    'LOVE_2022',
    'WT_PLUS',
    'COLOR_PICKER',
    'STRANGER_TALKIES_2022',
    'HALLOWEEN_2022',
    'CHRISTMAS_2022',
    'CHINESE_NEW_YEAR_2023',
    'VALENTINES_2023',
    'GREAT_TALKY_BROS',
    'EASTER_2023',
    'MAY_THE_4TH',
    'CHRISTMAS_2023',
    'NEW_YEAR_2024',
    'SKI_CLUB_2024',
    'CHINESE_NEW_YEAR_2024',
    'VALENTINES_2024',
    'SUPERBOWL_2024',
  ],
  achievements: ['FRIENDS_ADD_10'],
}

export const unlockedByBountiesFilterCase: FilterCaseProvider = {
  EASTER_2022: ['AD', 'SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  HALLOWEEN_2021: ['AD', 'SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  CHRISTMAS_2021: ['AD', 'SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  LOVE_2022: ['AD', 'SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  WT_PLUS: [skus.wtPlus, 'SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  COLOR_PICKER: [skus.colorPicker, 'SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  STRANGER_TALKIES_2022: [skus.stranger, 'SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  HALLOWEEN_2022: ['SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  CHRISMAS_2022: ['SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  CHINESE_NEW_YEAR_2023: ['SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  GREAT_TALKY_BROS: ['SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  EASTER_2023: ['SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  MAY_THE_4TH: ['SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
  TEARS_OF_THE_WALKIE: ['SYSTEM_GIFT', 'SYSTEM_GIFT_TMP'],
}
