import {bundleKeys} from './BountiesProvider'

export interface ProviderThemeLogoType {
  [key: string]: string
}
export interface ProviderThemeBkgdType {
  [key: string]: {
    src: string
    trad: string
    color: string
    primaryColor: string
    key: string
    lockType: string
  }
}

export const themeLogo: ProviderThemeLogoType = {
  EASTER: '/media/avatars/talky/themes/easter_icon.svg',
  STRANGER_TALKIES_BIKES: '/media/avatars/talky/themes/Picto_bicycle_st_theme.svg',
  STRANGER_TALKIES_LIGHTS: '/media/avatars/talky/themes/Picto_lights_st_theme.svg',
  LOVE: '/media/avatars/talky/themes/Love_Theme.svg',
  CHRISTMAS: '/media/avatars/talky/themes/Christmas_theme.svg',
  HALLOWEEN: '/media/avatars/talky/themes/halloween_theme.svg',
  COLOR_PICKER: '/media/avatars/talky/themes/color_picker.svg',
  CHRISTMAS_2022_SANTA_SLEIGH: '/media/avatars/talky/themes/CHRISTMAS_2022_SANTA_SLEIGH.svg',
  VALENTINES_2023_CLOUDS: '/media/avatars/talky/themes/Love_Theme.svg',
}

export const bundleLogo: ProviderThemeLogoType = {
  EASTER_2022: '/media/avatars/talky/themes/easter_icon.svg',
  HALLOWEEN_2021: '/media/avatars/talky/themes/halloween_theme.svg',
  CHRISTMAS_2021: '/media/avatars/talky/themes/Christmas_theme.svg',
  LOVE_2022: '/media/avatars/talky/themes/Love_Theme.svg',
  WT_PLUS: '/media/avatars/talky/themes/WTplus_icon.svg',
  COLOR_PICKER: '/media/avatars/talky/themes/color_picker.svg',
  STRANGER_TALKIES_2022: '/media/avatars/talky/themes/Picto_lights_st_theme.svg',
  HALLOWEEN_2022: '/media/avatars/talky/themes/halloween_theme.svg',
  CHRISTMAS_2022: '/media/avatars/talky/themes/CHRISTMAS_2022_SANTA_SLEIGH.svg',
  CHINESE_NEW_YEAR_2023:
    '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_chinese_new_year_dragon_1_preview.svg',
  VALENTINES_2023: '/media/avatars/talky/themes/Valentine_icon.svg',
  GREAT_TALKY_BROS:
    '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_outer_great_talky_sibs_mario_1_preview.svg',
  EASTER_2023:
    '/media/avatars/talky/assets/headwear/preview/avatar_layer_hat_easter_2023_egg_basket_1_preview.svg',
  MAY_THE_4TH:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_may_the_4th_yoda_1_preview.svg',
  TEARS_OF_THE_WALKIE:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_tears_of_the_walkie_link_hero_tunic_1_preview.svg',
  AQUATIC_SUMMER_2023:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_aquatic_summer_2023_surfer_1_preview.svg',
  PRIDE_MONTH_2023:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_pride_month_2023_flag_1_preview.svg',
  SPIDER_TALKIE:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_spider_talkie_miles_1_preview.svg',
  BACK_TO_SCHOOL_2023:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_back_to_school_2023_pencil_1_preview.svg',
  HALLOWEEN_2023:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_halloween_2023_dracula_1_preview.svg',
  CHRISTMAS_2023:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_christmas_2023_candypanty_1_preview.svg',
  NEW_YEAR_2024:
    '/media/avatars/talky/assets/eyewear/preview/avatar_layer_eyewear_glasses_new_year_2024_party_1_preview.svg',
  SKI_CLUB_2024:
    '/media/avatars/talky/assets/accessory/preview/avatar_layer_facial_mask_ski_club_2024_snowboarder_1_preview.svg',
  CHINESE_NEW_YEAR_2024:
    '/media/avatars/talky/assets/mask/preview/avatar_layer_facial_mask_chinese_new_year_dragon_2_preview.svg',
  VALENTINES_2024:
    '/media/avatars/talky/assets/accessory/preview/avatar_layer_body_valentines_2024_lollipop_1_preview.svg',
  SUPERBOWL_2024:
    '/media/avatars/talky/assets/suit/preview/avatar_layer_body_superbowl_2024_kc_jersey_1_preview.svg',
}
export const colorTheme: ProviderThemeLogoType = {
  COLOR_FFD008: '#FFD008',
  COLOR_DCE775: '#DCE775',
  COLOR_AED581: '#AED581',
  COLOR_81C784: '#81C784',
  COLOR_8C97D3: '#8C97D3',
  COLOR_F279A2: '#F279A2',
  COLOR_E57373: '#E57373',
  COLOR_E0E0E0: '#E0E0E0',
  COLOR_90A4AE: '#90A4AE',
  COLOR_78CEFF: '#78CEFF',
  COLOR_00C7A3: '#00C7A3',
  COLOR_9B66F5: '#9B66F5',
  COLOR_D159E4: '#D159E4',
  COLOR_FF7A5C: '#FF7A5C',
}

export const themeBkgd: ProviderThemeBkgdType = {
  CHINESE_NEW_YEAR_2024_BAMBOO: {
    src: '/media/themes/profile_chinese_NY_2024.svg',
    trad: 'CHINESE_NEW_YEAR_2024',
    color: '#FFDCBF',
    primaryColor: '#FFA75F',
    key: 'CHINESE_NEW_YEAR_2024_BAMBOO',
    lockType: bundleKeys.CHINESE_NEW_YEAR_2024,
  },
  VALENTINES_2024_FLOWERS: {
    src: '/media/themes/profile_valentines_2024.svg',
    trad: 'VALENTINES_2024',
    color: '#FFE8E7',
    primaryColor: '#FFC5C4',
    key: 'VALENTINES_2024_FLOWERS',
    lockType: bundleKeys.VALENTINES_2024,
  },
  SUPERBOWL_2024_FIELD: {
    src: '/media/themes/profile_superbowl_2024.svg',
    trad: 'SUPERBOWL_2024',
    color: '#9ECDE4',
    primaryColor: '#0D82BC',
    key: 'SUPERBOWL_2024_FIELD',
    lockType: bundleKeys.SUPERBOWL_2024,
  },

  SKI_CLUB_2024_MOUNTAINS: {
    src: '/media/themes/profile_bg_ski.svg',
    trad: 'SKICLUB_2024',
    color: '#FFD8D6',
    primaryColor: '#FF9E99',
    key: 'SKI_CLUB_2024_MOUNTAINS',
    lockType: bundleKeys.SKI_CLUB_2024,
  },
  NEW_YEAR_2024_PARTY: {
    src: '/media/themes/illu_newyear_2024.svg',
    trad: 'NEWYEAR_2024',
    color: '#C9CBFF',
    primaryColor: '#777DFF',
    key: 'NEW_YEAR_2024_PARTY',
    lockType: bundleKeys.NEW_YEAR_2024,
  },
  CHRISTMAS_2023_SNOWWEATHER: {
    src: '/media/themes/illu_christmas2023.svg',
    trad: 'CHRISTMAS_2023',
    color: '#FAF7F1',
    primaryColor: '#F2EADB',
    key: 'CHRISTMAS_2023_SNOWWEATHER',
    lockType: bundleKeys.CHRISTMAS_2023,
  },
  HALLOWEEN_2023_GRAVEYARD: {
    src: '/media/themes/halloween_2023.svg',
    trad: 'HALLOWEEN_2023',
    color: '#F3E4FB',
    primaryColor: '#E2BBF5',
    key: 'HALLOWEEN_2023_GRAVEYARD',
    lockType: bundleKeys.HALLOWEEN_2023,
  },
  BACK_TO_SCHOOL_2023_CAMPUS: {
    src: '/media/themes/illu_bts.svg',
    trad: 'BACK_TO_SCHOOL_2023',
    color: '#FFF1D4',
    primaryColor: '#FFDB94',
    key: 'BACK_TO_SCHOOL_2023_CAMPUS',
    lockType: bundleKeys.BACK_TO_SCHOOL_2023,
  },
  AQUATIC_SUMMER_2023_SEAWEED: {
    src: '/media/themes/illu_aquatic.svg',
    trad: 'AQUATIC_SUMMER_2023',
    color: '#CCF5E6',
    primaryColor: '#7FE5C0',
    key: 'AQUATIC_SUMMER_2023_SEAWEED',
    lockType: bundleKeys.AQUATIC_SUMMER_2023,
  },
  PRIDE_MONTH_2023_CLOUDS: {
    src: '/media/themes/illu_pride.svg',
    trad: 'PRIDE_MONTH_2023',
    color: '#FFF6D1',
    primaryColor: '#FFE98D',
    key: 'PRIDE_MONTH_2023_CLOUDS',
    lockType: bundleKeys.PRIDE_MONTH_2023,
  },
  SPIDER_TALKIE_ROOFTOPS: {
    src: '/media/themes/illu_spider.svg',
    trad: 'SPIDER_TALKIE',
    color: '#E2D1F5',
    primaryColor: '#B78CE5',
    key: 'SPIDER_TALKIE_ROOFTOPS',
    lockType: bundleKeys.SPIDER_TALKIE,
  },
  TEARS_OF_THE_WALKIE_ISLANDS: {
    src: '/media/themes/profil_TOW.svg',
    trad: 'TEARS_OF_THE_WALKIE',
    color: '#E6FAFF',
    primaryColor: '#CAF4FF',
    key: 'TEARS_OF_THE_WALKIE_ISLANDS',
    lockType: bundleKeys.TEARS_OF_THE_WALKIE,
  },
  MAY_THE_4TH_TALKY_WARS: {
    src: '/media/themes/talky_war_profile_background_1.svg',
    trad: 'MAY_THE_4TH',
    color: '#B9CDE4',
    primaryColor: '#5082BC',
    key: 'MAY_THE_4TH_TALKY_WARS',
    lockType: bundleKeys.MAY_THE_4TH,
  },
  GREAT_TALKY_BROS_WORLD_1_1: {
    src: '/media/themes/profile_mario_1.svg',
    trad: 'GREAT_TALKY_BROS',
    color: '#CDF3FE',
    primaryColor: '#82E2FD',
    key: 'GREAT_TALKY_BROS_WORLD_1_1',
    lockType: bundleKeys.GREAT_TALKY_BROS,
  },
  EASTER_2023: {
    src: '/media/themes/profile_easter.svg',
    trad: 'EASTER_2023',
    color: '#E8FFF9',
    primaryColor: '#C5FFF0',
    key: 'EASTER_2023',
    lockType: bundleKeys.EASTER_2023,
  },
  VALENTINES_2023_CLOUDS: {
    src: '/media/themes/illu_love_1.svg',
    trad: 'VALENTINES_2023_CLOUDS',
    color: '#FFE6ED',
    primaryColor: '#FFC0D2',
    key: 'VALENTINES_2023_CLOUDS',
    lockType: bundleKeys.VALENTINES_2023,
  },
  CHRISTMAS_2022_SANTA_SLEIGH: {
    src: '/media/themes/illu_christmas.svg',
    trad: 'CHRISTMAS_2022_SANTA_SLEIGH',
    color: '#B3DDCB',
    primaryColor: '#41AA7D',
    key: 'CHRISTMAS_2022_SANTA_SLEIGH',
    lockType: bundleKeys.CHRISTMAS_2022,
  },
  STRANGER_TALKIES_BIKES: {
    src: '/media/themes/illu_stranger01.svg',
    trad: 'STRANGER_TALKIES_BIKES',
    color: '#C8CDD7',
    primaryColor: '#76839B',
    key: 'STRANGER_TALKIES_BIKES',
    lockType: bundleKeys.STRANGER_TALKIES_2022,
  },
  STRANGER_TALKIES_LIGHTS: {
    src: '/media/themes/illu_stranger02_1.svg',
    trad: 'STRANGER_TALKIES_LIGHTS',
    color: '#BEC7B9',
    primaryColor: '#5C7251',
    key: 'STRANGER_TALKIES_LIGHTS',
    lockType: bundleKeys.STRANGER_TALKIES_2022,
  },
  EASTER: {
    src: '/media/themes/illu_easter_1.svg',
    trad: 'EASTER',
    color: '#EAF9EB',
    primaryColor: '#CBF1CE',
    key: 'EASTER',
    lockType: bundleKeys.EASTER_2022,
  },
  CHRISTMAS: {
    src: '/media/themes/illu_christmas_2021.svg',
    trad: 'CHRISTMAS',
    color: '#FFB3B3',
    primaryColor: '#FF4040',
    key: 'CHRISTMAS',
    lockType: bundleKeys.CHRISTMAS_2021,
  },
  HALLOWEEN: {
    src: '/media/themes/illu_halloween_2.svg',
    trad: 'HALLOWEEN',
    color: '#FBD099',
    primaryColor: '#F68A00',
    key: 'HALLOWEEN',
    lockType: bundleKeys.HALLOWEEN_2022,
  },
  LOVE: {
    src: '/media/themes/illu_love_2.svg',
    trad: 'LOVE',
    color: '#F6DDDD',
    primaryColor: '#E9A9AB',
    key: 'LOVE',
    lockType: bundleKeys.LOVE_2022,
  },
}
