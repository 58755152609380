import {getAuth, signOut, signInWithEmailAndPassword} from 'firebase/auth'
export class FirebaseHelper {
  public static async login(email: string, password: string) {
    const auth = getAuth()
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        return user.getIdToken().then(function (idToken) {
          return {accessToken: idToken}
        })
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') return Promise.reject({email: 'Email not found'})
        if (error.code === 'auth/wrong-password')
          return Promise.reject({password: 'Wrong password'})
        else return Promise.reject({email: 'Unknow error'})
      })
  }

  public static async logout() {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      })
  }
}
