import {Line} from 'react-chartjs-2'
import React from 'react'
import {timestampConverter} from '../../../../helpers/converter/Common'
import moment from 'moment'
import {AccountEventsTypeModel} from '../../../auth/models/AccountUserWrapperModel'
import {arrayReduce, dataAssign} from '../../../../helpers/ChartHelper'

type Props = {
  InvalidationData: AccountEventsTypeModel[]
  SanctionData: AccountEventsTypeModel[]
  PardonData: AccountEventsTypeModel[]
  startDate: Date
  endDate: Date
}

const AccountModeratorChart: React.FC<Props> = ({
  InvalidationData,
  SanctionData,
  PardonData,
  startDate,
  endDate,
}) => {
  const resultDates: string[] = []
  const sanctionList: string[] = []
  const invalidationList: string[] = []
  const pardonList: string[] = []
  const start: Date = new Date(startDate)
  const end: Date = new Date(endDate)
  const diffInDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  const current = moment(start)
  const labelArray = ['Invalidation', 'Sanction', 'Pardon']
  InvalidationData.map((invalidations: AccountEventsTypeModel) =>
    invalidationList.push(timestampConverter(invalidations.date))
  )

  SanctionData.map((sanctions: AccountEventsTypeModel) => {
    return sanctionList.push(timestampConverter(sanctions.date))
  })

  PardonData.map((pardons: AccountEventsTypeModel) => {
    return pardonList.push(timestampConverter(pardons.date))
  })

  let n = 0
  while (n < diffInDays + 1) {
    resultDates.push(current.format('YYYY-MM-DD'))
    current.add(1, 'day')
    n++
  }

  const invalidationDate = arrayReduce(invalidationList, (v: number) => (v || 0) + 1)
  const finalInvalidationData = arrayReduce(resultDates, (v: number) => v || 0)
  dataAssign(invalidationDate, finalInvalidationData)

  const sanctionDate = arrayReduce(sanctionList, (v: number) => (v || 0) + 1)
  const finalSanctionData = arrayReduce(resultDates, (v: number) => v || 0)
  dataAssign(sanctionDate, finalSanctionData)

  const pardonDate = arrayReduce(pardonList, (v: number) => (v || 0) + 1)
  const finalPardonData = arrayReduce(resultDates, (v: number) => v || 0)
  dataAssign(pardonDate, finalPardonData)

  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        beginAtZero: true,
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        beginAtZero: true,
      },
    },
  }

  const data = {
    labels: resultDates,
    datasets: [
      {
        label: labelArray[0],
        data: finalInvalidationData,
        fill: false,
        borderColor: '#009EF7',
        yAxisID: 'y',
      },
      {
        label: labelArray[1],
        data: finalSanctionData,
        fill: false,
        borderColor: '#F1416C',
        yAxisID: 'y',
      },
      {
        label: labelArray[2],
        data: finalPardonData,
        fill: false,
        borderColor: '#50CD89',
        yAxisID: 'y',
      },
    ],
  }

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  )
}

export {AccountModeratorChart}
